@import '../../../../style/utils/colors';
@import '../../../../style/utils/breakpoints';

.syllabus-wrapper {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 1.5vw;
  margin-bottom: 1em;
  transform: translateX(-2.5%);

  @include breakpoint-max(sm) {
    gap: 8vw;
  }

  .icon {
    filter: brightness(0.3);
  }

  span {
    color: $grey-dark;
    text-decoration: underline;
  }

  &:hover {
    opacity: 0.6;
  }
}
