@import '../../style/utils/colors';
@import '../../style/utils/breakpoints';

.wrapper {
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  right: 3vw;
  transform: translateX(50%);
  z-index: 300;

  @include breakpoint-max(xs) {
    z-index: 1000;
    transform: unset;
    right: 6vw;
    transform: translateX(-150%);
    bottom: 60px;
  }

  .currentLangWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    height: 35px;
    width: 35px;
    border: 1px solid $mint;
    border-radius: 10px;

    .loading {
      width: 50%;
      height: 50%;
      filter: brightness(1);
    }

    button {
      color: $mint;
      box-sizing: border-box;
      padding: 0.5em;
      border-radius: 2px;
      z-index: 4000;

      span {
        font-size: 1em;
        line-height: 1em;
      }
    }

    &:hover {
      border: unset;
      background-color: $mint;
      button {
        color: $white;
      }
    }
  }

  .darkMode {
    background-color: $mint;
    button {
      color: $white;
    }

    &:hover {
      border: 1px solid $mint;
      background-color: transparent;
      button {
        color: $mint;
      }
    }
  }

  .listWrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: absolute;
    bottom: -60%;
    background-color: $background-extra-soft-mint;
    border-radius: 2px;

    .currentLangInList {
      display: none;
    }

    ul {
      z-index: 55000;
      li {
        &:hover {
          cursor: pointer;
          background-color: $extra-soft-mint;
          border-radius: 2px;
        }
        button {
          padding: 0.5em 1em 1em;
          z-index: 4000;
          border-radius: 2px;
          &::after {
            content: ' ';
            position: absolute;
            bottom: 20%;
            left: 20%;
            right: 20%;
            height: 2px;
            background-color: $mint;
          }
        }
      }
    }
  }
}
