@import '../../../style/utils/colors';

.field-wrapper {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;

  label {
    font-size: 1em;
    margin-left: 0.8em;
    color: $grey-dark;
    &:hover {
      cursor: pointer;
    }
  }

  input {
    &:hover {
      cursor: pointer;
    }
  }

  .tooltip {
    position: relative;
    margin-left: 0.7em;
    .icon {
      width: em;
      height: 1em;

      img {
        width: 100%;
        height: 100%;
      }
    }

    &:hover {
      cursor: pointer;
      .tooltip__label {
        display: block;
      }
    }

    &__label {
      display: none;
      position: absolute;
      left: 150%;
      top: 0;
      width: 20vw;
      color: $grey-dark;
      border: 1px solid $grey-dark;
      padding: 0.5em 0.7em;
    }
  }
}
