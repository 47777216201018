@import '../../../style/utils/colors';
@import '../../../style/utils/breakpoints';

@mixin section--title {
  font-size: 1.2em;
  color: $grey-dark;
  margin: 0;
  text-align: left;
  font-weight: 700;
}

@mixin section--title--wrapper {
  width: 100%;
  margin-bottom: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.myBookingsContainer {
  width: 100%;
  height: 100%;
  max-width: 1280px;
  margin: 0 auto;

  .myBookingsHeader {
    position: relative;
    @include section--title--wrapper();
    .title {
      @include section--title();

      @include breakpoint-max(sm) {
        &.titleWithBulkingstageOpen {
          display: none;
        }
      }
    }
  }

  .pastCardsSection {
    margin-top: 6.25vw;

    .sectionTitle {
      @include section--title();
      @include section--title--wrapper();
    }
  }

  .noPastLessons {
    width: 100%;
    padding: 5%;
    background-color: $button-light-grey;
    grid-column: span 3;
    border-radius: 3px;

    p {
      text-align: center;
      color: $grey-light-dark;
      font-weight: 600;
    }
  }
}

@keyframes slideButton {
  from {
    transform: translateY(0);
  }
  to {
    transform: translateY(10%);
  }
}
