@import '../../style/utils/colors';
@import '../../style/utils/breakpoints';

.aside-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  border-radius: 8px;
  padding: 1.5rem 2rem;

  .options {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;

    .option {
      display: flex;
      justify-content: flex-start;
      align-items: center;

      button {
        justify-content: flex-start;
      }
    }
  }
}
