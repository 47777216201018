@import '../utils/breakpoints';
@import '../utils/colors';

.container {
  margin: auto;
  width: 100%;
}

.filters {
  align-items: center;
  display: flex;
  justify-content: center;
  margin-bottom: 32px;
}

.subHeading {
  font-size: 20px;
  margin-top: 30px;
  text-align: center;
}

.tutorsContainer {
  width: 100%;
  padding: 2% 10%;
  background-color: $grey-lightest;
}

@media (max-width: 600px) {
  .filters {
    flex-direction: column;
  }
}

$padding-left: 10%;
$size-elements-left: 170px;

.listWrapper {
  width: 100%;
  padding: 3% 10%;
  background-color: $grey-lightest;
}

.loader {
  width: 50%;
}

.pageWrapper {
  background-color: $white;
}

.pageWrapper h1 {
  margin-top: 0;
  padding-top: 3%;
}

.filterButton {
  margin-right: 10px;
  border: 1px solid $black !important;
}

.filterWrapper {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding-bottom: 3%;
  @include breakpoint-max(xs) {
    flex-wrap: wrap;
  }
}

.timetableWrapper {
  position: absolute;
  box-shadow: 0px 2px 0px 0px $color-shadow;
  z-index: 10;
  top: 74px;
  left: -85px;
}

.timetableWrapper div {
  margin: unset !important;
}

.timeTableWrapper .timeTableColumn:first-child div:first-child {
  background-color: transparent;
  border: none;
}

.header {
  padding: 1% 10%;
  background-color: $white;
  font-size: 16px;

  .modalBackButton {
    background-color: transparent;
    outline: none;
    border: none;
    color: $grey-dark;
    font-size: 1em;
    position: relative;
    margin: 1em 0 0 1em;

    &::before {
      content: ' ';
      width: 0.5em;
      height: 0.5em;
      border-left: 1px solid $grey-dark;
      border-bottom: 1px solid $grey-dark;
      position: absolute;
      top: 32%;
      left: -0.8em;
      transform: rotate(45deg);
    }

    &:hover {
      color: $grey-light-button;

      &::before {
        border-left: 1px solid $grey-light-button;
        border-bottom: 1px solid $grey-light-button;
      }
    }
  }

  h1 {
    font-size: 2.8em;
    text-align: left;
    margin: 0.4em 0 0;
  }

  .changeCoachWarning {
    background: $white-smoke;
    margin: 1em 0;
    padding: 1em;
    max-width: 680px;
    display: flex;
    justify-content: center;
  }

  @include breakpoint-max(sm) {
    font-size: 12px;
    h1 {
      text-align: center;
    }

    .filterWrapper {
      display: flex;
      justify-content: center;
    }

    .changeCoachWarning {
      div {
        p {
          font-size: 0.95em;
        }
      }
    }
  }

  .dropdownWrapper {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    margin-top: 2vw;
    position: relative;
    @include breakpoint-max(xs) {
      margin-bottom: 5%;
    }
  }
}

.dropdownLabel {
  font-size: 1.2em;
}

.fakeDropdown {
  box-shadow: 0 0px 0px $color-shadow;
  transition: 0.1s box-shadow linear;
  padding: 10px 20px;
  color: $grey-dark;
  background-color: $grey-light-medium-dark;
  border-color: $color-border;
  margin-right: 10px;
  border: 1px solid $black !important;
  border-radius: 5px !important;
}
