@import '../utils/breakpoints';
@import '../utils/colors';

.anotherTutorButton {
  height: 40px;
  background-color: $back-button;
  margin-right: 10px;
  border-radius: 5px !important;
  box-shadow: none !important;
}

.centeredHeader {
  text-align: center;
  margin-top: 4%;
  margin-bottom: 1.5%;

  @include breakpoint-max(xs) {
    width: 80%;
    margin: 5vw auto;
  }
}

.buttonWrapper{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-bottom: 4%;
  @include breakpoint-max(sm) {
    flex-direction: column;
  }
}

.buttonWrapper .anotherTutorButton {
  margin-top: 10px;
}

.containerWrapper {
  background-color: $grey-lightest;
  margin-bottom: -32px;
  padding-bottom: 32px;
}

.usefulCopy {
  text-align: center;
  margin-bottom: 0;
  color: $white;
}

.usefulCopyWrap {
  width: 100%;
  background-color: $blue;
  padding: 2%;
  margin: auto;
  margin-bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.usefulCopyLink {
  color: $white;
  background-color: transparent;
  border: none;
  padding: 0;
  text-decoration: underline;
}

.centerLoader {
  position: absolute;
  margin: auto;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}



@media (max-width: 600px) {
  .anotherTutorButton {
    font-size: 3.5vw !important;
  }
  .callToAction {
    font-size: 3.5vw !important;
  }
}
