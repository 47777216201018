@import '../../style/utils/colors';

.regular-wrapper,
.bigger-wrapper {
  position: absolute;
  top: 50%;
  z-index: 2;
  border-radius: 50%;

  .blocked-lesson {
    height: 100%;
    width: 100%;
    border: none;
    border-radius: 50%;
    background-color: #f3f3f3;
    position: absolute;
  }

  .score-wrapper {
    height: 100%;
    width: 100%;
    display: flex;
    border-radius: 50%;
    justify-content: center;
    align-items: center;
    background-color: $white;
    border: 3px solid $mint;
  }
}

.regular-wrapper {
  width: 30px;
  height: 30px;
  transform: translate(-38%, -50%);
}

.bigger-wrapper {
  width: 36px;
  height: 36px;
  transform: translate(-42%, -50%);
}
