@import '../../../style/utils/colors';
@import '../../../style/utils/breakpoints';

$tooltip-icon--size: 1em;

.tooltip {
  position: relative;
  margin-left: 0.3em;
  display: inline-flex;
  align-items: flex-end;
  transform: translateY(20%);
  z-index: 1;
  .icon {
    width: $tooltip-icon--size;
    height: $tooltip-icon--size;
    opacity: 0.4;

    svg {
      width: 100%;
      height: 100%;
    }
  }

  &:hover:not(.just-icon) {
    cursor: pointer;
    .tooltip__label {
      display: block;
      z-index: 5000;
    }
  }

  &__label {
    border-radius: 3px;
    display: none;
    position: absolute;
    width: 20vw;
    color: $grey-dark;
    border: 1px solid $grey-dark;
    padding: 0.5em 0.7em;

    @include breakpoint-max(sm) {
      width: 50vw;
    }

    &.transparent {
      background-color: transparent;
    }

    &.white {
      background-color: $white;
      z-index: 100000;
    }
  }

  .top-right {
    transform: translate(-80%, -25px);
  }

  .bottom-right {
    transform: translate(-80%, 110%);
  }

  .right {
    left: 150%;
    top: 0;
  }
}
