@import '../../../../style/utils/colors';
@import '../../../../style/utils/breakpoints';

.input {
  width: 100%;
  min-width: unset;
  border-top: 2px solid $grey-lightest;
  padding: 0.5em 0 0 0;
  padding-bottom: 1em;

  .chatButtonsWrapper {
    display: flex;
    gap: 0.5vw;
    justify-content: space-between;
    align-items: center;

    @include breakpoint-max(xs) {
      flex-direction: column;
      max-width: 80px;
    }
    
    .attachButtons {
      display: flex;
      height: 100%;
      align-items: stretch;
      gap: 0.5vw;

      @include breakpoint-max(xs) {
        order: 2;
        justify-content: space-between;
      }
    }

    .sendButton {
      height: 40px;
      font-size: 0.875rem;
      padding: 0.25rem 0.85rem;

      @include breakpoint-max(xs) {
        min-height: 40px;
      }
    }
  }
}
