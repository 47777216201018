$purple-dark: rgb(99, 59, 74);
$dark-purple: rgb(66, 27, 155);
$background-purple: rgba(215, 201, 255, 55%);

// Gray tones
$grey-darkest: rgb(31, 31, 31);
$grey-dark: rgb(51, 51, 51);
$grey-hard-dark: rgb(68, 68, 68);
$button-hover-grey: rgb(95, 95, 95);
$grey-medium-dark: rgb(102, 102, 102);
$light-gray: rgb(125, 125, 125);
$grey: rgb(128, 128, 128);
$grey-light-button-font: rgb(141, 144, 145);
$grey-light-dark: rgb(153, 153, 153);
$grey-button-disabled-text: rgb(172, 172, 172);
$grey-light: rgb(192, 192, 192);
$grey-medium-button-text: rgb(198, 198, 198);
$grey-light-button: rgb(205, 205, 205);
$grey-button-disabled: rgb(214, 214, 214);
$grey-light-medium-dark: rgb(217, 217, 217);
$transparent-grey: rgba(221, 221, 221, 0.5);
$tag-grey: rgb(226, 226, 226);
$background-light-grey: rgb(230, 230, 230);
$button-light-grey: rgb(239, 239, 239);
$grey-lightest: rgb(240, 240, 240);
$active-grey: rgb(245, 245, 245);
$grey-lightest-hover: rgb(249, 249, 249);

$back-button: rgba(206, 205, 205, 0.34);
$back-button-hover: rgba(156, 156, 156, 0.34);

$color-darkener: rgba(0, 0, 0, 0.15);
$color-mid-darkener: rgba(0, 0, 0, 0.5);
$color-extra-darkener: rgba(0, 0, 0, 0.8);

$color-background: $grey-lightest;
$color-border: $color-darkener;
$color-footer: $grey-darkest;
$color-footer-link: $grey-light-dark;
$color-footer-link-hover: $grey-light;
$color-shadow: $color-darkener;

$online-color: rgb(85, 239, 196);
$text-highlight: #b5ffe7;
$light-text-highlight: lighten($text-highlight, 7%);
$inperson-color: rgb(116, 185, 255);
$conversation-color: rgb(255, 234, 167);
$tuition-color: rgb(250, 177, 160);
$group-color: rgb(223, 230, 233);
$language-culture: rgb(190, 144, 212);

// This is the color from the color codes given by Cristina

$white: rgb(250, 250, 250);
$white-transparent: #ffffff80;
$white-yellow: rgb(252, 248, 227);
$white-red: rgb(242, 222, 222);
$white-blue: rgb(217, 237, 247);

$teal: #7fc7bd;
$soft-teal: lighten($teal, 50%);

$blue: #397988;
$soft-blue: lighten($blue, 20%);
$dark-blue: darken($blue, 20%);
$blue-light: rgb(217, 237, 247);
$blue-opacity: rgba(57, 121, 136, 0.8);

$peach: #e7ce9b;
$soft-peach: lighten($peach, 10%);
$smooth-peach: #f5e6cd;

$green: #a5cf65;
$soft-green: lighten($green, 20%);
$dark-green: #1a9c6f;

$yellow: #e6d255;
$soft-yellow: rgb(231, 206, 155);
$transparent-yellow: rgba(231, 206, 155, 0.5);

$orange: #ef9349;
$soft-orange: rgb(239, 147, 73);
$very-soft-orange: rgb(138, 109, 59);

$pink: #ff3e80;
$soft-pink: lighten($pink, 10%);
$dark-pink: darken($pink, 25%);
$medium-pink: #fa5b5b;

$red: #a60a0d;
$soft-red: rgb(227, 75, 96);
$red-dark: rgb(169, 68, 66);
$red-light: rgb(242, 222, 222);
$background-red: lighten($red, 58%);

$purple: rgb(134, 86, 169);
$soft-purple: rgb(118, 102, 153);

// Colors from app refresh

$salmon: rgba(252, 151, 109);
$salmon-opaque: rgba(252, 151, 109, 0.45);
$purple-heart: #462ab9;
$blumine: #2e6775;
$white-smoke: #f0f0f0;
$whisper: #e6e6e6;
$gainsboro: #e3e3e3;
$black: #000000;
$mint: #40dba7;
$mint-opaque: rgba(105, 230, 187, 0.65);
$dark-mint: #3fdba8;
$mint-opacity: rgba(66, 209, 159, 0.11);
$background-extra-soft-mint: lighten($mint, 36%);
$extra-soft-mint: lighten($mint, 26%);
$super-extra-soft-mint: lighten($mint, 33%);
$soft-mint: lighten($mint, 20%);
$medium-mint: #66e7bd;
$extra-dark-mint: darken($mint, 20%);
$camel: #f5ebd8;
$camel-2: #f2e5cb;
$tooltip-highlight: #fcd889;
$new-light-gray: #f4f4f4;
$gray85: #d9d9d9;
$gray85-opaque: rgba(217, 217, 217, 0.65);
$link-default: #4e65de;
$link-hover: #6980fc;
$link-active: #384bac;
$salmon: #fc976d;

$border-grey: #ddd;

$tag-mint: rgba(63, 219, 168, 0.4);
$tag-salmon: rgba(252, 151, 109, 0.4);

$light-amber: #efd546;
$dark-amber: #ef9d46;
