@import '../../utils/colors';

.timeTableWrapper{
  display: flex;
  flex-direction: row;
}

.timeTableRow{
  display: flex;
  flex-direction: row;
}

.timeTableColumn{
  display: flex;
  flex-direction: column;
}

.timeTableCell{
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  border: 0.5px solid $color-darkener;
  min-height: 46px;
  padding: 10px;
  height: 46px;
  justify-content: center;
  align-items: center;
}

.timezone {
  font-size: 0.875em;
  margin-bottom: 0.5em;
}

.timeTableHeader {
  background-color: $soft-yellow;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
}

.timeTableHeaderDisabled {
  background-color: $color-background;
}

.timeTableEmpty {
  background-color: transparent;
  border: none;
}

.timeTableNormal {
  display: flex;
  align-items: center;
  justify-content: center;
}

.timeTableNormal {
  background-color: $color-background;
}

.timeTableNormalSelected {
  background-color: $white;
}

.calendarIcon {
  filter: grayscale(1);
}

.calendarIconSelected {
  filter: grayscale(0);
}


.calendarButton {
  border-radius: 100% !important;
  width: 25px;
  height: 25px;
  background-color: $white;
  padding: 0 !important;
  box-shadow: none !important;

  .calendarIcon {
    width: 100%;
    height: 100%;
    img {
      width: 100%;
      height: 100%;
    }
  }

}

.calendarButton[disabled] {
  background-color: $color-background;
  pointer-events: none;
}

.timeTableNormalSelectedDisabled {
  background-color: $soft-yellow;
}


@media (max-width: 768px) {
  .timeTableCell {
    padding: 0.5vw;
    min-height: calc(26px + 1vw);
    height: calc(26px + 1vw);
  }

  .timeTableWrapper {
    width: fit-content;
    margin: 25px auto 0px auto;
  }
}
