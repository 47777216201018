@import '../../../../style/utils/colors';
@import '../../../../style/utils/breakpoints';

.self-study-card {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 1em;
  align-items: flex-start;
  justify-content: center;
  text-align: left;
  position: relative;
  padding-bottom: 1em;

  h2 {
    font-size: 1.875em;
    font-weight: 700;
    line-height: 1em;
  }

  h3 {
    font-size: 1.4em;
    font-weight: 700;
    line-height: 1.2em;
  }

  p {
    font-size: 1em;
    line-height: 1.2em;
  }
}

.self-study-card-without-tooltip {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 1em;
  align-items: center;
  justify-content: center;
  text-align: left;
  position: relative;
  padding: 2em 0;

  h3 {
    font-size: 1.4em;
    font-weight: 700;
    line-height: 1.2em;
  }
}

.progress-bar--wrapper {
  width: 88%;
}

.tooltip {
  position: absolute;
  bottom: -0.8em;
  right: -1em;

  span > span:last-of-type {
    @include breakpoint-max(sm) {
      transform: translateX(-115%);
    }
  }
}

.blocked-self-study-card {
  width: 100%;
  height: 100%;
  color: $grey-light-button-font;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 2vh;

  img {
    width: 40px;
    height: 40px;
    filter: grayscale(1);
  }

  h3 {
    width: 70%;
  }

  button {
    display: none;

    @include breakpoint-max(sm) {
      display: block;
      width: 80%;
    }
  }
}
