@import '../../../../style/utils/breakpoints';
@import '../../../../style/utils/colors';

.lesson-wrapper {
  display: flex;
  justify-content: flex-start;
  gap: 2vw;
  position: relative;

  @include breakpoint-max(sm) {
    gap: 10vw;
  }

  &:hover {
    position: relative;
    .container {
      .lesson {
        &__number,
        &__title,
        &__subdata {
          color: $white;
        }
      }
    }
    &::before {
      content: '';
      position: absolute;
      top: -5%;
      left: -10%;
      right: -10%;
      bottom: -5%;
      background-color: $grey-dark;
      border-radius: 5px;
      z-index: 1;
    }
  }

  .container {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    padding: 0.75em 0;
    cursor: pointer;
    font-weight: 400;
    line-height: 1.2em;
    z-index: 2;

    .lesson {
      text-align: left;

      &__number,
      &__title,
      &__subdata {
        color: $grey-medium-dark;
      }

      &__duedate,
      &__overdue {
        font-weight: bold;
        color: $grey-dark;
        padding-left: 3px;
        padding-right: 3px;
      }

      &__duedate {
        background-color: $dark-mint;
      }

      &__overdue {
        background-color: $light-amber;
      }

      &__number,
      &__title {
        display: inline;
        font-size: 1.2em;
      }

      &__number {
        margin-right: 0.3em;
      }

      &__subdata {
        color: $button-hover-grey;
        display: block;
        font-size: 0.85em;
        margin-top: 0.2em;

        span {
          font-size: 1em;
        }
      }
    }

    &--selected {
      .lesson {
        &__number,
        &__title {
          font-weight: 700;
        }
        &__subdata {
          font-weight: 500;
        }
      }
    }

    &--inactive {
      &:hover {
        opacity: 1;
        cursor: default;
      }
      .lesson {
        &__number,
        &__title,
        &__subdata {
          color: $grey-light;
          cursor: default;
        }
      }
    }

    &.containerPast {
      color: $grey;
    }
  }
}
