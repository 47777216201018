@import '../../../style/utils/colors';
@import '../../../style/utils/breakpoints';

.containerWrapper {
  width: 100%;
  height: 100%;
  background-color: $white;
  border-radius: 5px;
  border: 1px solid $grey-lightest;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 0.5em 1em;

  .loadingWrapper {
    width: 80%;
    height: 60px;
    margin-top: 5vh;
    & > div {
      margin-bottom: 4vh;
    }

    @include breakpoint-max(xs) {
      width: 100%;
      height: 70px;
      & > div {
        margin-bottom: 8vh;
      }
    }
  }

  .noChannelsMsg {
    font-size: 1em;
    letter-spacing: 0.2px;
    display: block;
    width: 100%;
    height: 100%;
    margin-top: 30%;
    text-align: center;
  }

  .chatNav {
    display: flex;
    justify-content: space-around;
    padding-bottom: 0.5rem;

    .navButton {
      background-color: unset !important;
      color: $black !important;
      font-size: 16px !important;
      width: -webkit-fit-content;
      width: -moz-fit-content;
      width: fit-content;
    }

    .selectedNavButton {
      border-bottom: $blue 6px solid !important;
      border-radius: unset !important;
    }
  }

  & > div {
    width: 100%;
  }
}

.conversationBox {
  padding: 2px;
}

.languageHelperTag,
.conversationBoxSelected .languageHelperTag {
  background-color: $soft-yellow !important;
  padding: 3px;
  position: absolute;
  top: 2px;
  left: 0;
  font-size: 0.5rem;
  border-radius: 0;
}

.conversationBoxSelected {
  div {
    background-color: $super-extra-soft-mint !important;
    border-radius: 3px 3px 0 0;
  }
}

.conversationBoxUnread .languageHelperTag {
  font-weight: normal;
}
