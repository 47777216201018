@import '../../utils/colors';
@import '../../utils/breakpoints';

.password-form {
  width: 50%;
}

.account-details__header {
  display: flex;
  justify-content: space-between;
  align-items: center;

  @include breakpoint-max(sm) {
    align-items: center;
  }

  button {
    width: 20%;
    max-width: 100px;

    @include breakpoint-max(sm) {
      width: 30%;
      margin-top: 0;
    }
  }
}

.loading {
  height: 30px;
  width: 30px;
}

.locationSelector {
  margin-bottom: 15px;
}

.settingsContainer {
  display: flex;
}

.settingsContainer .checkbox {
  margin-top: unset;
}

.checkBoxContainer {
  text-align: center;
  margin-left: 40px;
  margin-right: 40px;
  margin-top: 20px;
}

.emailsContainer {
  margin-right: 45px;
}

.space {
  margin-top: 25px;
}

.divider {
  height: 50px;
}

.preferencesContainer {
  display: flex;
  flex-direction: column;
}

.preferencesHeader,
.preferencesOption {
  flex-direction: row;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.headerWithSubHeader {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.preferencesOption p {
  width: 40%;
  text-align: left;
}

.preferencesHeader h2 {
  width: 40%;
  text-align: left;
}

.preferencesHeader h5 {
  width: 30%;
  text-align: center;
}

.checkboxWrapper {
  width: 30%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.disabledOption {
  color: $grey-light;
}

.meetMeInfo {
  width: 40%;
}

.meetMeInfo p {
  margin-bottom: 25px;
}

.dropdownWrapper {
  margin-bottom: 50px;
}

.dropdown {
  position: relative;
    font-size: 14px;
    color: rgb(51, 51, 51);
    letter-spacing: .01rem;
    z-index: 0;
    margin-top: 0 !important;
    margin-bottom: 0 !important;
    margin-left: 0;
    background: #FFFFFF;
    border: 1px solid #CACACA;
    border-radius: 5px;
    line-height: 25px;
    height: 35px;
    width: 300px;
    outline: none;
    padding: 0.5em 0.75em;
}