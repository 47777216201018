@import '../../../../style/utils/breakpoints';
@import '../../../../style/utils/colors';

$half-perc: 50%;
$diamond-height: 25px;
$half-diamond-height: $diamond-height/2;

.spinnerWrapper {
  width: 50px;
  height: 50px;
}

.tooltipWrapper {
  color: blue;
  z-index: 10;
}

.dropzoneWrapper {
  margin-top: 10px;
  width: 100%;
}

.dropzoneWrapper>div {
  border: $grey-light-dark dashed 1px !important;
  display: flex;
  align-items: center;
  justify-content: center;
  color: $grey-light-dark;
  text-align: center;
  padding: 15px;
  width: 100% !important;
  height: 80px !important;
}

.deliveryFilesHeader {
  margin-top: 15px;
  margin-bottom: 5px;
}

.unitWrapper {
  display: flex;
  width: 70%;

  @include breakpoint-max(sm) {
    position: static;
    margin-top: 0;
    font-size: 18px;
    width: 100%;
  }

  .loadingWrapper {
    position: relative;
    left: 70px;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-start;

    @include breakpoint-max(sm) {
      left: 0;
    }
  }

  .diamondWrapper {
    height: 100%;
    border-left: 4px solid $grey-dark;
    position: relative;

    .diamond {
      transform: rotate(45deg);
      width: $diamond-height;
      height: $diamond-height;
      background-color: $grey-dark;
      min-width: $diamond-height;
      min-height: $diamond-height;

      position: absolute;
      top: 48px;
      left: -$half-diamond-height - 2px;
      z-index: 1000;

      @include breakpoint-max(sm) {
        display: none;
      }
    }
  }

  .unitContent {
    @include breakpoint-max(sm) {
      padding-left: 0;
    }

    .titleWrapper {
      display: flex;
      flex-direction: column;
      text-align: left;
      margin-bottom: 20px;

      .title {
        text-align: left;
        @include breakpoint-max(xs) {
          font-size: 1.5rem !important;
        }
      }

      h1,
      h2 {
        margin: 0;
      }

      h2 {
        font-size: 1rem;
        font-weight: bold;
        margin-bottom: 0.3rem;
      }
    }

    .contentWrapper {
      display: flex;
      flex-direction: row;
      position: relative;

      @include breakpoint-max(xs) {
        left: 0;
      }

      .unit-specific-content {
        flex-direction: column;
        display: flex;

        .contentDescription {
          margin-bottom: 1rem;
        }

        .resultsDescription {
          margin: 1rem 0;
        }

        .unit-status,
        .test-status {
          display: flex;
          justify-content: flex-start;
          align-items: center;

          @include breakpoint-max(xs) {
            margin-bottom: 2rem;
          }

          .live-class {
            font-weight: bold;
            margin-right: 0.4rem;
          }

          .icon-check {
            width: 0.8rem;
            height: 0.8rem;
            margin-left: 0.2rem;
          }
        }

        .unit-status {
          margin-bottom: 4rem;
        }

        .test-status {
          margin-bottom: 1rem;
        }

        .assignments,
        .support,
        .assessments {
          margin-bottom: 2rem;
          .header {
            display: flex;
            justify-content: space-between;
            width: 100%;
            margin-bottom: 0.6rem;
            .title {
              text-transform: uppercase;
            }
            .lesson-summary {
              color: $grey-dark;
              font-size: 1rem;
              display: flex;
              justify-content: center;
              align-items: center;
              gap: 0.3rem;
              text-decoration: underline;
              font-weight: 500;

              &:hover {
                opacity: 0.6;
              }

              svg {
                width: 15px;
                height: 15px;
              }
            }
          }

          .resources {
            display: flex;
            flex-direction: column;
            gap: 1.2rem;
          }
        }

        .assessments {
          margin-top: 2rem;
        }
      }
    }
  }
}

.completed {
  background-color: $grey-dark;
}

.futureContent {
  color: $grey-light-button;
}

.notBooked {
  color: $grey-light-dark;
  margin-right: 0.5rem;
}

.dropzoneError {
  margin-top: 10px;
  margin-bottom: 10px;
  color: $soft-red;
}