@import '../../../style/utils/colors';

.modalWrapper {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  inset: 0;
  display: flex;
  z-index: 2000;
  justify-content: center;

  .background {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    inset: 0;
    background-color: rgba(0, 0, 0, 0.55);
  }

  .contentWrapper {
    position: relative;
    top: 2vh;
    max-height: 95vh;
    overflow-y: auto;
    border-radius: 5px;

    .closeButton {
      position: absolute;
      right: 1%;
      top: 2%;
      z-index: 1000;
      padding: 0 !important;
    }

    .progressBar {
      position: absolute;
      padding: 0 !important;
      width: 100%;
      height: 1vh;
      top: 0;
      background-color: $mint;
      transition: width 1s;
      border-radius: 5px 5px 0 0;
      z-index: 1000;

      &.salmon {
        background-color: $salmon;
      }

      &.middle {
        position: absolute;
        left: 0;
        width: 50%;
        border-radius: 10px 0 0 0;
      }

      &.done {
        position: absolute;
        left: 0;
        width: 100%;
      }
    }

    .close-button {
      position: absolute;
      right: 1%;
      top: 1%;
      padding: 0.25rem 0.5rem;
      border-radius: 50%;

      &:hover {
        background-color: $grey-dark;
        color: $white;
      }
    }

    .content {
      background-color: $white;
      border-radius: 10px;
      overflow-y: auto;
    }

    & > div {
      padding: 1.8rem 3.5vw;
    }
  }

  &.noPadding .contentWrapper {
    div {
      padding: 0;
    }
  }
}
