@import '../../../../style/utils/colors';
@import '../../../../style/utils/breakpoints';

.changeCoachModalWrapper {
  font-size: 16px;
  background-color: $white;
  min-width: 530px;
  width: 100%;
  margin: 0 auto;

  @include breakpoint-max(sm) {
    font-size: 12px;
    min-width: unset;
    width: 85vw;
  }

  .modalBackButton {
    position: absolute;
    top: 3%;
    left: 3%;
    background-color: transparent;
    outline: none;
    border: none;
    color: $grey-dark;
    font-size: 1em;

    &::before {
      content: ' ';
      width: 0.5em;
      height: 0.5em;
      border-left: 1px solid $grey-dark;
      border-bottom: 1px solid $grey-dark;
      position: absolute;
      top: 30%;
      left: -45%;
      transform: rotate(45deg);
    }

    &:hover {
      color: $grey-light-button;

      &::before {
        border-left: 1px solid $grey-light-button;
        border-bottom: 1px solid $grey-light-button;
      }
    }
  }

  .changeCoachSummary {
    p {
      font-size: 1em;
      margin: 0;
      text-align: center;
    }

    .bookingSummaryList {
      margin-top: 6.5vh;

      .textareaLabel {
        text-align: left;
        font-weight: 500;
      }

      .motivationTextarea {
        width: 100%;
        margin-top: 1.3vh;
        padding: 0.7em 1em;
        border: 1px solid $grey-button-disabled;
        border-radius: 5px;
        box-sizing: border-box;

        &::placeholder {
          font-size: 0.9em;
        }
      }
    }
  }

  .bookingModalSection {
    margin-top: 4.7vh;

    h2 {
      font-size: 1.125em;
      font-weight: bold;
      margin: 0;
    }

    @include breakpoint-max(sm) {
      margin-top: 4vh;
    }

    .livePracticesNumber {
      margin: 1.4vh 0 4.7vh;
    }

    .bookingOptionWithButton {
      width: 100%;
      display: flex;
      justify-content: flex-start;
      gap: 3%;
      margin-top: 2vh;

      @include breakpoint-max(sm) {
        flex-wrap: wrap;
      }

      button {
        white-space: nowrap;
        border-radius: 50px;
        border: none;
        outline: none;
        color: $grey-dark;
        padding: 0.5em 1em;

        @include breakpoint-max(sm) {
          height: 10vw;
          &:last-of-type {
            margin-top: 2vh;
          }
        }

        &.selected {
          background-color: $medium-mint;
        }
      }
    }

    &.withCalendar {
      width: 70vw;
      min-width: 700px;

      @include breakpoint-max(sm) {
        width: 100%;
        min-width: unset;

        p {
          font-size: 1.2em;
        }
      }
    }
  }

  .calendarStuff {
    margin-top: 2.2vh;
    display: flex;
    font-size: 14px;

    @include breakpoint-max(sm) {
      display: flex;
      flex-direction: column;
    }

    .bookingSummary {
      width: 50%;
      border: 1px solid $grey-light-button;
      border-radius: 4px;
      background-color: $white;
      padding-top: 15px;
      margin-left: 0.8vw;
      max-height: 37vh;
      overflow-y: auto;

      @include breakpoint-max(sm) {
        width: 100%;
        margin: 4vw 0 0 0;
        max-height: 25vh;
        overflow-y: auto;
      }

      h1 {
        margin: 0;
        font-size: 1.1em;
        text-transform: uppercase;
      }

      .bookingSummaryList {
        margin-top: 1vh;
        display: flex;
        justify-content: center;
        align-items: center;

        .loadingContainer {
          width: 30px;
          height: 30px;
        }

        ul {
          width: 100%;
          list-style: none;

          li {
            border-bottom: 1px solid $gray85;

            &:last-of-type {
              border-bottom: none;
            }
          }
        }
      }
    }
  }

  #timezoneMessage {
    font-size: 0.875em;
    margin-top: 6px;
  }

  .errorWhileBooking {
    width: 100%;
    margin-top: 1em;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 1em;

    svg {
      width: 30px;
      path {
        fill: $tooltip-highlight;
      }
    }

    .errorDescription {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;

      p:first-of-type {
        font-size: 0.9em;
        border-bottom: 3px solid $tooltip-highlight;
      }
      p:last-of-type {
        font-weight: 600;
      }
    }

    ol {
      margin-left: 1em;
      li {
        font-size: 0.9em;
      }
    }
  }

  .errorWhileBooking ~ .buttonsWrapper {
    margin: 2vh auto 1vh !important;
  }
}

.buttonsWrapper {
  display: flex;
  justify-content: space-around !important;
  width: 100%;
  height: 6vh;
  margin: 6vh auto 2vh;

  button {
    width: 30%;
  }

  @include breakpoint-max(sm) {
    width: 80%;
  }
}
