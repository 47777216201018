@import '../../../style/utils/colors';
@import '../../../style/utils/breakpoints';

.welcome-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10vh 0;
  gap: 2vh;

  @include breakpoint-max(sm) {
    padding: 5vh 0;
    width: 88%;
    margin: 0 auto;
  }

  .welcome-title {
    font-size: 2.5em;
    margin: 0;
    text-align: center;
  }

  .welcome-subtitle {
    font-size: 1.2em;
    text-align: center;
  }

  button {
    width: 20%;
    margin: 5vh 0 0;

    @include breakpoint-max(sm) {
      width: 50%;
      margin: 3vh 0 0;
    }
  }

  .welcome-hint {
    color: $grey-light-button-font;
  }
}
