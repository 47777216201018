@import '../../../style/utils/colors';

.link {
    background-color: transparent;
    font-size: 1em;
    text-decoration: underline !important;
    color: $link-default !important;

    &:hover {
        color: $link-hover !important;
        cursor: pointer;
    }

    &:active {
        color: $link-active !important;
    }
}