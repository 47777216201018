@import '../../../../style/utils/colors';
@import '../../../../style/utils/breakpoints';

.messageContainer {
  width: 100%;
  box-sizing: border-box;

  .message {
    & > div {
      padding: 0.5em 1em 1em;
      box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
      max-width: 60%;

      @include breakpoint-max(xs) {
        max-width: 85%;
      }

      & > div {
        & > div:last-of-type {
          position: absolute;
          bottom: -0.5rem;
          font-size: 0.75rem;
        }

        img {
          max-width: 200px;
          max-height: 200px;
          object-fit: contain;
        }
      }
    }

    &__left {
      & > div {
        background-color: $super-extra-soft-mint;

        div {
          svg {
            fill: $super-extra-soft-mint;
          }
        }
      }
    }

    &__right {
      & > div {
        background-color: $active-grey;

        svg {
          fill: $active-grey;
        }
      }
    }

    * {
      font-size: 0.875rem;
      line-height: 1.2rem;
    }
  }

  .photo,
  .file {
    & > div > div {
      &:hover {
        cursor: pointer;
      }
    }
  }
}

.loadingMessage {
  position: relative;
  height: 5rem;
  width: 40%;
  padding: 0.5rem 1rem;
  border-radius: 5px;
  background-color: $grey-lightest;
  margin-bottom: 1rem;

  &__left {
    display: flex;
    justify-content: flex-start;
  }

  &__right {
    display: flex;
    justify-content: flex-end;
  }
}

.systemMessageContainer {
  margin-bottom: 1em;

  .systemMessage {
    & > div {
      padding: 1em;
      box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
      * {
        font-size: 0.875rem;
        line-height: 1.2rem;
      }

      @include breakpoint-max(xs) {
        max-width: 100%;
      }
    }
  }
}
