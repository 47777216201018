@import '../../../style/utils/colors';

.tag-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.5em 0.8em;
  color: $grey-dark;
  font-size: 0.75em;
  border-radius: 5px;
  font-weight: 500;

  &.grey {
    background-color: $grey-light-medium-dark;
  }

  &.mint {
    background-color: $tag-mint;
  }

  &.salmon {
    background-color: $tag-salmon;
  }
}
