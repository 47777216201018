@import '../../../style/utils/colors';
@import '../../../style/utils/breakpoints';

.cardWrapper {
  display: flex;
  width: 48%;
  max-height: 166px;

  @include breakpoint-max(sm) {
    width: 100%;
  }

  .contentContainer {
    width: 100%;
    padding: 2.375rem 1.875rem;
    display: flex;
    gap: 1.5vw;
    justify-content: space-between;
    align-items: center;
    background-color: $white;
    border-radius: 10px;
    border: 2px solid $border-grey;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;

    @include breakpoint-max(sm) {
      max-width: unset;
      padding: 1.8rem 1.5rem;
      gap: 3vw;
    }

    .profile-picture--wrapper {
      width: 90px;
      height: 90px;

      @include breakpoint-max(sm) {
        width: 57px;
        height: 55px;
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        clip-path: circle(50% at center);
      }
    }

    .icon--wrapper {
      width: 90px;
      height: 90px;
      display: flex;
      justify-content: center;
      align-items: center;
      clip-path: circle(50% at center);
      background-color: $salmon;

      @include breakpoint-max(sm) {
        width: 57px;
        height: 55px;

        svg {
          width: 30px;
          height: 30px;
        }
      }
    }

    .card-data--wrapper {
      width: 75%;
      height: 100%;
      position: relative;

      .button--wrapper {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        text-align: left;
        position: relative;

        h2 {
          font-weight: 700;
          line-height: 1.75rem;
          white-space: normal;
          padding-right: 1em;

          @include breakpoint-max(sm) {
            font-size: 1.1rem;
            line-height: 1.2rem;
          }
        }

        &:after {
          position: absolute;
          right: 0;
          top: 50%;
          content: ' ';
          width: 6px;
          height: 6px;
          border-top: 3px solid $grey-dark;
          border-right: 3px solid $grey-dark;
          border-radius: 1px;
          transform: rotate(45deg) translate(-50%, -50%);
        }
      }

      .missing-lessons--wrapper {
        position: absolute;
        bottom: -1.75rem;
        font-size: 0.875rem;
        white-space: nowrap;

        span {
          font-weight: 500;
        }

        @include breakpoint-max(sm) {
          bottom: -1.6rem;
        }
      }
    }

    &.all-booked {
      background-color: $mint-opaque;

      .card-data--wrapper {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        gap: 0.5vh;

        h2 {
          font-weight: 700;
        }
        p {
          font-weight: 500;
        }
      }
    }
  }
}

.loaderContainer {
  width: 492px;
  height: 166px;
  background-color: $white;
  border-radius: 10px;
  border: 2px solid $border-grey;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
  padding: 1.5rem 0 1.5rem 1.5rem;

  @include breakpoint-max(sm) {
    margin: 0;
    height: 100%;
    min-height: unset;
  }
}
