@import '../../../style/utils/colors';

.MuiInputBase-root {
  position: relative;
  background-color: transparent;
  font-family: Karla, sans-serif !important;
  &::before {
    border-bottom: none !important;
  }
}

.border-bottom {
  bottom: -5px !important;
  border-bottom: 9px solid $smooth-peach !important;
}

.confirm-button {
  position: absolute;
  right: -70px;
  top: 50%;
  transform: translateY(-50%);

  button {
    font-size: 0.75rem !important;
  }
}
