@import '../../style/utils/colors';
@import '../../style/utils/breakpoints';

.row {
  td {
    width: 100%;
    text-align: center;
    padding: 0.75rem 0.5rem;
    vertical-align: middle;
    font-size: 0.9rem;

    * {
      font-size: 0.9rem;
      line-height: 1rem;
    }
  }
}

.status-cancelled {
  color: $soft-red;
}

.join-button {
  white-space: normal;     /* Allows text to wrap */
  word-wrap: break-word;   /* For older browsers */
  word-break: break-word;  /* Breaks long words */
  text-align: center;      /* Centers the text */
  max-width: 100%;         /* Ensures the button doesn't exceed container width */
  padding: 10px;           /* Adjust padding as needed */
}
/* New color-coded status styles */
.status-active {
  color: #2e7d32; /* Soft green for text */
  background-color: #e8f5e9; /* Very light green background */
}

.status-at-risk {
  color: #ff9800; /* Soft amber for text */
  background-color: #fff3e0; /* Very light amber background */
}

.status-inactive {
  color: $soft-red; /* Soft red for text */
  background-color: #ffebee; /* Very light red background */
}