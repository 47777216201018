@import '../utils/colors';

.iframe {
  width: 100%;
  height: 100%;
  flex-grow: 1;
}

.iframeHidden {
  display: none;
}

.iframeWrapper {
  position: absolute;
  top: 60px;
  height: calc(100vh - 60px);
  width: calc(100% - 58px);
  display: flex;
  flex-direction: column;
  min-height: 82vh;
  margin-left: 58px;
}

.iframeLoader {
  width: 100%;
  height: 100%;
  flex-grow: 1;
  align-items: center;
  justify-content: center;
}

.noAssignmentCopy {
  width: 80%;
  margin-left: 10%;
  margin-right: 10%;
  margin-top: 50px;
  text-align: center;
}

.sideBar {
  position: sticky;
  top: 0;
  left: 0;
  width: 58px;
  background-color: $grey-dark;
  /* height: calc(95vh - 60px - 32px - 20px); */
  height: calc(100vh - 60px);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  overflow-x: hidden;
  padding-bottom: 40px;
  z-index: 1;
}

.paddingClosed,
.sideBarClosed {
  width: 58px;
  transition: width 0.5s;
  overflow-x: hidden;
}

.paddingOpen,
.sideBarOpen {
  width: 371px;
  max-width: 100vw;
  transition: width 0.5s;
}

.buttonIcon {
  width: 16px;
  height: 16px;
}

.crossIcon {
  margin-left: 3px;
  margin-top: 2px;
}

.iconWrapper {
  background-color: transparent;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 25px;
  outline: 0;
  margin-top: 14px;
  width: 100%;
  min-height: 35px;
}

.lessonsWrapper {
  color: $white;
  width: 371px;
  max-width: 100vw;
}

.lessonNameWrapper,
.resourceNameWrapper {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding-top: 15px;
  font-size: 1em;
  padding-left: 25px;

  &:hover {
    color: $gainsboro;
    cursor: pointer;

    &.lessonIcon {
      background-color: $gainsboro;
    }
  }
}

.resourceNameWrapper {
  padding-left: 56px;
  background-color: $grey-medium-dark;
}

.resourceSelected {
  text-decoration: underline;
}

.lessonIcon {
  background-color: $white;
  width: 7px;
  height: 7px;
  border-radius: 100%;
  margin-right: 7px;
}

.iconPlaceholder {
  width: 7px;
  height: 7px;
  background-color: transparent;
  margin-right: 7px;
}

.visible {
  opacity: 1;
  visibility: visible;
  transition: opacity 2s linear;
}

.invisible {
  opacity: 0;
  visibility: hidden;
  transition: visibility 0s 2s, opacity 2s linear;
}

.lessonNameWrapperSelected {
  background-color: $grey-medium-dark;
}

.lessonNameWrapperDisabled {
  color: $grey-medium-dark;
  cursor: default;
}

.lessonNameWrapperDisabled .lessonIcon {
  background-color: $grey-medium-dark;
}

.lessonNameWrapperDisabled:hover {
  color: $grey-medium-dark;
  cursor: default;
}

.lessonNameWrapperDisabled:hover .lessonIcon {
  background-color: $grey-medium-dark;
}

.resourceBottomPadding {
  background-color: $grey-medium-dark;
  height: 15px;
}

// .sidebarPadding {
//   background-color: $grey-dark;
//   height: 40px;
//   position: fixed;
//   top: calc(95vh - 92px);
// }

* {
  margin: 0;
  padding: 0;
}

/* Icon 1 */

.menuIcon {
  width: 35px;
  height: 35px;
  position: relative;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: 0.5s ease-in-out;
  -moz-transition: 0.5s ease-in-out;
  -o-transition: 0.5s ease-in-out;
  transition: 0.5s ease-in-out;
  cursor: pointer;
  margin-left: 11.5px;
}

.iconWrapper:hover {
  opacity: 0.7;
}

.menuIcon span {
  display: block;
  position: absolute;
  height: 4px;
  width: 100%;
  background: $mint;
  border-radius: 4px;
  opacity: 1;
  left: 0;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: 0.25s ease-in-out;
  -moz-transition: 0.25s ease-in-out;
  -o-transition: 0.25s ease-in-out;
  transition: 0.25s ease-in-out;
}

/* Icon 3 */

.menuIcon span:nth-child(1) {
  top: 0px;
}

.menuIcon span:nth-child(2),
.menuIcon span:nth-child(3) {
  top: 10px;
}

.menuIcon span:nth-child(4) {
  top: 20px;
}

.menuIcon.open span:nth-child(1) {
  top: 10px;
  width: 0%;
  left: 50%;
}

.menuIcon.open span:nth-child(2) {
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
}

.menuIcon.open span:nth-child(3) {
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

.menuIcon.open span:nth-child(4) {
  top: 10px;
  width: 0%;
  left: 50%;
}

.closeMenu {
  color: $mint;
  font-size: 16px;
  margin-bottom: 10px;
  margin-left: 5px;
  -webkit-transition: 0.25s ease-in-out;
  -moz-transition: 0.25s ease-in-out;
  -o-transition: 0.25s ease-in-out;
  transition: 0.25s ease-in-out;
}

.has-tip {
  cursor: help;
  display: inline-block; // so it shrinks to content's width
  position: relative;
  text-align: center;
  -webkit-transform: translateZ(0); /* webkit flicker fix */
}

@keyframes animateTooltip {
  0% {
    left: 0;
    opacity: 0;
  }
  50% {
    left: 0;
    opacity: 0;
  }
  100% {
    left: 75px;
    opacity: 1;
  }
}

.tooltip {
  background: $mint;
  top: 70px;
  color: #fff;
  display: block;
  // left: 75px;
  color: #fff;
  margin: auto;
  margin-bottom: 15px;
  // opacity: 1;
  padding: 13px;
  pointer-events: cursor;
  position: absolute;
  left: 75px;
  opacity: 1;
  color: $grey-dark;

  animation-name: animateTooltip;
  animation-duration: 4s;
}

// Tooltip's Triangel
$tooltip-tip-size: 10px;
.tooltip::after {
  border: transparent solid $tooltip-tip-size;
  border-top-color: $mint;
  content: '';
  transform: rotate(90deg);
  // position the tip -> center
  left: -33px;
  margin: auto;
  position: absolute;
  top: 0;
  bottom: 0;
  width: 0;
}

/* IE can just show/hide with no transition */
.lte8 .tooltip {
  display: none;
}

.lte8 .tooltip {
  display: block;
}

.hideTooltip {
  opacity: 0;
  // transition: all .5s ease-out;
}

.closeButton {
  margin-left: 5px;
}

.ceregoBanner {
  width: 100%;
  height: 60px;
  background-color: $blumine;
  color: $white;
  z-index: 200;
  position: absolute;
  top: 60px;
  padding: 5px;
  padding-left: 10px;
  padding-right: 10px;
  text-align: center;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.ceregoBanner a {
  color: $white;
  text-decoration: underline;
}

.modalWrapper {
  width: 60vw;
  max-width: 800px;
  padding: 1em 2em;

  h3 {
    text-align: center;
    font-size: 1.2em;
    margin-bottom: 2em;
  }

  p {
    margin-bottom: 1em;
    font-size: 1em;
  }

  li {
    font-size: 1em;
    margin-left: 2em;
    list-style-type: none;
  }

  .italic {
    font-size: 1em;
    font-style: italic;
  }

  button {
    width: 30%;
    margin: 7vh auto 0;
  }
}
