.animated-dots-wrapper {
  display: inline;
  margin-left: 0.3em;

  .first-dot {
    display: inline-block;
    animation: jump 0.5s ease-in-out alternate infinite;
  }

  .second-dot {
    display: inline-block;
    animation: jump 0.5s 0.2s ease-in-out alternate infinite;
  }

  .third-dot {
    display: inline-block;
    animation: jump 0.5s 0.35s ease-in-out alternate infinite;
  }
}


@keyframes jump {
  from {
    transform: translateY(0);
  }
  to {
    transform: translateY(-0.4em);
  }
}