.toolbar button {
  height: 30px;
}

.menuGlyph {
  font-size: 14px;
}

.heading {
  font-weight: bold;
  text-align: center;
  margin-bottom: 10px;
}

.viewNav {
  text-align: right;
}

.mobileOnly {
  display: none;
}

@media (max-width: 375px) {
  .navBlock {
    width: 50%;
  }

  .mobileOnly {
    display: block;
  }

  .desktopOnly {
    display: none;
  }
}
