@import '../../../style/utils/colors';

.wrapper {
  min-width: 35px;
  height: 100%;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;

  .attachButton {
    background-color: $mint;
    border: 1px solid $mint;
    border-radius: 3px;
    width: 100%;
    height: 100%;
    padding: 5px;
    display: flex;
    justify-content: center;
    align-items: center;

    &:disabled {
      background-color: $grey-lightest;
      border: 1px solid $grey-lightest;

      &:hover {
        background-color: $grey-lightest;
        opacity: 1;
        cursor: auto;
          
        svg {
          animation: none;
        }
      }
    }

    svg {
      width: 75%;
      height: 75%;
    }

    &:hover {
      background-color: $mint;
      cursor: pointer;
      opacity: 0.8;

        svg {
          animation: ring 0.5s ease-in-out 1 forwards;
        }
      }
    }
}

@keyframes ring {
  0% {
    transform: translateX(-10%);
  }
  20% {
    transform: translateX(10%);
  }
  40% {
    transform: translateX(-10%);
  }
  60% {
    transform: translateX(10%);
  }
  80% {
    transform: translateX(-10%);
  }
  100% {
    transform: translateX(0);
  }
}
