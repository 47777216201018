@import '../../style/utils/colors';
@import '../../style/utils/breakpoints';

$link-color: $mint;
$padding-vertical: 20px;

.reference {
  position: relative;
}

.isActive {
  background-color: $black !important;

  .button-wrapper {
    background-color: $black !important;

    span {
      font-weight: 700 !important;
      color: $white !important;
    }
  }

  & svg {
    filter: brightness(10);
  }
}

.link-wrapper {
  height: 100%;
  display: flex;

  .menuItem {
    position: relative;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: 0.1s background-color linear, 0.1s color linear;
    padding: 0 1.2rem;

    @include breakpoint-max(xs) {
      background-color: $grey-dark;
      width: 100%;
      height: 60px;
      z-index: 1000;
    }

    span {
      color: $link-color;
      text-transform: uppercase;
      font-size: 0.875rem;
      font-weight: 500;
      letter-spacing: 0.6px;
    }

    &:hover {
      cursor: pointer;
      background-color: $grey-hard-dark;
      span {
        color: $soft-mint;
      }
    }

    &.showBadge {
      &:after {
        position: absolute;
        content: ' ';
        background-color: $soft-red;
        width: 14px;
        height: 14px;
        border-radius: 100%;
        top: 0;
        left: 55px;
        transform: translate(-50%, 50%);
        z-index: 5000;

        @include breakpoint-max(xs) {
          left: 43%;
        }
      }
    }

    .button-wrapper {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 0.5em;

      @include breakpoint-max(xs) {
        justify-content: flex-start;
        margin-left: 30vw;
        width: 100%;
        height: 60px;
        z-index: 1000;
      }

      .background {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 25px;
        height: 25px;
        background-color: $link-color;
        box-sizing: content-box;
        padding: 0.5em;
        border-radius: 100%;

        h1 {
          font-size: 2em;
        }

        svg {
          width: 22px;
          height: 22px;
        }
      }

      span {
        font-size: 0.75rem !important;
        max-width: 7vw;
        line-height: 1.2em;

        @include breakpoint-max(xs) {
          max-width: unset;
          font-size: 1rem !important;
        }
      }
    }
  }
}

.options-wrapper {
  position: absolute;
  right: 0;
  transform: translateY(-120%);
  z-index: 2000;
  background-color: $background-extra-soft-mint;
  border-radius: 0 0 5px 5px;
  animation: show-options 0.2s ease-out forwards;

  @include breakpoint-max(xs) {
    left: 0;
    top: 120px;
  }

  ul {
    h3 {
      font-size: 1em;
      color: $grey-dark;
      padding: 1em 1.5em;
      box-sizing: content-box;
      position: relative;

      &::after {
        content: ' ';
        position: absolute;
        left: 1.5em;
        width: 50%;
        bottom: 0;
        height: 1px;
        border-bottom: 2px solid $mint;
      }
    }
    li {
      .chat-wrapper {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: 0.5em;

        .background {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 25px;
          height: 25px;
          background-color: $link-color;
          box-sizing: content-box;
          padding: 0.5em;
          border-radius: 100%;

          svg {
            width: 100%;
            height: 100%;
          }
        }
      }

      &:hover {
        background-color: $extra-soft-mint;
        cursor: pointer;
      }

      a {
        display: block;
        font-size: 1em;
        line-height: 1.2em;
        font-size: 1em;
        width: 20vw;
        padding: 1em 1.5em;
        box-sizing: content-box;
        color: $grey-dark;

        @include breakpoint-max(xs) {
          width: 100%;
          padding: 1.2em 1.8em;
          box-sizing: border-box;
        }
      }
    }
  }
}

@keyframes show-options {
  from {
    transform: translateY(-120%);
  }
  to {
    transform: translateY(0);
  }
}
