@import '../../utils/colors';

.selectButton {
  border-radius: 20px !important;
  background-color: $grey-lightest;
  border: none !important;
  box-shadow: none !important;
  padding: 10px 16px;

  &:hover {
    background-color: $grey-light-dark;
  }
}

.selectButton--active {
  background-color: $medium-mint;

  &:hover {
    background-color: $extra-dark-mint;
  }
}
