@import '../../../style/utils/colors';
@import '../../../style/utils/breakpoints';

.content-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;

  .title {
    margin: 0 0 0.4em 0;
    text-align: center;

    @include breakpoint-max(sm) {
      margin: 0 0 0.5em 0;
    }
  }

  .subtitle {
    font-size: 1em;
    margin-bottom: 4em;
    text-align: center;

    @include breakpoint-max(sm) {
      margin: 0 0 1.5em 0;
    }

    .link {
      color: $link-default;

      &:hover {
        cursor: pointer;
        color: $link-hover;
        text-decoration: underline;
      }
    }
  }

  .filter {
    width: 100%;
    margin: -3vh 0 2vh;

    @include breakpoint-max(sm) {
      margin: 0 0 2vh;
    }
  }

  .coach-cards-wrapper {
    position: relative;
    display: flex;
    justify-content: flex-start;
    box-sizing: border-box;
    gap: 4vw;
    width: 87vw;
    overflow-x: auto;
    margin-bottom: 2em;
    padding-bottom: 1.5em;
  }
}
