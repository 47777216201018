@import '../../utils/breakpoints';
@import '../../utils/colors';

.container {
  background-color: $white;
  border-bottom: 1px solid $color-darkener;
  padding: 30px 0;
  align-items: center;
  flex-direction: column;
  padding-top: 0;
  font-size: 1em;

  svg {
    width: 20px;
    height: 20px;
  }
}

.otherPro {
  margin-right: 5px;
  width: fit-content;
  height: fit-content;
  width: 70%;
}

.startBooking {
  cursor: pointer;
  text-decoration: underline;
}

.bookButton {
  width: 100%;
  font-size: 1vw !important;
  padding: unset !important;

  @include breakpoint-max(sm) {
    font-size: 14px !important;
  }
}

.editingOtherPro {
  margin-top: 1em;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 1em;
}

.autocompleteTagCross {
  background-color: $black;
  color: $white;
  padding: 0.2em 0.4em;
  font-size: 0.75rem;
  border-radius: 50%;
  margin-left: 1em;
  &:hover {
    cursor: pointer;
    opacity: 0.8;
  }
}

.label {
  margin-left: 25px;
}

.secondLabel {
  display: block;
  color: $grey-light-button-font;
  font-size: 0.9em;
  letter-spacing: 0.3px;
  line-height: 1.1em;
  margin-top: 1em;
  width: 100%;

  a {
    font-size: 0.9em;
  }
}

.mainLabel {
}

.callToAction {
  height: 40px;
  background-color: $mint;
  margin-top: 4%;
  border-radius: 5px !important;
  box-shadow: none !important;
}

.saveChangesButton {
  height: 40px;
  background-color: $mint;
  box-shadow: none !important;
  margin-right: 15px;
}

.tutorPreviewHeader {
  top: 1em;
  right: 1em;
  padding: 0.5em 1em;

  .editing-wrapper {
    display: flex;
    justify-content: space-around;
    align-items: center;
    gap: 2vw;
    background-color: $super-extra-soft-mint;
    padding: 1em 2em;
    border-radius: 5px;
    margin: 0 auto;
    width: 60vw;

    .tutorPreviewTitle {
      font-size: 1.1em;
      margin: 0;
    }

    button {
      max-width: 12vw;
      white-space: nowrap;
    }

    .editingHeaderButtonsContainer {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 2vw;
    }
  }
}

.editingHeaderContainer {
  width: 50%;
  margin: 35px auto 0 auto;
}

.editingHeaderContainer input[type='checkbox'] {
  /* Double-sized Checkboxes */
  -ms-transform: scale(2); /* IE */
  -moz-transform: scale(2); /* FF */
  -webkit-transform: scale(2); /* Safari and Chrome */
  -o-transform: scale(2); /* Opera */
  transform: scale(2);
  padding: 10px;
  top: 6px;
}

.editingHeaderButtonsContainer {
  display: flex;
  margin: 0 auto;
}

.centerText {
  text-align: center;
}

.header {
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.tutorInfoContainer {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
}

.tutorNameHeader {
  background-color: $whisper;
  height: 15vh;
  width: 100%;
  display: flex;
  justify-content: center;
  position: absolute;

  @include breakpoint-max(sm) {
    position: static;
  }
}

.textContainer {
  .bio-textarea {
    margin-bottom: 2em;

    label {
      font-size: 1em;
      font-weight: 500;
      color: $grey-dark;
      margin-top: 0.5em;
    }

    textarea {
      width: 100%;
      box-sizing: border-box;
      border-radius: 5px;
      border: 1px solid $grey-light;
      margin-top: 1em;
    }
  }
}

.heading {
  margin-top: 0;
}

.mobilePhoto {
  display: none;
}

.columnLeft {
  width: 17%;
  text-align: left;
  padding-right: 2%;
  margin-top: 50px;
  z-index: 1;

  @include breakpoint-max(xs) {
    display: flex;
    flex-direction: column;
    align-items: center;

    .mobilePhoto {
      width: 50vw;
    }

    .tutorInfo {
      margin-left: 0;

      button {
        width: 60vw;
        height: 12vw;
        font-size: 1em;
        font-weight: 600;
        text-transform: uppercase;
        padding: 0.2em 0.7em;
      }
    }
  }

  .desktopPhoto {
    position: relative;
  }
}

.columnLeft h2 {
  margin: 0;
  margin-top: 10px;
}

.columnRight {
  width: 55%;
  text-align: left;
  padding-left: 2%;
  margin-top: 1vw;
  z-index: 1;
  .meet {
    font-size: 3em;
    text-align: left;
    margin-bottom: 4vw;

    @include breakpoint-max(sm) {
      text-align: center;
    }
  }
}

.starContainer {
  margin-top: 4%;
  text-align: left;
}

.bio {
  word-break: break-word;
}

.tutorIcon {
  margin-right: 5px;
  font-size: 0.7em;
  width: 20px;
}

.quoteIcon {
  width: 25px;
}

.quoteContainer {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  margin-top: 20px;
}

.quoteName {
  margin-left: 52px;
}

.sectionHeader {
  margin-top: 2em;
  margin-bottom: 0.6em;
  display: flex;
  justify-content: flex-start;
  gap: 2vw;
  align-items: center;

  button {
    width: 15vw;
    font-size: 0.875rem;
    white-space: normal;
  }
}

.otherText {
  display: inline-block;
}

.inReview {
  margin-left: 5px;
}

.tutorInfo {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 2vh;

  &__buttonContainer {
    width: 100%;
    height: 53px;
  }

  .callToAction {
    background-color: $grey-dark;
    color: #fff;

    &:hover {
      background-color: $active-grey;
      color: $grey-dark;
    }
  }
}

@media (max-width: 770px) {
  .desktopPhoto {
    display: none;
  }

  .mobilePhoto {
    display: block;
    text-align: center;
    position: relative;
    margin-top: -25vw;
  }

  .container {
    display: block;
  }

  .textContainer {
    width: 100%;
    margin-right: 0;
    margin-top: 20px;
  }

  .heading {
    text-align: center;
  }

  .tutorPreviewTitle {
    font-size: 20px;
    margin-right: unset;
    margin-bottom: 9%;
  }

  .tutorInfoContainer {
    flex-direction: column;
    padding: 0 15px;
  }

  .columnLeft {
    margin: 0 auto;
    width: fit-content;
  }

  .columnRight {
    width: 100%;
  }

  .tutorInfo {
    margin-left: -10vw;
  }

  .quoteIcon {
    margin-right: unset;
  }
}

.languagesText {
}

.dropZone {
  position: absolute !important;
  width: 100%;
  height: 100%;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  color: $white;
  background-color: $color-darkener;
  border: $soft-yellow 2px solid;
  border-radius: 50%;
  &:hover {
    cursor: pointer;
    opacity: 0.8;
  }
}

.category {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 0.2em;
  margin: 1em 0 0.3em;

  h2 {
    margin: 0;
    font-size: 2em;
  }
}

.categoryTitle {
  font-size: 1.5em;
  font-weight: bold;
}

.infoIcon {
  color: #dad6d6;
  font-size: 18px;
  top: 3px;
  left: 5px;
  position: relative;
}

.infoIcon .tooltipText {
  border: 1px solid #333333;
  opacity: 0;
  width: 205px;
  background-color: white;
  padding: 10px;
  position: absolute;
  z-index: 200;
  top: 0px;
  left: -159px;
  cursor: default;

  @include breakpoint-max(xs) {
    transform: translate(-100%, -120%);
  }
}

.infoIcon.showTooltip {
  color: $grey-light-dark;
}

.infoIcon:hover {
  color: $grey;
}

.infoIcon .tooltipText p {
  font-size: 16px !important;
  color: #777777;
}

.infoIcon:hover .tooltipText,
.infoIcon.showTooltip .tooltipText {
  opacity: 1 !important;
}
