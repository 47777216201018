@import '../utils/breakpoints';
@import '../utils/colors';

.outerWrapper {
  display: flex;;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.explanationRow {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  padding: 5%;
  padding-left: 10%;
  padding-right: 10%;
  width: 100%;

  @include breakpoint-max(sm) {
    padding-left: 5%;
    padding-right: 5%;
    flex-direction: column;
  }
}

.explanationRowInv {
  @include breakpoint-max(sm) {
    flex-direction: column-reverse !important;
  }
}

.explanationWrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

.explanationImg {
  width: 35%;
  @include breakpoint-max(sm) {
    width: 70%;
    margin: 5%;
  }
}

.imgLeft {
  margin-right: 5%;
  @include breakpoint-max(sm) {
    margin-left: 5%;
  }
}

.imgRight {
  margin-left: 5%;
  @include breakpoint-max(sm) {
    margin-right: 5%;
  }
}

.wrapperBeige {
  background-color: $soft-yellow;
}

.wrapperWhite {
  background-color: $white;
}

.explanationContent {
  text-align: left;
  font-size: 18px;
}

h2.explanationContent {
  margin-top: 0;
  font-size: 24px;
  font-weight: bold;
}

.headerContent {
  margin-left: 25%;
  margin-right: 25%;

  @include breakpoint-max(sm) {
    margin-left: 5%;
    margin-right: 5%;
  }
}

.spacer {
  height: 50px;
}

.explanationList {
  list-style: none;
}

.explanationList li:before {
  content: '●';
  color: $soft-orange;
  margin-right: 10px;
  margin-left: -20px;
}

.explanationList li {
  margin-top: 5px;
  font-size: 16px;
}

.footer {
  background-color: $blue;
  width: 100%;
  padding: 3%;
  display: flex;;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.footer h2 {
  color: $white;
}

.nextButton {
  height: 40px;
  background-color: $mint;
  border-radius: 5px !important;
  box-shadow: none !important;
  margin-bottom: 1%;
}

.nextButton:hover {
  background-color: lighten($mint, 10%);
}

.otherButton {
  height: 40px;
  background-color: $grey-lightest;
  border-radius: 5px !important;
  box-shadow: none !important;
}

.otherButton:hover {
  background-color: darken($grey-lightest, 10%);
}
