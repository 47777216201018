@import '../../style/utils/colors';
@import '../../style/utils/breakpoints';

.container {
  margin: 2rem 6vw;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 0.5rem;
  overflow-x: hidden;
  position: relative;

  @include breakpoint-max(sm) {
    overflow: auto;
  }

  h1,
  h2 {
    text-align: left;
    margin: 0 0 0.5rem;
  }

  h1 {
    font-size: 1.5rem;
    font-weight: 700;

    span {
      position: relative;
      &:after {
        content: ' ';
        position: absolute;
        bottom: -5px;
        height: 5px;
        left: 0;
        right: 0;
        background-color: $mint;
      }
    }
  }

  h2 {
    margin-bottom: 2vh;
    font-size: 1.1rem;
    font-weight: 300;
  }
}

.table-container {
  width: 100%;
  min-width: 600px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
  border-radius: 8px;
  padding: 1.5rem 2rem;
  background-color: $white;
  overflow: auto;

  @include breakpoint-max(sm) {
    min-width: unset;
  }

  table {
    width: 100%;
    min-width: 600px;
    table-layout: fixed;
  }
}

.back {
  margin-bottom: 2vh;
}

.button-wrapper {
  display: flex;
  justify-content: flex-start;
  gap: 0.25rem;
  margin-top: 0.5rem;

  @include breakpoint-max(sm) {
    flex-direction: column;

    * {
      font-size: 1rem;
    }
  }

  * {
    font-size: 1rem;
  }
}


.scrollable-wrapper {
  overflow-x: auto; /* Enable horizontal scrolling */
  white-space: nowrap; /* Ensure content doesn't wrap */
}

.table {
  width: 100%;
  min-width: 600px; /* Ensure minimum width to fit content */
  border-collapse: collapse;
  table-layout: fixed; /* Ensures column widths are distributed equally */

  th, td {
    padding: 0.75rem; /* Adds spacing to prevent overlap */
    text-align: left;
    vertical-align: middle;
    border: 1px solid $grey-light; /* Optional: Add borders for visibility */
    text-wrap: wrap;
  }

  th {
    font-size: 0.95rem; /* Adjust font size */
    line-height: 1.25rem; /* Prevent overlap */
    background-color: $grey-light; /* Add a subtle background */
    
  }
  }


  .scrollable-table-specific {
    table-layout: fixed; /* Ensures column widths respect the fixed values */
    width: 100%; /* Stretch table to fit the container */
  }
  
  .scrollable-table-specific th, 
  .scrollable-table-specific td {
    width: 145px; /* Fixed column width */
    word-wrap: normal;
    text-wrap: wrap;
    overflow-wrap: break-word;
    white-space: normal; /* Enable wrapping */
    text-align: left; /* Optional: Adjust alignment if needed */
    vertical-align: top; /* Align text to the top of the cell */
    padding: 0.75rem 0.5rem; /* Maintain padding for readability */
  }
  
  .sticky-column {
    position: sticky;
    left: 0;
    z-index: 1;
    background-color: #fff; /* Match the table background */
    box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1); /* Optional shadow for visual separation */ 
    padding-right: 1px;
  }