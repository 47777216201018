@import '../../../../style/utils/colors';

.wrapper {
  display: flex;
  justify-content: flex-start;
  gap: 1.5rem;
  margin-top: 1rem;
}

#existing,
#selected,
#current {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.35rem;
  div {
    border-radius: 1rem;
    width: 1.5rem;
    height: 0.875rem;
  }

  p {
    font-size: 0.875rem;
    color: $grey-dark;
    position: relative;
  }
}

#existing {
  div {
    background-color: $grey-dark;
  }
}

#selected {
  div {
    background-color: $purple-heart;
  }
}

#current {
  div {
    border: 2px solid $purple-heart;
  }
}
