.test-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: 100%;

  .test {
    width: 100%;
  }

  .placementTestLoadingContainer {
    width: 60px;
    height: 60px;
    margin-top: 5vh;
  }

  .typeformContainer {
    width: 100%;
    height: 100vh;
  }
}
