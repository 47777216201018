@import '../../../../../style/utils/breakpoints';
@import '../../../../../style/utils/colors';

.live-practice-card {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  position: relative;
  gap: 1em;

  .coach-picture {
    min-width: 80px;
    min-height: 80px;
    border-radius: 50%;
    background-color: transparent;
    display: flex;
    justify-content: flex-start;
    align-items: center;

    img {
      width: 100%;
      height: 100%;
      max-width: 80px;
      max-height: 80px;
      object-fit: cover;
      clip-path: circle(50% at center);
    }

    &.clickable {
      &:hover {
        cursor: pointer;
        opacity: 0.9;
      }
    }
  }

  .date-wrapper {
    display: flex;
    flex-direction: column;
    gap: 0.5em;
    align-items: flex-start;

    .count-wrapper {
      width: 100%;
      h2, span {
        font-size: 1.875em;
        font-weight: 700;
        line-height: 1em;
        text-align: left;
        display: inline;
        color: $grey-dark;
  
        span {
          font-size: 1em;
          color: $grey-dark;
        }
      }
    }

    h2, span {
      font-size: 1.875em;
      font-weight: 700;
      line-height: 1em;
      text-align: left;
      display: inline;
      color: $grey-dark;

      span {
        font-size: 1em;
        color: $grey-dark;
      }
    }

    p {
      font-size: 0.875rem;
      line-height: 1.2rem;
      text-align: left;
    }
  }
}

.live-practice-card-only-text {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 0.5em;
  align-items: flex-start;
  justify-content: center;
  position: relative;

  h2 {
    font-size: 1.875em;
    font-weight: 700;
    line-height: 1em;
  }

  h3 {
    font-size: 1.4em;
    font-weight: 700;
    line-height: 1.2em;
  }

  p {
    font-size: 1.1em;
    font-weight: 500;
    line-height: 1.2em;
  }
}

.tooltip {
  position: absolute;
  bottom: -0.8em;
  right: -1em;

  span > span:last-of-type {
    @include breakpoint-max(sm) {
      transform: translateX(-115%);
    }
  }
}
