@import '../../../style/utils/colors';
@import '../../../style/utils/breakpoints';

.calendarAndClockWrapper {
  width: 100%;
  min-height: 350px;
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: stretch;

  @include breakpoint-max(sm) {
    width: 100%;
    max-height: 70vh;
    min-height: 210px;
    flex-direction: column;
  }

  .calendarAndTime {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 70%;

    @include breakpoint-max(sm) {
      width: 100%;
      height: 100%;
      overflow-y: scroll;
    }
  }

  .bookingSummary {
    width: 30%;
    border: 1px solid $grey-light-button;
    border-radius: 4px;
    min-height: 288px;
    height: 100%;
    background-color: $white;
    padding-top: 8px !important;
    margin-left: 0.8vw;
    overflow-y: auto;
    position: relative;

    @include breakpoint-max(sm) {
      width: 100%;
      margin: 4vw 0 0 0;
      max-height: 20vh;
      overflow-y: auto;
      min-height: 200px;
    }

    h1 {
      margin: 0;
      font-size: 1em;
      text-transform: uppercase;
    }

    .bookingSummaryList {
      margin-top: 1vh;
      display: flex;
      justify-content: center;
      align-items: center;

      .loadingContainer {
        width: 30px;
        height: 30px;
      }

      ul {
        width: 100%;
        list-style: none;

        li {
          border-bottom: 1px solid $gray85;

          &:last-of-type {
            border-bottom: none;
          }
        }
      }
    }

    .buttonsWrapper {
      position: sticky;
      bottom: 0;
      left: 0;
      right: 0;
      padding: 0.75rem 0 !important;
      background-color: transparent;
      display: flex;
      justify-content: center;

      &:after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        inset: 0;
        background-color: $white;
        z-index: -1;
        opacity: 0.85;
      }

      button {
        width: 50%;
      }
    }
  }
}

.calendar {
  width: 100%;
  height: 100%;
  padding: 1.5rem 2rem !important;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: $white;

  @include breakpoint-max(sm) {
    padding: 4vw 5vw !important;
  }

  .calendarHeader {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 1.5rem;

    @include breakpoint-max(sm) {
      justify-content: space-around;
    }

    .arrow {
      background-color: transparent;
      padding: 0 2em;
      border: none;
      position: relative;

      @include breakpoint-max(sm) {
        padding: 0;
      }

      &:hover {
        cursor: pointer;
        .rightArrow,
        .leftArrow {
          border-color: $white;
        }
        &::before {
          background-color: $grey-dark;
          border-radius: 50%;
          content: '';
          position: absolute;
          width: 25px;
          height: 25px;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
      }

      &:disabled {
        &:hover {
          cursor: auto;
          &::before {
            background-color: transparent;
          }
        }
      }

      .rightArrow,
      .leftArrow {
        width: 1vh;
        height: 1vh;
        transform: rotate(45deg);
        border: 1px solid $grey-dark;

        &.disabled {
          width: 1vh;
          height: 1vh;
          transform: rotate(45deg);
          border: 1px solid $grey-light-button;
        }
      }

      .rightArrow,
      .rightArrow.disabled {
        border-left: 0;
        border-bottom: 0;
      }

      .leftArrow,
      .leftArrow.disabled {
        border-right: 0;
        border-top: 0;
      }
    }

    span {
      font-size: 1em;
      color: $grey-dark;
      margin: 0;
      text-transform: uppercase;

      &:first-of-type {
        font-weight: 600;
      }
    }
  }

  .calendarDays {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    position: relative;

    .calendarColumn {
      display: flex;
      flex-direction: column;
      align-items: center;

      @include breakpoint-max(sm) {
        gap: 1vh;
      }

      .weekDay {
        text-align: center;
        font-weight: 600;
        font-size: 0.875em;
      }
      .day {
        text-align: center;
        padding: 0.5vw 1vw;
        border-radius: 50px;
        border: none;
        background-color: transparent;
        font-size: 0.875em;
        color: $grey-dark;
        position: relative;

        &:hover {
          &:not(.inactive) {
            cursor: pointer;
            color: $purple-heart;
            &::after {
              position: absolute;
              content: '';
              top: 0;
              left: 0;
              bottom: 0;
              right: 0;
              inset: 0;
              border: 2px solid $purple-heart;
              border-radius: 1rem;
            }
          }
        }

        &.selected {
          border: 2px solid $purple-heart;
          color: $purple-heart;

          &:hover {
            border: 2px solid $purple-heart;
            color: $purple-heart;
            cursor: pointer;
          }
        }

        &.inactive {
          background-color: transparent;
          color: $color-footer-link-hover;

          &:hover {
            background-color: transparent;
            color: $color-footer-link-hover;
            cursor: auto;
          }
        }

        &.already-selected {
          background-color: $purple-heart;
          color: $white;

          &:hover {
            background-color: $purple-heart;
            color: $white;
          }
        }

        &.previously-selected {
          background-color: $grey-dark;
          color: $white;

          &.selected {
            background-color: $grey-dark;
            color: $white;
          }

          &:hover {
            background-color: $grey-dark;
            color: $white;

            &:not(.inactive) {
              background-color: $grey-dark;
              color: $white;
            }
          }
        }
      }
    }
  }
}

.timer {
  background-color: $white;
  border: 1px solid #ccc;
  border-radius: 4px;
  width: 20%;
  min-height: 288px;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 8px !important;
  margin-left: 0.8vw;

  @include breakpoint-max(sm) {
    min-height: 25vh;
  }

  .timerHeader {
    display: flex;
    justify-content: center;
    span {
      color: $grey-dark;
      margin: 0;
      text-transform: uppercase;

      &:first-of-type {
        font-weight: 600;
      }
    }
  }

  .availableTimes {
    margin-top: 1rem;
    width: 100%;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    gap: 0.25rem;

    .timeSlots {
      box-sizing: content-box;
      padding: 5% 10%;
      text-align: center;
      border: none;
      background-color: transparent;
      font-size: 0.875em;
      color: $grey-dark;

      @include breakpoint-max(sm) {
        padding: 10%;
      }

      &:hover {
        background-color: $grey-dark;
        color: $white;
        cursor: pointer;
      }

      &.selected {
        background-color: $grey-dark;
        color: $white;
      }

      &.inactive {
        background-color: transparent;
        color: $color-footer-link-hover;

        &:hover {
          cursor: auto;
        }
      }
    }
  }
}

.emptySpace {
  color: transparent;
  background-color: transparent;
  padding: 0.5vw 1vw !important;
}

.loaderWrapper {
  position: absolute;
  background-color: $white;
  top: 0;
  bottom: 0%;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;

  .loader {
    animation: spin 1s infinite linear;
    background-image: url('/assets/icons/spinner.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: 40px;
    display: block;
    height: 40px;
    opacity: 0.25;
    width: 64px;
    margin: 0 auto;
  }
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.alert {
  max-width: 360px;
  margin-top: 1.5rem;
  height: 92px;
  display: flex;
  flex-direction: column;
  justify-content: center;

  @include breakpoint-max(sm) {
    margin-top: 0.85rem;
  }
}
