@import '../../utils/_colors.scss';

$circle-length: 151px;
$check-length: 36px;

.completedCheck {
  margin-top: 4px;
  margin-left: -10px;
}

@keyframes scaleAnimation {
  0% {
    opacity: 0;
    transform: scale(1.1);
  }
  100% {
    opacity: 1;
    transform: scale(0.8);
  }
}

@keyframes drawCircle {
  0% {
    stroke-dashoffset: $circle-length;
  }
  100% {
    stroke-dashoffset: 0;
  }
}

@keyframes drawCheck {
  0% {
    stroke-dashoffset: $circle-length;
  }
  100% {
    stroke-dashoffset: $check-length;
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fadeColor {
  0% {
    stroke: $white;
  }
  100% {
    stroke: $grey-dark;
  }
}

#successAnimationCircle {
  stroke-dasharray: $circle-length $circle-length;
  stroke: $grey-dark;
}

#successAnimationCheck {
  stroke-dasharray: $check-length $check-length;
  stroke: $white;
}

#successAnimationResult {
  fill: $grey-dark;
  opacity: 0;
}

#successAnimation.animated {
  animation: scaleAnimation 1s ease-out 0s 1 both;

  #successAnimationCircle {
    animation: drawCircle 1s cubic-bezier(0.77, 0, 0.175, 1) 0s 1 both, fadeOut 0.3s linear 0.9s 1 both;
  }

  #successAnimationCheck {
    animation: drawCheck 1s cubic-bezier(0.77, 0, 0.175, 1) 0s 1 both, fadeColor 0.3s linear 0.9s 1 both;
  }

  #successAnimationResult {
    animation: fadeIn 0.3s linear 0.9s both;
  }
}
