@import '../../../style/utils/colors';
@import '../../../style/utils/breakpoints';

.containerWrapper {
  width: 100%;
  height: 100%;
  background-color: $white;
  border-radius: 5px;
  border: 1px solid $grey-lightest;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 1em 2em 0;
  position: relative;

  @include breakpoint-max(xs) {
    padding: 0.5rem 1rem;
  }

  .loadingWrapper {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    margin-top: 3vh;
    @include breakpoint-max(xs) {
      margin-top: 2vh;
      align-items: flex-start;
    }
    .alignLeft,
    .alignRight {
      width: 55%;
      height: 120px;
      margin-top: 2vh;

      @include breakpoint-max(xs) {
        width: 85%;
        height: 120px;
        margin-top: unset;
        margin-bottom: 5vh;
      }
    }

    .alignRight {
      align-self: flex-end;
    }
  }

  .navBar {
    width: 100%;
    position: relative;
    background-color: transparent;
    padding: 0 0 0.5em 0;
    &:after {
      content: '';
      height: 1px;
      bottom: 0;
      left: 0;
      right: 0;
      position: absolute;
      background-color: $mint;
    }

    .title {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: 1rem;

      button {
        display: none;
        @include breakpoint-max(xs) {
          display: block;
          padding: 0.5rem 0.85rem;
          border-radius: 100%;
          border: 1px solid $grey-dark;

          &:active {
            background-color: $mint;

            svg {
              fill: $mint;
            }
          }
        }
      }
      p {
        font-size: 1em;
        font-weight: 500;
      }
    }
  }

  .messagesContainer {
    padding: 1em 0;
    height: 100%;
    width: 100%;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    gap: 2vh;

    .loading-more-messages {
      margin: 0 auto;
      text-align: center;
      margin-bottom: 1em;
    }

    .typing {
      text-align: right;
      margin-right: 2%;
    }
  }

  .content {
    padding: 0.5em 1em;
  }
}
