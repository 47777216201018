@import '../utils/breakpoints';
@import '../utils/elevation';
@import '../utils/colors';

.container-mobile {
  display: none;
  @include breakpoint-max(sm) {
    display: block;
  }
}

.pageContainer {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  background-color: $white;

  @include breakpoint-max(xs) {
    .tabContainer {
      order: 1;
      border-bottom: 6px solid $smooth-peach;
    }
    .courseHeader {
      order: 3;
      margin-top: 0;
    }
    .chatNav {
      order: 2;
      padding: 0 !important;
      display: flex;
      align-items: center;
      button {
        padding: 0 !important;
      }
    }
    .studyAreaHeaderContainer,
    .unitsSectionWrapper {
      order: 4;
    }

    .studyAreaHeaderContainer {
      background-color: $smooth-peach;
    }
  }
}

.progressBarWrapper {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  margin-top: 0px;
  width: 100% !important;
  @include breakpoint-max(xs) {
    margin-top: 0px;
    margin-bottom: 0px;
    width: 100% !important;
  }
}

.complete {
  font-size: 16px;
}

.dateDetails {
  font-size: 14px;
  color: $light-gray;
  text-align: left;
}

.progressAndUpdate {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  @include breakpoint-max(xs) {
    width: 100%;
    flex-direction: row;
    align-items: flex-end;
    margin-top: 5px;
    margin-bottom: 5px;
  }
}

.nextClassJoin {
  background-color: $mint;
  border: 0px !important;
  width: 100%;
}

.progressMobile {
  display: none;
  @include breakpoint-max(xs) {
    display: unset;
  }
}

.lastUpdate {
  font-size: 12px !important;
  color: $grey-light-dark;
  margin-left: 10px;
  margin-bottom: 6px !important;
  margin-top: 2px;

  @include breakpoint-max(xs) {
    margin-bottom: 2px !important;
    margin-left: 5px !important;
  }
}

.progressBar {
  flex-grow: 1;
  height: 16px;
  margin-top: 3px;
  width: 30%;
  border: 1px solid $grey-light-dark;
  margin-right: 13px;
  margin-bottom: 0px;
  border-radius: 10px;
}

.progressBar div {
  color: $grey-darkest;
  background-color: $mint;
}

.tabContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  margin-top: 1vw;

  @include breakpoint-max(xs) {
    padding-bottom: 2vh;
    margin-top: 7vw;
  }
}

.courseHeader {
  width: 100%;
  margin-top: 1.5vw;
  display: flex;
  flex-direction: row;
  @include breakpoint-max(xs) {
    flex-direction: column;
  }
}

.courseHeaderLeft {
  padding: 32px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  background-color: $smooth-peach;
  flex-grow: 2;
  @include breakpoint-max(xs) {
    padding: 35px 0;
  }
}

.courseHeaderRight {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.courseHeaderBlock {
  display: flex;
  flex-direction: row;
  padding-left: 20px;
  padding-right: 14%;
  justify-content: flex-start;
  align-items: center;
  background-color: $soft-yellow;
  margin-left: 2px;
  width: 100%;
  flex-grow: 1;
  @include breakpoint-max(xs) {
    margin: 0;
  }
}

.courseHeaderBlockCentral {
  margin-top: 2px;
  margin-bottom: 2px;
}

.courseHeaderBlockNumber {
  font-size: 70px;
  margin-right: 11px;
  width: 80px;
}

.unitsSectionWrapper {
  display: flex;
  flex-direction: row;
  width: 75%;
  margin-top: 8vh;
  padding-bottom: 100px;
  @include breakpoint-max(xs) {
    width: 100%;
    padding: 5vh 5%;
    margin: 0;
    background-color: #eeeeee;
  }
}

.unitDetailWrapper {
  display: flex;
  flex-direction: column;
  width: 66%;
  @include breakpoint-max(xs) {
    width: 100%;
  }
}

.loading {
  height: 80vh;
  margin: auto;
}

.headerWrapper {
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  @include breakpoint-max(xs) {
    align-items: start;
    margin: unset;
    width: 100%;
  }
}

.headerWrapper h1 {
  width: fit-content;
  margin-right: 16px;

  @include breakpoint-max(xs) {
    font-size: 22px;
    text-align: left;
  }
}

.studyNow,
.headerWrapper button  {
  padding: 5px 10px;
  font-size: 13px;
  margin-bottom: -3px;
  height: fit-content;
  @include breakpoint-max(xs) {
    margin: 0;
    margin-top: 10px;
    margin-bottom: 17px;
  }
}

.yourProgress {
  font-size: 14px !important;
  margin-top: 10px;
  margin-bottom: 0px;
  @include breakpoint-max(xs) {
    display: none;
  }
}

.headerSmallPrint {
  font-size: 14px !important;
  text-align: center;
  color: $grey-dark;
}

.studyAreaHeaderContent {
  text-align: center;

  h1 {
    text-decoration: underline;
    width: 100%;
    text-align: left;
    font-size: 1.5em;

    @include breakpoint-max(xs) {
      text-align: left;
    }
  }

  @include breakpoint-max(xs) {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 0.4em;
    text-align: left;
  }
}

.nextLessonContent {
  .studyAreaTextContent {
    @extend .studyAreaHeaderContent;
    @include breakpoint-max(xs) {
      margin-top: 0;
      justify-content: center;
      h1 {
        font-size: 1.7em;
      }
    }
  }

  @include breakpoint-max(xs) {
    display: flex;
    justify-content: space-between;
    width: 100%;

    .circularImg {
      width: 70px;
      height: 70px;
      clip-path: circle(at center);
    }
  }
}

.studyAreaHeaderActive .studyAreaNextClassWrapper {
  justify-content: center;
}

.studyAreaNextClassWrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.studyAreaNextClassWrapper img {
  width: 75px;
  height: 75px;
  clip-path: circle(at center);
  object-fit: cover;
}

.studyAreaNextClassContent  {
  display: flex;
  flex-direction: column;
}

.studyAreaHeaderContainer {
  display: flex;
  height: 175px;
  flex-flow: wrap;
  height: fit-content;

  @media (max-width: 1087px) {
    justify-content: center;
  }

  @include breakpoint-max(xs) {
    background-color: #eeeeee;
  }
}

.studyAreaRatingBox {
  background-color: #fff;
  margin-right: 21px;
  padding: 10px 24px;
  height: 96px;
  width: 315px;
  border-left: 6px solid $mint !important;

  p {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    height: 100%;
  }
}

.studyAreaRatingBox:hover {
  opacity: 0.9;
}

.studyAreaRatedBox {
  background-color: $white;
  margin-right: 21px;
  padding: 10px 24px;
  height: 96px;
  width: 315px;
}

.studyAreaRatedBox p {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  height: 100%;
}

.underlinedText {
  text-decoration: underline;
  margin-left: 4px;
}

.studyAreaHeaderPlaceholder {
  min-width: 1px;
  min-height: 100px;
  @include breakpoint-max(xs) {
    height: 160px !important;
  }
}

.noPointer {
  cursor: unset !important;
}

.studyAreaHeaderCallToActions {
  background-color: $white;
  margin-right: 21px;
  padding: 10px 24px;
  width: 315px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  cursor: pointer;
  padding-top: 47px;

  @include breakpoint-max(xs) {
    padding-top: unset;
    padding: 15px;
    height: 180px !important;
    margin-right: unset;
    padding-bottom: 5px;
    width: 88%;

    &#sylabus,
    &#languageHelper {
      height: 10vh !important;
    }
  }
}

.studyAreaHeaderCallToActions h1 {
  @include breakpoint-max(xs) {
    font-size: 24px;
    margin: unset;
  }
}

.studyAreaHeaderCallToActions p {
  margin-bottom: unset;
  @include breakpoint-max(xs) {
    font-size: 16px;
    margin: unset;
  }
}

/* .callToActionImg {
  height: 78px;
  margin-bottom: 20px;
} */

.studyAreaTitleContainer {
  display: flex;
  align-items: center;
  min-height: 108px;
  min-width: 1px;

  @include breakpoint-max(xs) {
    width: 100%;
    text-align: center;
    min-height: 0;
    h1 {
      margin: 0;
      text-align: center;
    }
  }

  @media (max-width: 1087px) {
    justify-content: center;
  }
}

.closeButton {
  position: absolute;
  right: 5px;
  top: 5px;
  z-index: 900;
  font-size: 1.2em;

  &:hover {
    cursor: pointer;
  }
}

.container {
  padding: 32px;
  position: relative;
}

.stepContainer {
  padding: 15px;
  width: 80%;
}

.stepContainer h1 {
  font-size: 1.2;
  text-align: left;
}

.modal {
  @include elevation(24);

  border-radius: 4px;
  background-color: white;
  max-width: 796px;
  position: absolute;
  left: calc(40% - 257px);
  top: 60px;
  height: fit-content;

  &:focus {
    outline: 0;
  }

  @include breakpoint-max(md) {
    width: 70%;
  }
}

.stepModal {
  @include elevation(24);

  z-index: 925;
  border-radius: 4px;
  background-color: white;
  max-width: 530px;
  position: absolute;
  top: 60px;
  height: fit-content;

  &:focus {
    outline: 0;
  }

  @include breakpoint-max(md) {
    width: 70%;
  }
}

.pointerModal {
  display: none;
  position: absolute;
  right: 50%;
  bottom: 0;
  transition: bottom 2s;
  -moz-transition: bottom 2s;
  -ms-transition: bottom 2s;
  -o-transition: bottom 2s;
  -webkit-transition: bottom 2s;
}

.leftPointerModal {
  transition: left ease 5s;
  display: none;
  position: absolute;
  top: 30px;
  transform: rotate(90deg);
  left: -20px;
  transition: left 2s;
  -moz-transition: left 2s;
  -ms-transition: left 2s;
  -o-transition: left 2s;
  -webkit-transition: left 2s;
}

.studyAreaHeaderActive {
  border: solid 2px #69e6bb;

  p {
    color: $grey-dark !important;
  }
}

.progressBarDivider {
  position: absolute;
  width: 1px;
  background-color: $grey-light-dark;
  left: calc(70% - 39px);
  z-index: 5;
  height: 16px;
  margin-top: 3px;
}

.progressBarWrapper .completedCopy {
  margin-left: 15px;
}

#nextStep {
  height: 200px;
  margin-bottom: 20px;
}

.studyAreaHeaderActive #nextStepTitleContainer {
  background-color: $mint;
}

#nextStepTitleContainer {
  background-color: $grey-dark;
  color: $white;
  font-weight: 500;
  text-align: left;
}

#nextStep h1 {
  text-align: left !important;
}

#nextStepStatus {
  text-align: left;
  margin-top: 15px;
  margin-bottom: 16px;
}

#youStudying {
  border-left: 6px solid $mint;
  height: 200px;
  margin-bottom: 20px;
}

#nextSession {
  height: 200px;
  margin-bottom: 20px;
}

.studyAreaHeaderActive #nextSessionTitleContainer {
  background-color: $mint;
}

#nextSessionTitleContainer {
  background-color: $grey-dark;
  color: #fff;
  font-weight: 500;
  text-align: left;
}

#languageHelper {
  border-left: 6px solid $grey-dark;
  padding-top: 10px;
  margin-bottom: 10px;

  @include breakpoint-max(xs) {
    margin-bottom: 20px;
  }
}

#sylabus {
  border-left: 6px solid $grey-dark;
  padding-top: 10px;
  margin-bottom: 10px;
}

.titleContainer {
  position: absolute;
  text-align: center;
  top: 0;
  left: 0;
  width: 100%;
  padding: 10px;
  font-size: 1.2em;
}

.msgAndLhContainer {
  display: flex;
  flex-direction: column;

  @media (max-width: 1087px) {
    flex-direction: row;
    height: 85px;
  }
  @media (max-width: 700px) {
    flex-direction: column;
    height: 175px;
  }

  @include breakpoint-max(xs) {
    width: 88%;

    .studyAreaHeaderCallToActions {
      width: 100%;
    }
  }
}

.infoIcon {
  color: #dad6d6;
  position: absolute;
  bottom: 5px;
  top: unset;
  right: 5px;
  font-size: 18px;
}

.infoIcon .tooltipText {
  border: 1px solid #333333;
  visibility: hidden;
  width: 205px;
  background-color: white;
  padding: 10px;
  position: absolute;
  z-index: 1;
  top: 24px;
  left: 20%;
  cursor: default;
}

.infoIcon .tooltipText p {
  font-size: 1em;
  color: #777777;
}

.infoIcon:hover .tooltipText {
  visibility: visible;
}

.toolLink {
  text-decoration: underline;
  color: #777777;
  cursor: pointer;
}

.toolLink:hover {
  text-decoration: underline;
  color: #777777;
}

/* mobile nav */
.navButton {
  background-color: unset !important;
  color: black !important;
  font-size: 1em;
  padding: unset !important;
  padding-top: 1em;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  margin: 0 3%;
}

.selectedNavButton {
  border-bottom: #e7ce9b 6px solid !important;
  border-radius: unset !important;
}

.chatNav {
  background-color: white;
  padding-bottom: 5px;
  display: flex;
  flex-direction: row;
  height: 50px;
  width: 100%;
  margin-bottom: 12px;

  @include breakpoint-max(xs) {
    width: 88%;
    margin: 0 auto;
    justify-content: space-between;
    display: flex;
    align-items: flex-start;
  }
}

.courseDropDown {
  min-width: 160px;
  border: unset !important;
  background-color: unset !important;
  padding: unset !important;
  box-shadow: none !important;

  .selectedOption {
    margin: 0;
    &:after {
      content: ' ';
      position: absolute;
      width: 7px;
      height: 7px;
      border-top: 1px solid $black;
      border-right: 1px solid $black;
      transform: rotate(135deg) translate(25%, -100%);
      margin-left: 10px;
    }
  }

  p {
    margin-right: 10px;
    font-size: 1.2em;
    text-transform: uppercase;
  }

  span {
    display: none !important;
  }
}

.downArrow {
  transform: rotate(-270deg);
  position: absolute;
  right: 35px;
  font-size: 1.1em;
}

.loadingWrapper {
  width: 6vw;
  height: 6vw;
  margin: 10vh auto;
}
