@import '../../../../style/utils/colors';
@import '../../../../style/utils/breakpoints';

.modalWrapper {
    max-width: 50vw;
    padding: 2rem !important;
    .modalSection {
        display: flex;
        flex-direction: column;

        h1 {
            font-size: 1.5rem;
        }

        p {
            font-size: 1rem;
        }

        p:last-of-type {
            margin-top: 1rem;
        }

        .buttonsWrapper {
            display: flex;
            justify-content: center;
            width: 100%;
            gap: 2rem;
            margin-top: 2rem;
            
            button {
                max-width: 200px;
            }
        }
    }
}