@import '../../style/utils/breakpoints';

.content-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 5em 0;

  .title {
    margin: 0 0 0.4em 0;
    text-align: center;

    @include breakpoint-max(sm) {
      margin: 0 0 0.5em 0;
    }
  }

  .subtitle {
    font-size: 1em;
    margin-bottom: 3em;
    text-align: center;

    @include breakpoint-max(sm) {
      margin: 0 0 1.5em 0;
    }
  }
}
