@import './colors';
@import './breakpoints';

.form {
  display: flex;
  flex-direction: column;
  gap: 2vh;

  h2 {
    font-weight: 700;
  }

  .loading {
    width: 30px;
    height: 30px;
  }

  & > button {
    margin-top: 1rem;
  }

  .row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 3vw;

    &:not(:last-of-type) {
      margin-bottom: 2vh;

    }

    @include breakpoint-max(sm) {
      flex-direction: column;
    }
  }
}

.disabledMessage {
  text-align: left;
  margin-top: 5px;
}

.noCreditQuote {
  color: $grey-dark;
}

.btnTopUp {
  background-color: $soft-red;
  color: $white;
  border-color: transparent;
  margin-top: 10px;
  margin-bottom: 17px;
  padding: 0;
}

.btnTopUp:hover {
  color: $white;
  background-color: $red;
}

.btnLink {
  padding: 10px 0 !important;
  color: $white;
  text-decoration: underline;
  font-size: 16px;
}

.btnLink:hover {
  color: $white;
  text-decoration: underline;
}

.btnLink:focus {
  color: $white;
  text-decoration: underline;
}

.field-wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  position: relative;

  &.invalidField {
    input {
      border: 1px solid $red;

      &:focus {
        border: 1.4px solid $red;
        box-shadow: rgba(102, 175, 233, 0.3) 0px 1px 2px 0px, rgba(102, 175, 233, 0.15) 0px 2px 6px 2px;
      }
    }
  }

  &__with-icon {
    width: 100%;
    position: relative;
    input {
      width: 100%;
      height: 2.5rem;
      outline: none;
      border: 1px solid $grey-light-button;
      border-radius: 0.3em;
      padding: 0.5em 0.75em;
      box-sizing: border-box;
      color: $grey-dark;
      background-color: $white;

      &:focus {
        border: 1.4px solid $gray85;
        box-shadow: rgba(102, 175, 233, 0.3) 0px 1px 2px 0px, rgba(102, 175, 233, 0.15) 0px 2px 6px 2px;
      }

      &:disabled {
        background-color: $grey-light-medium-dark;
        cursor: default;
      }
    }

    .icon {
      position: absolute;
      top: 0;
      right: 3%;
      width: 20px;
      height: 20px;
      transform: translateY(50%);
      button {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0 !important;
        padding: 0 !important;

        &:hover {
          img {
            opacity: 0.5;
          }
        }

        img {
          width: 100%;
          height: 100%;
        }
      }

      &.disabled button {
        cursor: default;
        img {
          opacity: 0.4;
          filter: alpha(opacity=40); /* msie */
        }
        &:hover {
          img {
            opacity: 0.4;
            filter: alpha(opacity=40); /* msie */
          }
        }
      }
    }
  }

  .invalidMessage {
    color: $red;
    font-size: 0.85em;
    margin-top: 0.2em;
    margin-bottom: 0;
    text-align: left;
  }

}

.label {
  font-size: 1rem;
  font-weight: 500;
  margin-bottom: 0.4rem;
  color: $grey-dark;

  @include breakpoint-max(sm) {
    margin-bottom: 0.4em;
  }
}