@import '../../utils/breakpoints';
@import '../../utils/colors';

$size: 32px;

.container {
  background-color: $mint;
  border-radius: 100%;
  color: $grey-dark;
  display: inline-block;
  font-size: 0.75 * $size;
  height: $size;
  line-height: $size;
  margin-right: 0.33 * $size;
  text-align: center;
  width: $size;
}

.number {
  display: block;
  margin: -1px 0 0 1px;
}

.offsetLeft {
  display: inline-block;

  @include breakpoint(md) {
    display: block;
    left: -1 * $size;
    margin-top: -0.25 * $size;
    position: absolute;
  }
}
