@import '../../../../style/utils/breakpoints';
@import '../../../../style/utils/colors';

.listWrapper {
  display: flex;
  flex-direction: column;
  @include breakpoint-max(sm) {
    width: 100%;
    padding: 2vh 10vw;
    background-color: $background-light-grey;
  }
}
