@import '../../../../style/utils/colors';

$p--line-height: 1.4em;

.description-wrapper {
  position: relative;

  &.full-height {
    height: unset;
  }

  .description {
    font-size: 1em;
    line-height: $p--line-height;

    .read-more {
      color: $link-default;
      background-color: $white;
      margin-left: 0.3em;

      &:hover {
        color: $link-hover;
        text-decoration: underline;
      }
    }
  }


}
