@import "../../../../style/utils/colors";

.button {
    width: 2rem;
    height: 2rem;
    box-sizing: border-box;
    border-radius: 100px;
    font-size: 0.8rem;
    &:hover {
        background-color: $grey-dark;
        color: $white;
        font-weight: 700;
    }
}