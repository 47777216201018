@import '../../../../style/utils/colors';
@import '../../../../style/utils/breakpoints';

.videoClassModalWrapper {
    font-size: 16px;
    background-color: $white;

    @include breakpoint-max(sm) {
        font-size:12px;
    }

    .modalTitle {
        position: relative;

        h1 {
            font-size: 1.5em;
            margin: 15px 0;
        }
    }

    .modalContent {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        gap: 1em;
        h2 {
            font-size: 1.2em;
        }
    }
}