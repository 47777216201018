@import '../../../../style/utils/colors';
@import '../../../../style/utils/breakpoints';

.successWrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10000;
  -webkit-animation: slide-bottom 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  animation: slide-bottom 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;

  .messageWrapper {
    max-width: 30vw;
    background-color: $light-text-highlight;
    padding: 0.8em 1.2em !important;
    border-radius: 4px;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    display: flex;
    justify-content: space-between;
    gap: 1vw;
    align-items: center;

    @include breakpoint-max(sm) {
      max-width: 80vw;
    }

    p {
      color: $dark-green;
      font-size: 0.9em;
    }

    button {
      margin: 0;
      color: $dark-green;
      border-radius: 50px;
      padding: 2px 10px;

      &:hover {
        color: white;
        background-color: $dark-green;
      }
    }
  }
}

@-webkit-keyframes slide-bottom {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  100% {
    -webkit-transform: translateY(4vh);
    transform: translateY(4vh);
  }
}

@keyframes slide-bottom {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  100% {
    -webkit-transform: translateY(4vh);
    transform: translateY(4vh);
  }
}
