@import '../../style/utils/colors';
@import '../../style/utils/breakpoints';

.headerContent {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.tooltipWrapper {
  position: relative;
  display: inline-block;

  &:hover .tooltip {
    opacity: 1;
    visibility: visible;
  }
}

.infoIcon {
  font-size: 0.85rem;
  font-weight: bold;
  background-color: $grey-light;
  border-radius: 50%;
  padding: 0.25rem;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 1rem;
}

.tooltip {
  position: absolute;
  top: calc(100% + 0.5rem); /* Position tooltip below the icon */
  left: 50%;
  transform: translateX(-50%);
  background-color: $grey-dark;
  color: $white;
  padding: 0.5rem;
  border-radius: 4px;
  font-size: 0.85rem;
  white-space: normal; /* Allow text to wrap */
  min-width: 300px; /* Set a maximum width */
  max-width: 500px;
  word-wrap: break-word; /* Break long words if necessary */
  z-index: 10;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.2s ease, visibility 0.2s ease;

  &:before {
    content: '';
    position: absolute;
    bottom: 100%; /* Place the triangle at the bottom of the tooltip */
    left: 50%;
    transform: translateX(-50%);
    border-width: 5px;
    border-style: solid;
    border-color: transparent transparent $grey-dark transparent; /* Triangle points up */
  }
}
