@import '../../utils/spacing';

.dash {
  @include margin-horizontal(4px);
}

.field {
  flex-grow: 2;
  margin-bottom: 8px;
  min-width: 0;
  padding: 0.4rem 0.8rem;
  font-size: 1rem;
}

.fields {
  align-items: center;
  display: flex !important;
  width: 100%;
}
