@import '../utils/elevation';
@import '../utils/spacing';
@import '../utils/breakpoints';
@import '../utils/colors';

.buyMore {
  font-size: 15px;
  text-align: center;
}

.container {
  margin: auto;
  width: 90%;
}

.expiredOrderAlert {
  @include margin-horizontal(auto);
  max-width: 480px;
}

.header {
  @include margin-vertical(60px);
}

.divider {
  height: 1px;
  background-color: $grey-light;
}

.openAccordionButton {
  background-color: transparent;
  border: transparent;
  text-decoration: underline;
}

.openAccordionButton span {
 font-size: 0.7em;
}

.modal {
  @include elevation(24);
  @include margin-horizontal(auto);

  background-color: $white;
  margin-top: 32px;
  max-width: 512px;
  outline: none;
  padding: 32px;
}

.sectionContainer {
  margin-top: 40px;
}

.sectionHeading, .sectionSubHeading {
  text-align: center;
}

.sectionSubHeading {
  margin-top: 20px;
}

.languageFilterContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.boldText p {
  font-weight: bold;
}

.boldText, .creditSummary, .topUpContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

.sectionInnerContainer {
  display: flex;
  justify-content: center;
  flex-direction: row;
  margin: auto;
  margin-top: 20px;
}

.apptTypesCardContainer {
  max-width: 80%;
  justify-content: flex-start;

  @include breakpoint-max(md) {
    flex-direction: column;
  }
}

.apptTypesCardContainer::-webkit-scrollbar {
    width: 0px;
    background: transparent; /* make scrollbar transparent */
}

.foundProductType {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
}

.topUpBtn {
  background-color: $grey-medium-dark;
  border-radius: 20px;
  padding-left:16px;
  padding-right: 16px;
  padding-top: 4px;
  padding-bottom: 4px;
  margin-top: 10px !important;
  margin-bottom: 10px !important;
  color: $white;
  cursor: pointer;
}

.topUpBtn:hover {
  opacity: .5;
}

.topUpBtn a, .topUpBtn a:hover{
  color: $white;
}

.smallText {
  font-size: 13px;
  margin-top: 30px;
}

.creditSituationBox{
  margin: 0;
  margin-top: 20px;
  border-radius: 10px;
  background-color: $white;
  padding: 15px;

}

.filters {
  text-align: center;
}

.filterButton {
  box-shadow: 0 4px 6px $color-shadow;
  padding: 10px;
  transition: background-color 0.2s;
  margin-left: 5px;
  margin-right: 5px;
  margin-bottom: 10px;
}

.filterSelected, .filterSelected:focus {
  background-color: $grey-dark;
  color: $white;
}

.filterSelected:active, .filterSelected:active:focus {
  background-color: $grey-medium-dark;
}
