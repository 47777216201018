@import '../utils/colors';

.content {
  margin-top: 50px;
}

.header {
  position: relative;
  padding-top: 25px;
}

.header h1 {
  margin-bottom: 20px;
}

.lessonInfoContainr h2{
  text-align: center;
  margin-top: unset;
  font-weight: bold;
}

.lessonInfoContainr p{
  text-align: center;
}

.lessonInfoContainr {
  padding: 35px;
  background-color: $mint;
}

.goBackLink {
  color: $black;
  text-decoration: underline;
  position: absolute;
  top: 10px;
  font-size: 16px;
  left: 5vw;
}