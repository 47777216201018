.passworResetWrapper {
  height: calc(100vh - 60px);
  padding: 4vw 6vw 0;
  box-sizing: border-box;

  .passworResetContent {
    display: flex;
    flex-direction: column;
    gap: 4vh;
    margin-top: 2vh;

    p {
      font-size: 1.1em;
    }

    .submitButton {
      width: 20vw;
    }
  }
}