@import '../utils/colors';
@import '../utils/elevation';
@import '../utils/breakpoints';

.content {
  margin-top: 50px;
}

.header p{
  text-align: center;
}

.header h1 {
  font-size: 32px;
  margin-bottom: 1%;
}

.name {
  margin-top: 0;
  font-size: 20px;
  margin-bottom: 15px;
}

.noLessons, .noResources {
  @include elevation(1);

  background: $white;
  margin-bottom: 24px;
  padding: 32px;

  :last-child {
    margin-bottom: 0;
  }
}

.resourceCard {
  max-width: 320px;
  border-radius: 5px;
}

.lessonResourceCard {
  border-radius: 5px;
  max-width: 1035px;
}

.tutorHeader {
  background-color: $blue !important;
}

.progressBar {
  color: $grey-darkest;
  margin-bottom: unset;
}

.progressBar div {
  color: $grey-darkest;
  background-color: $soft-green;
}

.lessonResourceContainer {
  display: flex;
  justify-content: space-between;
  background-color: $white;
  border-radius: 5px;
  padding: 15px;
  width: 100%;
  margin: 0 auto 15px auto;
  color: $blue;
  align-items: center;
  border: 1px solid $grey-light-dark;

  @include breakpoint-max(xs) {
    background-color: white;
    min-height: 65px;
    padding: 5px;
  }
}

.lessonResourceContainer .btn{
  height: fit-content;
}

.lessonResourceLeftContent {
  display: flex;
  align-items: center;
  width: 100%;

  @include breakpoint-max(xs) {
    justify-content: flex-start;
  }
}

.lessonResourceLeftContent h2 {
  margin: unset;
  margin-left: 15px;
  font-size: 20px;

  @include breakpoint-max(xs) {
    font-size: 0.7em;
    margin-left: 8px;
    margin-right: 8px;
  }
}

.disabledText {
  color: $grey-light-medium-dark;
}

.duration {

}

.disabledContainer {
  border: 1px solid $grey-light-medium-dark;
}

.disabledIcon {
  opacity: .5;
}
.buttonContainer {
  width: fit-content;
  display: flex;
  justify-content: center;
  align-items: center;
}

.buttonContainer a {
  width: 60%;
}

.resourceBtns {
  margin: 10px 0 10px 40px;
  min-width: 135px;

  @include breakpoint-max(xs) {
    min-width: 75px;
    margin-left: unset;
  }
}
