@import '../../utils/colors';

$appt-type-width: 300px;

.container {
  display: flex;
  flex-direction: column;
  min-height: 150px;
  margin: 2%;
  width: 30%;
  min-width: $appt-type-width;
  background-color: $white;
  border: 1px solid $color-border;
  border-radius: 4px;
  -webkit-box-shadow: 0 4px 6px $color-border;
  box-shadow: 0 4px 6px $color-border;
}

.container:before,
.container:after {
   content: normal;
}

.leftContainer{
  display: flex;
  flex-direction: column;
  margin-top: 15px;
  margin-bottom: 15px;
}

.creditsLeftContainer {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: center;
  margin-top: 5px;
}

.name {
  font-size: 20px !important;
  text-align: left;
  text-transform: capitalize;
  box-shadow: none !important;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-left: 10px;
  border-left-width: 5px;
  border-left-style: solid;
  border-radius: 3px;
  padding-left: 5px;
}

.name > span {
  margin-left: 5px !important;
}

.name:active, .name:focus {
  background-color: transparent !important;
}

.dropdownMenu {
  left: auto;
}

.price {
  color: $grey-dark;
}

.image{
  width: 100%;
  height: 100%;
  object-fit: cover;
  max-height: 150px;
}

.imageContainer{
  padding-right: 0;
}

.tagContainer {
  font-size: .8em;
  display: flex;
  justify-content: center;
  min-height: 23px;
}

.tag {
  padding-left: 6px;
  padding-right: 6px;
  padding-top: 2px;
  padding-bottom: 2px;
  border-radius: 10px;
  background-color: $grey-light;
  margin: 2px;
  float: left;
}

.prices{
  background-color: $white;
  flex-direction: column;
  align-items: flex-start;
  height: 100%;
  position: relative;
}

.pricesText{
  color: $grey-dark;
}

.creditDetail {
  font-size: .8em;
  color: $grey-dark;
}

.btnContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 10px;
  position: absolute;
  bottom: 0;
}

.btnContainer button {
  margin-left: 10px;
  margin-right: 10px;
}

.customBtn {
  width: 60%;
  margin: auto;
  white-space: normal;
}

.btnTopUp {
  background-color: transparent;
  border-color: transparent;
  text-decoration: underline;
  margin-bottom: 3px;
  padding: 0;
  font-size: 11px !important;
  margin-left: 5px;
  padding: 0 !important;
  box-shadow: none !important;
  border: none !important;
}

.btnTopUpRed {
  color: $red;
}

.btnTopUp:hover, .btnTopUp:active, .btnTopUp:focus {
  opacity: .5;
  background-color: transparent;
}

.btnTopUp:visited {
  background-color: transparent;
}

.bookNowContainer {
  display: flex;
  justify-content: flex-start;
  margin:10px;
}

.btnBookNow {
  font-size: 1.2em !important;
}

.bookNowIcon {
  font-size: 0.7em;
}

.addIconDiv, .groupIconDiv, .trainingTypeIconDiv {
  display: inline;
}

.addIcon, .groupIcon {
  font-size: 14px;
  margin-right: 5px;
  margin-bottom: -3px;
}

.trainingTypeIcon {
  font-size: 24px;
  margin-right: 14px;
  margin-bottom: -7px;
}

.description {
  text-align: center;
  margin: 0;
}

.descriptionRed {
  text-align: center;
  margin: 0;
  color: $red;
}

a:hover {
  text-decoration: none;
}

.priceContainer {
  width: 100%;
}

.priceRow {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: flex-start;
}

.amountCol {
  flex-grow: 1;
  margin-top: 11px;
}

.priceCol {
  margin-top: 11px;
}

.discountText {
  font-size: 0.8em;
  text-align: right;
  margin-top: -15px;
  color: $grey-light-dark;
  min-height: 10px;
  margin-bottom: 0;
}

.priceFormGroup {
  width: 100%;
  padding: 10px;
  padding-bottom: 0px;
  flex-grow: 1;
  margin-bottom: 70px;
}
