@import '../../style/utils/breakpoints';
@import '../../style/utils/timing';
@import '../../style/utils/colors';

.new-onboarding-wrapper {
  width: 100%;
  min-height: 100vh;
  padding: 12vh 6vw 0;
  height: 100vh;
  overflow: hidden;
  animation: makeScrollable 1s ease-in $end-of-loading forwards;

  @include breakpoint-max(sm) {
    padding: 16vh 8vw 0;
  }

  .new-onboarding-content {
    margin-top: 7vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    z-index: 3;

    @include breakpoint-max(sm) {
      margin-top: 0;
    }

    .steps-wrapper {
      width: 70%;
      @include breakpoint-max(sm) {
        width: 80%;
      }
    }

    .content-wrapper {
      width: 100%;
    }
  }
}

.loadingContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 70vh;
  .wrapper {
    width: 60px;
    height: 60px;
  }
}

@keyframes makeScrollable {
  from {
    overflow: hidden;
    height: 100vh;
  }
  to {
    overflow: auto;
    height: auto;
  }
}

.languageSelectorWrapper {
  position: absolute;
  top: 0;
  right: 0;
}

.dropdown {
  border-radius: 5px !important;
  box-shadow: none !important;
  position: relative;
  font-size: 14px;
  letter-spacing: .01rem;
  z-index: 0;
  margin-top: 0 !important;
  margin-bottom: 0 !important;
  padding-left: 48px;
  margin-left: 10px;
  background: #FFFFFF;
  border: 1px solid #CACACA;
  line-height: 25px;
  height: 35px;
  width: 150px;
  outline: none;
  padding: 0.5em 0.75em !important;
  box-sizing: border-box;
  color: rgb(51, 51, 51);
  background-color: rgb(255, 255, 255) !important;
  text-align: left;
}

.dropdownSelected {
  cursor: pointer;
}

.dropdownSelected::after {
  right: 0 !important;
}

.dropdownOptions {
  display: flex;
  flex-direction: column;
  border-radius: 5px !important;
  box-shadow: none !important;
  position: relative;
  font-size: 14px;
  letter-spacing: .01rem;
  z-index: 0;
  margin-top: 0 !important;
  margin-bottom: 0 !important;
  padding-left: 48px;
  margin-left: 0;
  background: #FFFFFF;
  border: 1px solid #CACACA !important;
  line-height: 25px;
  width: 150px;
  outline: none;
  padding: 0 !important;
  box-sizing: border-box;
  color: rgb(51, 51, 51);
  background-color: rgb(255, 255, 255) !important;
  text-align: left;
  left: -1px !important;
  top: 33px !important;
}

.dropdownOptions button, .dropdownOptions p {
  width: 100%;
  margin: 0 !important;
  max-width: unset !important;
}
.dropdownOptions button:hover {
  background-color: $grey-light-button !important;
}

.dropdownOptions p {
  text-align: left;
  padding: 0.5em 0.75em !important;
  
}