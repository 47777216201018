@import '../utils/colors';

.resourceWrapper {
  margin-top: 5px;
  width: 100%;
  display: flex;
  flex-direction: row;
  padding: 5px;
  align-items: center;
}

.spinnerWrapper {
  width :20px;
  height: 20px;
  margin-left: 10px;
}

.resourceWrapper a {
  line-break:anywhere;
  width: 100%;
  flex-grow: 1;
}

.circle {
  width: 5px;
  min-width: 5px;
  height: 5px;
  border-radius: 100%;
  background-color: $grey-dark;
  margin-right: 5px;
}

.removeBtn {
  color: $grey-dark;
  min-width: 18px;
  min-height: 18px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  text-align: center;
  border: 1px solid $grey-dark;
  border-radius: 100%;
  padding: 0px !important;
  margin-left: 10px;
}

.removeBtn strong {
  font-size: 11px;
}

.removeBtn:hover {
  opacity: .6;
}