@import '../../utils/breakpoints';
@import '../../utils/colors';

.container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  padding: 5vh 0;
  background-color: $smooth-peach;
  color: $grey-dark;

  .content {
    width: 34%;
    min-width: 345px;
    @include breakpoint-max(sm) {
      width: 87%;
      min-width: unset;
    }
  }

  .bookingSubTitle {
    color: $grey-dark;
    font-weight: 600;
    margin-top: 0;
  }

  .apptName {
    color: $grey-dark;
    font-size: 22px;
    margin-bottom: 25px;
    display: none;
  }

  .bookingForm {
    width: 100%;

    .shortInput {
      width: 100%;

      & input {
        color: $grey;
        padding-left: 10px;
      }

      & input[type='text'] {
        border-radius: 5px;
        height: 34px;
        border: 1px solid $grey-light-button;
      }
    }
  }

  .selectDate {
    width: 100%;

    .calendarWrapper {
      width: 100%;
      margin-bottom: 1em;
    }
  }
}

.buttonContainer {
  margin-top: 2vw;
  text-align: center;
  display: inline-block;

  @include breakpoint-max(sm) {
    width: 100%;
    display: flex;
    justify-content: center;

    button {
      width: 80%;
    }
  }
}

.bookingSummaryTitle {
  font-size: 1.15em;
  color: $grey-dark;
  font-weight: 600;
}

.bookingLessonsInfo {
  color: $grey-dark;
}

.informBookingCopy {
  color: $grey-dark;
  margin-bottom: 10px;
  font-weight: lighter;
  margin-top: 15px;
}

.inlineRadioButtonItem {
  height: 24px;
  line-height: 24px;
  margin-left: 8px;
  vertical-align: middle;

  &:first-child {
    margin-left: 0;
  }

  &_disabled {
    color: $color-mid-darkener;
  }
}

.inlineRadioButtons {
  height: 24px;
}

.option {
  color: $grey-dark;
  font-weight: 100;
}

.popupCalendar {
  font-size: 0.9em;

  @include breakpoint-max(xs) {
    display: flex;
  }
}

.calendarLoading {
  pointer-events: none !important;
  cursor: not-allowed !important;
}

.skypeInfo {
  color: $grey-dark;
  font-size: 80%;
  margin-top: 4px;
}

label {
  margin-right: 5px;
  font-size: 16px;
}

.inviteesContainer  {
  display: flex;
  flex-direction: row;
}

.calendarWrapper {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}

.loader {
  background-size: 20px;
  /* margin-left: 5px; */
  margin-top: -48px;
  right: 45px;
  position: absolute;

  @include breakpoint-max(xs) {
    right: -10px;
  }

  @include breakpoint-max(se) {
    right: -36px;
  }
}

.shortInput[disabled] {
  background-color: $grey-light-medium-dark;
  color: $grey;
}

.shortInput label {
  font-size: 16px;
}

.detailsParagraph {
  color: $grey-dark;
}

.recommendationParagraph {
  color: $grey-dark;
  font-size: 14px;
  margin-bottom: 15px;
}

.recurringCheckboxes {
  margin-left: 20px;
}

.disabledInput {
  background-color: $grey-light-button !important;
  border-color: $grey-light-button;
  color: $grey-light-dark;
}

.recurrText {
  color: $grey-dark;
  font-size: 16px;
  margin-top: 10px;
}

.weeklyTitle {
  color: $grey-dark;
  font-size: 18px;
}

.weeklyStep {
  color: $grey-dark;
  margin-left: 10px;
  font-size: 14px;
}

.weeklyText {
  color: $grey-dark;
  font-size: 14px;
}

.checkbox input[type='checkbox'] {
  /* increase size of Checkboxes */
  // -ms-transform: scale(1.5); /* IE */
  // -moz-transform: scale(1.5); /* FF */
  // -webkit-transform: scale(1.5); /* Safari and Chrome */
  // -o-transform: scale(1.5); /* Opera */
  // transform: scale(1.5);
}

// .checkbox label {
//   margin-top: 5px;
//   margin-left: 10px;
//   margin-bottom: 15px;
// }

.shortInterval {
  margin-top: 5px;
  padding: 5px 0px;
}
.shortInterval p {
  color: $grey-dark !important;
}

.calendarInput {
  width: 230%;
  border-radius: 5px;

  @include breakpoint-max(xs) {
    width: 130%;
  }
}

.feedbackButtons {
  display: flex;
  align-items: center;
  justify-content: space-around;
  gap: 5%;
  margin-top: 20px;
}

.feedbackList {
  list-style: none;
  padding: unset;
  margin-bottom: 15px;
}

.feedbackList div {
  margin: unset;
}

.feedbackText {
  width: 45%;

  @include breakpoint-max(xs) {
    width: 100%;
  }
}

.consistencyLine {
  height: 1px;
  width: 100%;
  background-color: $grey-dark;
  margin-top: 15px;
  opacity: 0.8;
}

.datePickerPopper {
  z-index: 1000;

  // @include breakpoint-max(xs) {
  //   transform: translate(0, -100%) !important;
  // }
}

.bookingSummaryWrapper {
  transition: height 0.4s ease-out;
  overflow-y: hidden;
}

.loaderWrapper div {
  background-size: 25px;
  margin: 0;
}

.bookingSummary {
  width: 100%;
  font-size: 1.1em;
  @include breakpoint-max(sm) {
    font-size: 1em;
  }

  // & tr:first-of-type {
  //   th {
  //     p {
  //       margin: 0;
  //       text-align: center;
  //     }
  //   }
  // }

  // & tr {

  //   & td {
  //     line-height: 1.8em;
  //   }

  //   & td:first-of-type {
  //     text-align: center;
  //   }
  //   text-align: center;
  // }
}
