.wrapper {
  padding: 2vh 0;
  width: 100%;

  .quill {
    div:last-of-type {
      min-height: 30vh;
    }
  }
}
