@import '../../utils/colors';

.button,
.button-available {
  &:hover {
    opacity: 0.8;
  }
}

.button-available {
  button {
    color: $grey-dark;
    font-weight: 500;
    background-color: $dark-mint;
  }
}

.button {
  width: 80%;
  margin: 1em auto 0;
}

.buttonsContainer {
  border-top: 1px solid $mint;
  padding: 10px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  gap: 10px;
}

.buttonWidth {
  max-width: 100px;
  padding-left: 0;
  padding-right: 0;
  text-align: center;
  width: 100px;
}

.container {
  background: $soft-mint;
  border: 1px solid $mint;
  border-right-width: 0;
  color: $grey-dark;
  display: inline-block;
  width: 100%;
}

.container:first-child {
  border-bottom-left-radius: 4px;
  border-top-left-radius: 4px;
}

.container:last-child {
  border-bottom-right-radius: 4px;
  border-right: solid 1px $mint;
  border-top-right-radius: 4px;
}

.heading {
  margin: 10px 0;
  text-align: center;
}

@media (max-width: 880px) {
  .button {
    display: inline-block;
    margin: 5px;
  }

  .buttonsContainer {
    border-left: 1px solid $mint;
    border-top: 0;
    display: inline-block;
    max-width: 75%;
    text-align: left;
  }

  .container {
    border-width: 1px 1px 0;
    display: block;
  }

  .container:first-child {
    border-bottom-left-radius: 0;
    border-top-right-radius: 4px;
  }

  .container:last-child {
    border-bottom-left-radius: 4px;
    border-bottom-width: 1px;
    border-top-right-radius: 0;
  }

  .heading {
    display: inline-block;
    margin-top: 22px;
    min-width: 25%;
    vertical-align: top;
  }
}
