@import '../../../style/utils/colors';

.text--highlight {
    background-color: $text-highlight;
}

.text--italic {
font-style: italic;
}

.text--bold {
font-weight: bold;
}