@import '../utils/colors';

.container {
  padding: 5%;
  text-align: center;
}

.form {
  margin: 50px auto;
  width: 180px;

  & input {
    font-size: 24px;
    height: auto;
    line-height: 1;
    text-align: center;
  }
}

.betaWarning {
  background-color: $soft-yellow;
  display: inline-block;
  padding: 5px;
}
