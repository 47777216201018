@import '../../style/utils/colors';
@import '../../style/utils/breakpoints';

.container {
  margin: 2rem 6vw;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 0.5rem;
  overflow-x: hidden;
  position: relative;

  @include breakpoint-max(sm) {
    overflow: auto;
  }

  h1,
  h2 {
    text-align: left;
    margin: 0 0 0.5rem;
  }

  h1 {
    font-size: 1.5rem;
    font-weight: 700;

    span {
      position: relative;
      &:after {
        content: ' ';
        position: absolute;
        bottom: -5px;
        height: 5px;
        left: 0;
        right: 0;
        background-color: $mint;
      }
    }
  }

  h2 {
    margin-bottom: 2vh;
    font-size: 1.1rem;
    font-weight: 300;
  }
}

.table-container {
  width: 100%;
  min-width: 600px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
  border-radius: 8px;
  padding: 1.5rem 2rem;
  background-color: $white;
  overflow: auto;

  @include breakpoint-max(sm) {
    min-width: unset;
  }

  table {
    width: 100%;
    min-width: 600px;
    table-layout: fixed;
  }
}

.back {
  margin-bottom: 2vh;
}

.button-wrapper {
  display: flex;
  justify-content: flex-start;
  gap: 0.25rem;
  margin-top: 0.5rem;

  @include breakpoint-max(sm) {
    flex-direction: column;

    * {
      font-size: 1rem;
    }
  }

  * {
    font-size: 1rem;
  }
}
