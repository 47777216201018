@import '../utils/colors';
@import '../utils/breakpoints';

.messageList {
  background-color: $white;
  width: 100%;
  border-bottom: 1px solid $grey-lightest;
  flex-grow: 1;
  max-height: 50vh;
}

.messageListContainer {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  height: 100%;
  padding: 0;
  flex-grow: 1;
  border: 1px solid $grey-lightest;
  border-left: 0;
  width: 100%;
  min-height: 50vh;

  @include breakpoint-max(xs) {
    display: none;
    height: fit-content;
    position: absolute;
    left: 0;
    padding: 18px;
    background-color: $white;
  }
}

.testChatWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.testPageWrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 10%;
  padding-top: calc(10% - 60px);
  justify-content: center;
  align-items: center;
  margin: auto;
  text-align: center;
  height: calc(100vh - 60px);
  background-color: $background-light-grey;
  margin-bottom: 10px;

  p {
    max-width: 600px;
  }
}

.emailFormWrapper {
  width: 100%;
  max-width: 500px;
  margin-top: 50px;
}

.emailForm {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  flex-wrap: wrap;
  width: 100%;
}

.formElement {
  margin-left: 5px;
  margin-right: 5px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

.typeformContainer {
  width: 100%;
  height: 80vh;
}
.ceregoWrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.ceregoWrapper iframe {
  height: calc(50vh - 100px);
  width: 100%;
  max-width: 1000px;
}

.ceregoWrapper p {
  margin-top: 50px;
}

.techHelp {
  margin-top: 30px;
}

.typeformContainer iframe {
  height: 60vh;
  max-width: 1000px;
  padding-top: 10vh;
  padding-bottom: 10vh;
  width: 100%;
  border: none !important;
}

.youtubeParagraph {
  margin-top: 20px;
  margin-bottom: 20px;
}

.testEmailButtonContainer {
  margin-top: 0px !important;

  button {
    width: 150px !important;
  }
}

.buttonContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  width: 100%;
  flex-wrap: wrap;
  margin-top: 40px;
  max-width: 400px;
  height: 55px;

  button {
    width: 30%;
  }
}
.error {
  background-color: $salmon;
}
.emailSendingInfo {
  height: 80px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}
