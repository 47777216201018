@import '../../../../style/utils/colors';
@import '../../../../style/utils/breakpoints';

.cancelingModalWrapper {
  font-size: 16px;
  background-color: $white;
  min-width: 530px;
  width: 100%;

  @include breakpoint-max(sm) {
    font-size: 12px;
    min-width: unset;
    width: 85vw;
  }

  h1 {
    font-size: 1.5em;
    margin: 15px 0;

    @include breakpoint-max(sm) {
      margin: 15px 0 5px;
    }
  }

  .contentWrapper {
    .cancelingSummary {
      p {
        font-size: 1em;
        margin: 0;
        text-align: center;
      }

      .bookingSummaryList {
        margin-top: 4vh;

        li {
          border-bottom: 1px solid $gray85;

          &:last-of-type {
            border-bottom: none;
          }
        }

        .motivationTextarea {
          width: 100%;
          margin-top: 3vh;
          padding: 0.7em 1em;
          border: 1px solid $grey-button-disabled;
          border-radius: 5px;
          box-sizing: border-box;
        }
      }
    }

    .buttonsWrapper {
      display: flex;
      justify-content: space-around !important;
      width: 100%;
      height: 6vh;
      margin: 6vh auto 2vh;

      button {
        width: 40%;
      }

      @include breakpoint-max(sm) {
        height: 6vh;
      }
    }
  }
}

.lateCancelingWarning {
  margin-top: 1em;
  display: flex;
  align-items: center;
  gap: 2%;

  img {
    width: 30px;
  }

  p {
    text-align: left !important;
  }
}
