@import '../../../style/utils/breakpoints';

.container {
  width: 35vw;
  min-width: 380px;
  display: flex;
  flex-direction: column;
  align-items: center;

  @include breakpoint-max(sm) {
    min-width: 80vw;
  }

  .person-container {
    width: 45%;
    margin-bottom: 3vh !important;

    svg {
      width: 100%;
      height: 100%;
    }
  }

  h1 {
    font-size: 1.2rem;
    margin-bottom: 2vh !important;
  }

  p {
    width: 100%;
    text-align: left;
    font-size: 1rem;
    margin-bottom: 2vh;
  }

  .buttonWrapper {
    margin-top: 2vh !important;
  }
}
