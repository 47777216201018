@import '../../../style/utils/colors';

.field-wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  font-size: 14px;
  min-width: 200px;
  min-height: 50px;

  &.invalidField {
    input {
      border: 1px solid $red;

      &:focus {
        border: 1.4px solid $red;
        box-shadow: rgba(102, 175, 233, 0.3) 0px 1px 2px 0px, rgba(102, 175, 233, 0.15) 0px 2px 6px 2px;
      }
    }
  }

  input {
    width: 100%;
    height: 2.5em;
    outline: none;
    border: 1px solid $grey-light-button;
    border-radius: 0.3em;
    padding: 0.5em 0.75em;
    box-sizing: border-box;
    color: $grey-dark;
    background-color: $white !important;

    &:focus {
      border: 1.4px solid $gray85;
      box-shadow: rgba(102, 175, 233, 0.3) 0px 1px 2px 0px, rgba(102, 175, 233, 0.15) 0px 2px 6px 2px;
    }
  }

  .invalidMessage {
    color: $red;
    font-size: 0.85em;
    margin-top: 0.2em;
    margin-bottom: 0;
  }
}

.dropdown {
  border-radius: 5px !important;
  box-shadow: none !important;
  position: relative;
  font-size: 14px;
  letter-spacing: .01rem;
  z-index: 0;
  margin-top: 0 !important;
  margin-bottom: 0 !important;
  padding-left: 48px;
  margin-left: 0;
  background: #FFFFFF;
  border: 1px solid #CACACA;
  line-height: 25px;
  height: 35px;
  width: 300px;
  outline: none;
  padding: 0.5em 0.75em !important;
  box-sizing: border-box;
  color: rgb(51, 51, 51);
  background-color: rgb(255, 255, 255) !important;
  text-align: left;
}

.dropdownSelected {
  cursor: pointer;
}

.dropdownSelected::after {
  right: 0 !important;
}

.dropdownOptions {
  display: flex;
  flex-direction: column;
  border-radius: 5px !important;
  box-shadow: none !important;
  position: relative;
  font-size: 14px;
  letter-spacing: .01rem;
  z-index: 0;
  margin-top: 0 !important;
  margin-bottom: 0 !important;
  padding-left: 48px;
  margin-left: 0;
  background: #FFFFFF;
  border: 1px solid #CACACA !important;
  line-height: 25px;
  width: 300px;
  outline: none;
  padding: 0 !important;
  box-sizing: border-box;
  color: rgb(51, 51, 51);
  background-color: rgb(255, 255, 255) !important;
  text-align: left;
  left: -1px !important;
  top: 33px !important;
}

.dropdownOptions button, .dropdownOptions p {
  width: 100%;
  margin: 0 !important;
  max-width: unset !important;
}
.dropdownOptions button:hover {
  background-color: $grey-light-button !important;
}

.dropdownOptions p {
  text-align: left;
  padding: 0.5em 0.75em !important;
  
}
