@import '../../../style/utils/colors';
@import '../../../style/utils/breakpoints';

.container-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;

  @include breakpoint-max(sm) {
    width: 100%;
  }
}

.terms {
  font-size: 0.85em;
  text-align: center;
  margin-top: 2em;

  a {
    font-size: 1em;
    &:hover {
      text-decoration: underline;
      font-weight: 500;
    }
  }
}
