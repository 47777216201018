.iframe {
  width: 100%;
  height: 100%;
  flex-grow: 1;
}

.iframeHidden {
  display: none;
}

.iframeWrapper {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  min-height: 93vh;
}

.iframeLoader{
  width: 100%;
  height: 100%;
  flex-grow: 1;
  align-items: center;
  justify-content: center;
}
