@import '../utils/colors';

.heading {
  font-size: 20px;
  font-weight: bold;
  border-bottom: solid 1px;
  margin-bottom: 10px;
  margin-top: 20px;
}

.buttonContainer {
  margin-top: 20px;
  margin-bottom: 10px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 1em;

  button {
    width: 20vw;
    white-space: nowrap;
  }
}

.cancelConfirm {
  margin-top: 20px;
  border-top: solid 1px $red;
  padding-top: 15px;
  color: $red;
}

.studentNameLine {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

.studentNameLine b {
  margin-right: 5px;
}

.noShowCheckbox {
  margin-left: 10px;
  margin-bottom: 0px;
  margin-top: 0px;
  height: 22px;
}

.cancelButton, .noShowButton, .joinVideoButton {
  margin-left: 5px;
  margin-right: 5px;
}

.label {
  color: $black;
}

.textarea {
  color: $black;
  width: 95%;
  margin-top: 0.5rem;
}

.skypeModalContent {
  display: flex;
  flex-direction: column;
}

.copySkypeContainer {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  margin-top: 10px;
  margin-bottom: 5px;
}

.usernameText {
  margin-right: 25px;
}

.username {
  font-weight: bold;
}

.copied {
  color: $mint;
  height: 15px;
}
