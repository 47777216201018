@import '../../utils/colors';

.absencePeriod {
  background-image: repeating-linear-gradient(45deg, $red, $red 10px, $red-light 10px, $red-light 20px);
  border-color: $red !important;
  left: 0 !important;
  width: 100% !important;
}

.breakEvent,
.breakEvent:hover,
.breakEvent:active,
.breakEvent:visited {
  background-color: $gainsboro !important;
  border-color: $grey-dark !important;
  color: $grey-dark;
  width: 80% !important;
  left: 20% !important;
  z-index: 10 !important;
  cursor: default;
}

.container {
  margin: 20px;
  height: 600px;
}

.availability {
  width: 100% !important;
  left: 0 !important;
  background-image: repeating-linear-gradient(
    45deg,
    $grey-light-button,
    $grey-light-button 10px,
    $grey-light-medium-dark 10px,
    $grey-light-medium-dark 20px
  );
  border-color: $grey-light-button !important;
}

.availability:hover {
  cursor: default;
}

.appointment {
  width: 80% !important;
  left: 20% !important;
}

.appointment-booked {
  background-color: $blue-light !important;
  border-color: $blue !important;
  color: $dark-blue !important;
  z-index: 10 !important;
}

.appointment-canceled,
.appointment-tutor_no_show {
  background-color: #f2dede !important;
  border-color: #ebccd1 !important;
  color: #a94442 !important;
}

.appointment-canceled_late,
.appointment-technical_error {
  background-color: #fcf8e3 !important;
  border-color: #faebcc !important;
  color: #8a6d3b !important;
}

.appointment-happened {
  background-color: $soft-mint !important;
  border-color: $mint !important;
  color: $grey-dark !important;
  z-index: 11 !important;
}

.appointment-no_show {
  background-color: darkgray !important;
  border-color: darkgray !important;
}

.modal {
  max-width: 600px;
  max-height: 85vh;
  overflow-y: scroll;
  margin: auto;
  padding: 20px;
  margin-top: 30px;
  background-color: $white;
  border: solid 1px;
  border-radius: 4px;
}
