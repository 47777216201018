@import '../../../style/utils/colors';
@import '../../../style/utils/breakpoints';

.move-back,
.move-forward {
  background-color: transparent;
  border-radius: 50px;
  padding: 10px;
  position: relative;
  &:hover {
    &::before {
      content: '';
      top: 0;
      left: -10px;
      bottom: 0;
      right: -10px;
      position: absolute;
      background-color: $mint;
      z-index: -1;
      border-radius: 50px;
    }
  }

  &:disabled {
    background-color: transparent;
    opacity: 0.3;
    &:hover {
      cursor: auto;
      &::before {
        content: none;
      }
    }
  }
}

.move-back {
  position: absolute;
  left: 0;
  transform: translate(-150%, -10px);
}

.move-forward {
  position: absolute;
  right: 0;
  transform: translate(150%, -10px);
}
