@import '../../style/utils/colors';
@import '../../style/utils/breakpoints';
@import '../../style/utils/timing';

.animation-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  inset: 0;
  z-index: 4;
  background-color: $grey-lightest;
  animation: makeBackgroundTransparent 0.2s ease-in 1.01 * $end-of-loading forwards;

  .logo-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 38vw;
    height: 38vw;
    position: relative;
    animation: shrinkAndMoveUp 0.8s ease-in-out $circle-shape-timelapse forwards, moveToSidePosition 0.5s ease-in $end-of-loading forwards;
    z-index: 5;

    @include breakpoint-max(xs) {
      width: 60vw;
      height: 60vw;

      animation: shrinkAndMoveUpMobile 0.8s ease-in-out $circle-shape-timelapse forwards,
        moveToSidePositionMobile 0.5s ease-in $end-of-loading forwards;
    }

    &::before {
      content: '';
      width: 100%;
      height: 100%;
      background-color: $white;
      position: absolute;
      border-radius: 100%;
      animation: createRoundedShape $circle-shape-timelapse ease-in-out forwards;
    }

    .chatter-logo {
      width: 25vw;
      height: 25vw;
      z-index: 3;

      @include breakpoint-max(xs) {
        width: 40vw;
        height: 40vw;
      }
    }
  }

  .content-wrapper {
    width: 60vw;
    position: absolute;
    top: 38%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 5vh;
    z-index: 1;
    opacity: 0;
    animation: showAfter 0.2s ease-in 1.4 * $circle-shape-timelapse forwards, disappearAfter 0.2s ease-in $end-of-loading forwards;

    @include breakpoint-max(xs) {
      width: 80vw;
      top: 35%;
      gap: 2vh;
    }

    .title {
      font-weight: 700;
    }

    .description {
      font-size: 1.1em;
      text-align: center;

      @include breakpoint-max(xs) {
        font-size: 1em;
      }
    }

    .progress-bar {
      height: 10px;
      width: 50%;
      border-radius: 5px;
      background-color: $white;
      position: relative;

      @include breakpoint-max(xs) {
        width: 80%;
      }

      &:after {
        content: '';
        border-radius: 5px;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 100%;
        background-color: $mint;
        transition: right 0.5s;
      }

      &.one-quarter {
        &:after {
          right: 75%;
        }
      }
      &.two-quarters {
        &:after {
          right: 70%;
        }
      }
      &.three-quarters {
        &:after {
          right: 35%;
        }
      }

      &.full {
        &:after {
          right: 0%;
        }
      }
    }
  }

  &.disabled {
    animation-play-state: paused;

    .logo-wrapper {
      animation: shrinkAndMoveUp 0.8s ease-in-out $circle-shape-timelapse forwards, moveToSidePosition 0;
    }

    .content-wrapper {
      animation: showAfter 0.2s ease-in 1.4 * $circle-shape-timelapse forwards, disappearAfter 0;
    }

    .progress-bar {
      display: none;
    }
  }
}

@keyframes createRoundedShape {
  0% {
    transform: scale(3);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes shrinkAndMoveUp {
  0% {
    transform: scale(1) translateY(0);
  }
  60% {
    transform: scale(0.2);
  }
  100% {
    transform: scale(0.2) translateY(-100vh);
  }
}

@keyframes shrinkAndMoveUpMobile {
  0% {
    transform: scale(1) translateY(0);
  }
  60% {
    transform: scale(0.4);
  }
  100% {
    transform: scale(0.4) translateY(-60vh);
  }
}

@keyframes showAfter {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes disappearAfter {
  from {
    opacity: 1;
    display: flex;
  }
  to {
    opacity: 0;
    display: none !important;
  }
}

@keyframes makeBackgroundTransparent {
  from {
    background-color: $grey-lightest;
    z-index: 4;
  }
  to {
    background-color: transparent;
    z-index: 1;
  }
}

@keyframes moveToSidePosition {
  to {
    transform: translate(-42vw, -40vh) scale(0.12);
  }
}

@keyframes moveToSidePositionMobile {
  to {
    transform: translate(-40vw, -40vh) scale(0.2);
  }
}
