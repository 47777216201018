@import '../../style/utils/colors';
@import '../../style/utils/breakpoints';

$button-step--size: 0.8vw;
$button-step--height--mobile: 2vw;
$button-step--pading: 1vw;

.steps-wrapper {
  display: flex;
  justify-content: space-between;
  width: 100%;
  position: relative;

  @include breakpoint-max(sm) {
    justify-content: stretch;
  }

  .navigation-wrapper {
    display: none;
    @include breakpoint-max(sm) {
      display: block;
    }
  }

  &:after {
    content: ' ';
    position: absolute;
    height: 1px;
    border-radius: 5px;
    top: 50%;
    left: 0;
    right: 0;
    background-color: $grey-button-disabled-text;

    @include breakpoint-max(sm) {
      display: none;
    }
  }

  .button-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    background-color: $color-background;
    z-index: 3;
    padding: 0 $button-step--pading;

    @include breakpoint-max(sm) {
      width: 100vw;
      &.current {
        .step-dot {
          width: 150px;
        }
        .step-label {
          display: block;
          top: calc(2 * $button-step--height--mobile);
        }
      }
    }

    .step-dot {
      width: $button-step--size;
      height: $button-step--size;
      border-radius: 50px;
      background-color: $mint;
      background-color: $grey-button-disabled-text;

      &:hover {
        cursor: initial;
      }

      @include breakpoint-max(sm) {
        width: 100%;
        height: $button-step--height--mobile;
      }
    }

    .step-label {
      position: absolute;
      top: calc(2 * $button-step--size);
      font-size: 1em;
      font-weight: 400;
      white-space: nowrap;
      color: $grey-light-dark;

      &:hover {
        cursor: initial;
      }

      @include breakpoint-max(sm) {
        display: none;
      }
    }

    &.enabled {
      .step-dot {
        background-color: $mint;

        &:hover {
          cursor: pointer;
        }
      }
      .step-label {
        font-weight: 500;
        color: $grey-dark;

        &:hover {
          cursor: pointer;
        }
      }
    }
  }
}
