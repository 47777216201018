@import '../../../../../style/utils/colors';
@import '../../../../../style/utils/breakpoints';

.empty-state {
  width: 100%;
  height: 30vh;
  background-color: $gray85-opaque;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;

  h3 {
    color: $light-gray;
  }

  @include breakpoint-max(sm) {
    height: 15vh;
  }
}

.cards-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 2vw;

  @include breakpoint-max(sm) {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 3vw;
    max-height: 55vh;
    overflow-y: scroll;
  }

  .banner--wrapper {
    width: 100%;
    grid-column: 1/4;

    & > div {
      width: 66%;
      @include breakpoint-max(sm) {
        width: 100%;
      }
    }
  }
}
