@import '../../../../../style/utils/colors';

$line-width: 7px;

.section {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 2;

  .first-leg,
  .second-leg {
    min-width: $line-width;
    max-width: $line-width;
    height: 50%;
    background-color: $transparent-grey;

    &.section-current,
    &.done {
      background-color: $mint;
    }

    &.optional {
      position: relative;
      &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        border-left: $line-width dotted $mint;
      }
    }
  }

  .first {
    border-radius: 5px 5px 0 0;
  }
  .last {
    border-radius: 0 0 5px 5px;
  }
}
