@import '../../../../style/utils/colors';
@import '../../../../style/utils/breakpoints';

.alert-container {
    display: inline-flex;
    justify-content: flex-start;
    align-items: center;
    background-color: $background-purple;
    border-radius: 2rem;
    padding: 0.6rem 1.2rem !important;
    gap: 1rem;
    width: max-content;

    @include breakpoint-max(sm) {
        flex-direction: column;
        align-items: center;
        padding: 0.3rem 1.2rem 0.5rem 1.2rem !important;
        width: 100%;
        gap: 0.25rem;
    }

    p {
        white-space: nowrap;
        @include breakpoint-max(sm) {
            white-space: normal;
            font-size: 0.875rem !important;
        }
    }

    & > div {
        width: 30%;
    }
}

.wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;

    @include breakpoint-max(sm) {
        gap: 0.5rem;
    }
}