@import '../../../style/utils/colors';
@import '../../../style/utils/breakpoints';

.link {
  color: $link-default;

  &:hover {
    cursor: pointer;
    color: $link-hover;
    text-decoration: underline;
  }
}

.coach-cards-wrapper {
  position: relative;
  display: flex;
  justify-content: flex-start;
  box-sizing: border-box;
  gap: 2vw;
  width: 87vw;
  overflow-x: auto;
  margin-bottom: 2em;
  padding-bottom: 1.5em;
}

.filter {
  width: 100%;
  margin: -6vh 0 2vh;

  @include breakpoint-max(sm) {
    margin: 0 0 2vh;
  }
}
