@import '../../../style/utils/colors';

.field-wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  font-size: 14px;

  &.invalidField {
    input {
      border: 1px solid $red;

      &:focus {
        border: 1.4px solid $red;
        box-shadow: rgba(102, 175, 233, 0.3) 0px 1px 2px 0px, rgba(102, 175, 233, 0.15) 0px 2px 6px 2px;
      }
    }
  }

  input {
    width: 100%;
    height: 2.5em;
    outline: none;
    border: 1px solid $grey-light-button;
    border-radius: 0.3em;
    padding: 0.5em 0.75em;
    box-sizing: border-box;
    color: $grey-dark;
    background-color: $white !important;

    &:focus {
      border: 1.4px solid $gray85;
      box-shadow: rgba(102, 175, 233, 0.3) 0px 1px 2px 0px, rgba(102, 175, 233, 0.15) 0px 2px 6px 2px;
    }
  }

  .invalidMessage {
    color: $red;
    font-size: 0.85em;
    margin-top: 0.2em;
    margin-bottom: 0;
  }
}
