@import '../../../../../style/utils/colors';
@import '../../../../../style/utils/breakpoints';

.threeDotsButtons {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  position: absolute;
  right: 0;
  top: 0;
  animation: slideButton 0.2s cubic-bezier(0.22, 0.36, 0.34, 0.75) forwards;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
  border-radius: 6px;
  z-index: 2;

  button {
    text-align: left;
    padding: 0.8rem 1.2rem;
    border-bottom: 1px solid $gray85;
    color: $grey-dark;
    background-color: $white;
    &:hover {
      background-color: $gray85;
    }

    &:disabled {
      color: $grey-light-button-font;
      background-color: $border-grey;
      cursor: auto;
    }

    &:first-of-type {
      border-radius: 6px 6px 0 0;
    }

    &:last-of-type {
      color: $soft-red;
      border-bottom: none;
      border-radius: 0 0 6px 6px;

      &:hover {
        background-color: $background-red;
      }
    }
  }

  @include breakpoint-max(sm) {
    width: 100%;
    font-size: 0.8em;
  }

  .cancelSessionsButton {
    white-space: nowrap;
  }
}

.threeDots {
  background-color: transparent;
  outline: none;
  border: none;
  display: flex;
  gap: 3px;
  justify-content: space-between;
  align-items: center;
  padding: 1%;
  border-radius: 3px;
  .oneDot {
    width: 5px;
    height: 5px;
    background-color: $grey-dark;
    border-radius: 50%;
    position: relative;
  }
  &:hover {
    cursor: pointer;
    background-color: $grey-dark;
    transition: background-color 0.3s;
    .oneDot {
      background-color: $white;
    }
  }
}

.selected-action--wrapper {
  display: flex;
  gap: 1vw;
  animation: moveIntoAction 0.2s ease-out forwards;
}

@keyframes moveIntoAction {
  from {
    transform: translateX(50%);
  }
  to {
    transform: translateX(0);
  }
}
