@import '../../../../style/utils/colors';
@import '../../../../style/utils/breakpoints';

@mixin animatedBackground($color) {    
    animation-duration: 1.25s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-name: loading;
    animation-timing-function: linear;
    background: $color;
    background: linear-gradient(to right, lighten($color, 8%) 8%, lighten($color,4%) 18%, lighten($color,8%) 33%);
    background-size: 800px 104px;
    position: relative;
}

.loading {
    width: 100%;
    height: 100%;
    border-radius: 5px;
    @include animatedBackground($smooth-peach);

    &.salmon {
        @include animatedBackground($salmon);
    }

    &.gray {
        @include animatedBackground($grey-light-button);
    }
}

@keyframes loading{
    0%{
        background-position: -468px 0
    }
    100%{
        background-position: 468px 0
    }
}
