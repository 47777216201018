@import '../../utils/breakpoints';

.nextButton {
  margin-top: 20px;
  margin-bottom: 29px;
  margin-left: 50px;
  text-decoration: underline;
  text-align: center;

  @include breakpoint-max(xs) {
    margin-left: unset;
  }
}

.glyph {
    margin-left: 5px;
    font-size: 13px;
}