@import '../../../style/utils/breakpoints';
@import '../../../style/utils/colors';

$max-width: map-get($breakpoints, xl);

.loadingWrapper {
  width: 100%;
  height: 40vh;
  display: flex;
  justify-content: center;
  align-items: center;

  div {
    width: 50px;
    height: 50px;
  }
}

.header-wrapper {
  width: 100%;
  max-width: $max-width;
  background-color: $background-light-grey;

  .content-wrapper {
    padding: 3vw 8vw;
    margin: 0 auto;

    @include breakpoint-max(sm) {
      padding: 8vw 10vw;
    }

    .title {
      font-size: 1.5em;
      text-align: left;
      margin: 0 auto 1em;
      max-width: $max-width;

      span {
        font-size: 1em;
      }
    }
    .content {
      display: flex;
      justify-content: space-between;
      gap: 3vw;
      max-width: $max-width;
      margin: 0 auto;

      @include breakpoint-max(sm) {
        flex-direction: column;
        gap: 8vw;
      }

      .side-cards {
        width: 92%;
        display: flex;
        flex-direction: column;
        gap: 2vh;

        @include breakpoint-max(sm) {
          width: 100%;
        }
      }
    }
  }
}
