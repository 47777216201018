@import '../../../style/utils/colors';

.arrow {
  width: 0.875rem;
  height: 0.875rem;

  img {
    width: 100%;
    height: 100%;
    object-fit: fill;
  }
}

.right {
  transform: rotate(180deg);
}

.bottom {
  transform: rotate(-90deg);
}

.top {
  transform: rotate(90deg);
}
