@import '../../utils/elevation';
@import '../../utils/spacing';
@import '../../utils/colors';

.table {
  @include elevation(1);
  @include margin-vertical(16px);

  background-color: $white;
  border: 0;
  border-radius: 5px;

  > tbody > .lastRow {
    font-weight: bold;
    text-transform: uppercase;
    border-top: 1px solid $grey-dark;

    > .totalPayAmount {
      border-left: 0;
    }

    > .totalPayLabel {
      border-right: 0;
    }
  }

  thead,
  tbody {
    tr {
      th,
      td {
        padding: 0.8rem 1.5rem;
      }
    }
  }
}
