@import '../../../style/utils/colors';
@import '../../../style/utils/breakpoints';

$input-size: 30vw;

.modalWrapper {
  min-width: 805px;
  width: 80vw;
  position: relative;

  @include breakpoint-max(sm) {
    min-width: unset;
    width: 95vw;
  }

  .closeButton {
    position: absolute;
    top: 1vw;
    right: 1vw;
  }

  .modalTitle {
    min-width: 805px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 1rem;
    background-color: $mint-opaque;
    padding: calc(1rem + 1vh) 2.5rem !important;

    &.salmon {
      background-color: $salmon-opaque;
    }

    @include breakpoint-max(sm) {
      min-width: unset;
      padding: calc(1.5rem + 1vh) 1.5rem !important;
    }

    .main-title {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;

      p,
      span {
        font-size: 1rem;
      }
    }

    h2 {
      margin: 0 !important;
      font-size: 1.5rem !important;
      font-weight: 700 !important;
    }

    img {
      min-width: 50px;
      height: 50px;
      clip-path: circle(50% at center);
      object-fit: cover;
    }

    @include breakpoint-max(sm) {
      padding: 1.2rem 1.5rem !important;
    }

    span {
      font-size: 0.9em;
      @include breakpoint-max(sm) {
        text-align: center;
      }
    }
  }

  .modalTitle .salmon {
    background-color: $salmon;
  }

  .center-title {
    background-color: transparent;
    justify-content: center;
  }

  .modalSection {
    position: relative;
    min-width: 805px;
    padding: calc(1rem + 1vh) 2.5rem !important;
    position: relative;

    @include breakpoint-max(sm) {
      min-width: unset;
      padding: calc(1rem + 1vh) 1.5rem !important;
    }

    h2 {
      font-size: 1.125rem;
      font-weight: bold;
      margin: 0;
    }

    p {
      font-size: 1rem;
      @include breakpoint-max(sm) {
        font-size: 0.95rem;
      }
    }

    .livePracticesNumber {
      margin-bottom: 1.5rem;
    }

    @include breakpoint-max(sm) {
      padding-top: 0 !important;
      padding-bottom: 0 !important;
      margin-top: 3vh;
    }

    .bookingOptionWithButton {
      width: 100%;
      display: flex;
      justify-content: flex-start;
      gap: 3%;
      margin-top: 2vh;

      @include breakpoint-max(sm) {
        flex-wrap: wrap;
      }

      button {
        white-space: nowrap;
        border-radius: 50px;
        border: none;
        outline: none;
        color: $grey-dark;
        padding: 0.5em 1em;

        @include breakpoint-max(sm) {
          height: 10vw;
          &:last-of-type {
            margin-top: 2vh;
          }
        }

        &.selected {
          background-color: $medium-mint;
        }
      }
    }

    &.withCalendar {
      min-width: 700px;

      @include breakpoint-max(sm) {
        width: 100%;
        min-width: unset;

        p {
          font-size: 1rem;
        }
      }
    }
  }

  .timezone-wrapper {
    padding-bottom: 0 !important;
    // display: flex;
    // justify-content: flex-start;
    // align-items: center;
    // gap: 2rem;

    & > h2 {
      white-space: nowrap;
    }

    & > div {
      width: $input-size;
    }

    .dropdown {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      width: 100%;
      gap: 1rem;

      & > div {
        width: 30%;
      }

      .errorWrapper {
        width: 70%;
        margin-top: 0.5rem;
      }

      @include breakpoint-max(sm) {
        max-width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;

        & > div {
          width: 100%;
        }
      }
    }
  }
}

.calendarStuff {
  min-width: 720px;
  margin-top: 0.75rem;
  display: flex;
  font-size: 0.875rem;
  height: 48vh;

  @include breakpoint-max(sm) {
    min-width: unset;
    display: flex;
    flex-direction: column;
    padding-top: 0 !important;
    height: 60vh;
  }
}

.calendar-with-alerts {
  height: 56vh;

  @include breakpoint-max(sm) {
    height: 70vh;
  }
}

.bottomMessageWrapper {
  display: flex;
  flex-direction: row;
}

#timezoneMessage {
  font-size: 0.875rem;
  margin-top: 0.5rem;
  min-width: 250px;
  flex-grow: 1;
}

#licenseExpirationDate {
  font-size: 0.875rem;
  margin-top: 0.5rem;
  min-width: 250px;
  flex-grow: 1;
}

#licenseMessage {
  color: $soft-red;
}

.buttonsWrapper {
  display: flex;
  justify-content: center;
  gap: 5vw;
  width: 50%;
  height: 6vh;
  margin: 6vh auto 2vh;

  button {
    width: 40%;
  }

  @include breakpoint-max(slg) {
    width: 80%;
  }
}

.errorWhileBooking ~ .buttonsWrapper {
  margin: 2vh auto 1vh !important;
}

.noPadding {
  .modalSection {
    max-height: 80vh;
    overflow-y: auto;
    margin: 0 !important;
    padding: 2rem 2.5rem !important;

    @include breakpoint-max(sm) {
      padding: calc(1.5rem + 1vh) 1.5rem !important;
    }
  }
}
