@import '../utils/breakpoints.scss';
@import '../utils/colors';

.login {
  background-color: $background-light-grey;
  min-height: calc(100vh - 60px);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 5vh;
  position: relative;

  &:after {
    content: '';
    width: 20vw;
    height: 25vw;
    max-width: 230px;
    max-height: 300px;
    bottom: 0;
    left: 10vw;
    position: absolute;
    background-image: url('../../assets/people/login-lady-2.svg');
    background-repeat: no-repeat;
    background-size: contain;
    background-position: bottom;
    z-index: 0;

    @include breakpoint-max(sm) {
      width: 42vw;
      height: 200px;
      left: 50%;
      transform: translateX(-50%);
    }
  }
}

.loginWrapper {
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: column;
  background-color: $white;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
  padding: 2rem 3rem 0;
  border-radius: 10px;
  z-index: 1;
  width: 32vw;
  min-width: 400px;

  @include breakpoint-max(sm) {
    width: 90vw;
    min-width: unset;
    padding: 2rem 2rem 0;
  }

  .title {
    margin: 0 0 0.5rem;
    font-size: 1.8rem;
    color: $grey-dark;
    font-weight: 500;
  }

  .subtitle {
    margin: 0;
    font-size: 1rem;
    color: $grey-dark;
    font-weight: 400;
  }

  .loginForm {
    width: 100%;
    margin: 2rem 0;

    form {
      display: flex;
      flex-direction: column;
      gap: 2.5vh;
    }
  }
}
