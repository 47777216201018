@import '../utils/colors';
@import '../utils/breakpoints';

.newBookingWrapper {
  background-color: $background-light-grey;
  padding: 0;
  display: flex;
  flex-direction: column;
  align-items: center;

  @include breakpoint-max(sm) {
    padding: 0 0 10vw;
  }

  .bookings {
    width: 100%;
    padding: 3vw 8vw 6vw;
    position: relative;

    @include breakpoint-max(sm) {
      padding: 6vw 8vw;
    }
  }

  .myBookings {
    width: 100%;
    padding: 2rem 8vw 4rem;
    background-color: $white;
  }
}
