@import '../../../../style/utils/colors';
@import '../../../../style/utils/breakpoints';

$p--line-height: 1.4em;

.description-modal-wrapper {
  width: 55vw;
  max-width: 600px;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;

  @include breakpoint-max(sm) {
    width: 80vw;
    max-height: 80vh;
  }

  .coach-presentation {
    width: 100%;
    height: 25vh;
    background-color: $grey-dark;
    margin-top: 1.2rem;
    margin-bottom: 1.2rem;
    display: flex;
    justify-content: center;
    align-items: center;

    @include breakpoint-max(sm) {
      height: 20vh;
    }

    &.dark_gray {
      background-color: $grey-dark;
    }
    &.light_blue {
      background-color: #85beda;
    }
    &.light_yellow {
      background-color: #ffd965;
    }
    &.light_red {
      background-color: #f86d6e;
    }
    &.light_gray {
      background-color: #cccccc;
    }
    &.light_purple {
      background-color: #ac90e8;
    }

    img {
      width: 20vh;
      height: 20vh;
      max-height: 210px;
      max-width: 210px;
      object-fit: cover;
      object-position: center;
      clip-path: circle(50% at center);

      @include breakpoint-max(sm) {
        width: 35vw;
        height: 35vw;
      }
    }
  }

  .modal-header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-bottom: 1em;

    @include breakpoint-max(sm) {
      flex-direction: column;
      margin: 0;
    }

    .coach-name {
      font-size: 1.5em;
      margin: 0;
    }

    .coach-availability {
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 0.5em;

      @include breakpoint-max(sm) {
        justify-content: center;
        gap: 0.7em;
        margin: 1em 0;
      }
    }
  }

  .modal-info {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 0.2em;

    p {
      margin: 0;
    }
  }

  .modal-description {
    width: 100%;
    line-height: 1.2em;
    margin: 2em 0;
    max-height: 30vh;
    overflow-y: auto;

    .text-wrapper {
      max-width: 95%;
    }

    @include breakpoint-max(sm) {
      max-height: 27vh;
    }
  }

  .book-button {
    width: 30%;
    margin-bottom: 1.8em;

    @include breakpoint-max(sm) {
      width: 60%;
    }
  }
}
