@import '../utils/colors';
@import '../utils/spacing';

.container {
  margin: auto;
  max-width: 90%;

  .occasional-and-time-off {
    display: flex;
    justify-content: space-around;
    margin-top: 10vh;

    & > div {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      gap: 1em;
      width: 80%;
      h3 {
        margin: 0;
      }
    }
  }
}

.keysContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  margin-bottom: 30px;
}

.keyContainer {
  display: flex;
  margin-bottom: 5px;
  margin-right: 5px;
}

.keyText {
  margin-left: 5px;
  text-transform: uppercase;
}

.keyColour {
  $size: 20px;

  border-radius: 100%;
  border: 1px solid;
  height: $size;
  width: $size;
}

.keyColour-booked {
  background-color: $white-blue;
  border-color: $blue;
}

.keyColour-canceled,
.keyColour-tutor_no_show {
  background-color: $white-red;
  border-color: $soft-red;
}

.keyColour-canceled_late,
.keyColour-technical_error {
  background-color: $white-yellow;
  border-color: $very-soft-orange;
}

.keyColour-happened {
  background-color: $soft-green;
  border-color: $grey-dark;
}

.keyColour-paid {
  background-color: $white;
  border-color: $purple;
}

.keyColour-paid_canceled_late,
.keyColour-paid_technical_error {
  background-color: $grey-light-button;
  border-color: $soft-pink;
}

.keyColour-paid_no_show {
  background-color: $grey-light-medium-dark;
  border-color: $soft-purple;
}

.keyColour-no_show {
  background-color: $grey-light-dark;
  border-color: $grey;
}

.calendarInstructions {
  margin-bottom: 20px;
}

.calendarContainer {
  background-color: $white;
  border: 1px solid $color-border;
  border-radius: 4px;
}

.heading {
  margin-top: 48px;
  text-align: center;
}

.timeZoneInput {
  margin: 20px auto;
  max-width: 400px;
  text-align: center;
}

.tooltip {
  position: relative;
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: $grey-dark;
  color: $white;
  text-align: center;
  border-radius: 6px;
  padding: 10px;

  /* Position the tooltip */
  position: absolute;
  z-index: 1;
  top: 130%;
  left: 50%;
  margin-left: -60px;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
}

.tooltip .tooltiptext::after {
  content: ' ';
  position: absolute;
  bottom: 100%; /* At the top of the tooltip */
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent $grey-dark transparent;
}

.modal {
  max-width: 800px;
  height: fit-content;
  margin: auto;
  padding: 20px;
  background-color: $white;
  border: solid 1px;
  border-radius: 4px;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.modal:hover,
.modal:focus,
.modal:active:hover {
  outline: 0;
}

.apptsToHonorContainer {
  overflow-y: auto;
  max-height: 300px;
  margin: 20px 0px;
}

.calendarHeader {
  display: flex;
  align-items: flex-end;
  justify-content: center;
  flex-direction: row;
}

.calendarPlaceholder {
  min-height: 24px;
  height: 24px;
  margin-bottom: 11px;
  margin-right: 15px;
}

.calendarLoader {
  min-height: 24px;
  height: 24px;
  margin-bottom: 11px;
  margin-left: 15px;
}

.calendarLoader::after {
  background-size: 24px;
  min-height: 24px;
  height: 24px;
  width: 24px;
}
