@import '../utils/breakpoints';
@import '../utils/colors';
@import '../utils/elevation';
@import '../utils/prefixed';
@import '../utils/spacing';
@import '../utils/z-indexes';

.container {
  padding: 8vh 6vw;

  .title {
    font-weight: 700;
    margin-bottom: 0.8rem;
  }

  .invoice-generated--wrapper {
    margin-bottom: 0.8rem;
  }

  .invoice-timeline--wrapper {
    margin: 1rem 0;
    padding-left: 2rem;
    display: flex;
    flex-direction: column;
    gap: 0.8rem;

    .check-icon {
      transform: translateY(0.4rem);
    }
  }

  .go-back {
    position: absolute;
    top: 12vh;
    left: 6vw;
    a {
      font-size: 1rem;
      color: $grey-dark;

      &:hover {
        opacity: 0.6;
      }

      &:before {
        content: ' ';
        display: inline-block;
        width: 8px;
        height: 8px;
        border-top: 2px solid $grey-dark;
        border-left: 2px solid $grey-dark;
        transform: rotate(-45deg);
        margin-right: 5px;
      }
    }
  }

  .invoice-not-generated--wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 5vh;

    .check-step {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 1vh;

      &.singleItem {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
      }

      .contact-us {
        a {
          font-size: 1rem;
          color: $dark-mint;

          &:hover {
            text-decoration: underline;
          }
        }
      }
    }
  }

  .warning--wrapper {
    border-radius: 5px;

    .warning--message {
      display: flex;
      justify-content: center;
      align-items: center;
      img {
        width: 25px;
        height: 25px;
      }
    }

    .button--wrapper {
      max-width: 150px;
    }
  }
}

.form-group {
  display: flex;
  flex-direction: column;
  margin-bottom: 5vh;

  &--title {
    font-weight: 500;
    margin-bottom: 0.8rem;
  }

  .form-fields {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 1vh;
  }

  .options {
    display: flex;
    gap: 2rem;
    justify-content: flex-start;

    .option {
      &:hover {
        cursor: pointer;
      }
      input {
        margin-right: 0.3rem;
        &:hover {
          cursor: pointer;
        }
      }

      label {
        &:hover {
          cursor: pointer;
        }
      }
    }
  }

  fieldset {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;

    label {
      margin-right: 1rem;
      white-space: nowrap;
    }

    input {
      width: 100%;
      margin: 0;

      &.first-of-two {
        margin-right: 1rem !important;

        &.building {
          width: 60%;
        }
      }
    }
  }
}

.countrySelector {
  margin-top: 1rem;
}

.disabledBlock {
  @include user-select(none);

  -webkit-touch-callout: none;
  pointer-events: none;
  position: relative;

  &:before {
    @include elevation(16);

    background-color: $white;
    content: ' ';
    display: block;
    filter: none;
    height: 100%;
    left: 0;
    opacity: 0.85;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: $z-index-disabled-overlay;
  }

  > *:not(:first-child) {
    filter: blur(2px);
  }
}

.disabledMessage {
  font-size: 1.5em;
  max-width: 480px;
  text-align: center;
}

.disabledMessageContainer {
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
  padding: 32px;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: $z-index-disabled-overlay + 1;
}

.fieldError {
  color: $red-dark;
  margin-left: 0.4rem;
}

.lastSection {
  padding-bottom: 24px;

  @include breakpoint(md) {
    padding-bottom: 64px;
  }
}

.modal {
  padding: 32px;
}

.modalActions {
  display: flex;
  justify-content: center;
  gap: 4vw;
  margin-top: 2rem;
}

.modalHeading {
  margin: 0 0 24px;
  text-align: center;
}

.newSection {
  padding-top: 32px;
}

.required {
  color: $red-dark;
}

.textBox {
  height: 128px !important;
}

.textInput,
textarea {
  padding: 0.4rem 0.8rem;
  font-size: 1rem;
}

.notice {
  font-size: 0.9rem;
  margin-bottom: 1rem;
}
