@import '../utils/colors';
@import '../utils/elevation';
@import '../utils/breakpoints';

.content {
  width: 95%;
  margin: 5vh auto 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.tooltip {
  color: #dad6d6;
  bottom: 5px;
  top: unset;
  right: 5px;
  font-size: 18px;
}

.tooltip > div {
  min-width: 20px;
  min-height: 20px;
}


.progressLoader {
  background-size: 30px;
}

.header p {
  text-align: center;
  margin-bottom: 1rem;
}

.header h1 {
  font-size: 32px;
  margin-bottom: 1%;
}

.name {
  margin-top: 0;
  font-size: 20px;
  margin-bottom: 15px;
}

.noLessons,
.noResources {
  @include elevation(1);

  background: $white;
  margin-bottom: 24px;
  padding: 32px;

  :last-child {
    margin-bottom: 0;
  }
}

.resourceCard {
  max-width: 320px;
  min-height: 350px;
  border-radius: 5px;
}

.lessonResourceCard {
  border-radius: 5px;
  max-width: 1035px;
}

.tutorHeader {
  background-color: $blue !important;
}

.progressBar {
  height: 10px;
  margin-top: 0px;
  width: 100%;
  border: 1px solid $grey-light-dark;
  margin-right: 13px;
  margin-left: 10px;
  margin-bottom: 0px;
  @include breakpoint-max(xs) {
    margin-right: 5px;
  }
}

.brainFreezeContainer .progressBar div {
  background-color: $grey-light-button !important;
}

.progressBar div {
  color: $grey-darkest;
  background-color: $mint;
}

.finishedContainer .resourceBtns {
  box-shadow: none !important;
  background-color: $white !important;
  color: $grey-dark !important;
  border: solid 1px $grey-dark !important;
}

.finishedContainer .resourceBtns:hover {
  opacity: 0.6;
  background-color: $grey-light !important;
}

.brainFreeze {
  min-width: 135px;
  font-size: 14px;
  text-align: center;
}

.counter {
  font-variant-numeric: tabular-nums;
}

.brainFreezeContainer {
  background-color: $gainsboro !important;
  box-shadow: none !important;
}

.finishedContainer .progressBarWrapper {
  margin-left: 15px;
  margin-top: -8px;
}

.finishedContainer .progressBarWrapper span {
  font-weight: bold;
  color: $grey-medium-dark;
}

.lessonResourceContainer {
  display: flex;
  justify-content: space-between;
  gap: 1rem;
  align-items: center;
  background-color: transparent;
  border-radius: 5px;
  padding: 1.3rem 1.2rem;
  color: $blue;
  align-items: center;
  border: 1px solid $grey-light;

  @include breakpoint-max(xs) {
    background-color: white;
    padding: 2vh 5vw;
    width: 100%;
  }

  .titleStack {
    display: flex;
    justify-content: flex-start;
    gap: 0.6rem;

    .resource-icon {
      width: 20px;
      height: 20px;
    }

    .title {
      font-size: 1rem;
      font-weight: bold;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
    }
  }

  .resourceBtnContainer {
    right: 1rem;

    button {
      a {
        color: $grey-dark;
        font-size: 0.875rem;
      }
    }
  }
}

.lessonCeregoResourceContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
  background-color: transparent;
  border-radius: 5px;
  padding: 1.5rem 1.2rem;
  width: 100%;
  border: 1px solid $grey-light;

  @include breakpoint-max(sm) {
    background-color: white;
    align-items: center !important;
    justify-content: space-around;

    .buttonContainer {
      margin: 0;
    }
  }
}

.lessonCeregoResourceContainer .btn {
  height: fit-content;
}

.lessonResourceLeftContent {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 1rem;
  align-content: center;
  display: flex;
  align-items: flex-start;
  width: 100%;

  @include breakpoint-max(xs) {
    align-items: flex-start;
    justify-content: flex-start;
    gap: 1vh;
    width: 70%;
  }
}

.progressBarWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  align-items: center;
  max-width: 250px;
  @include breakpoint-max(xs) {
    margin: 0px;
    width: 80%;
  }
}

.progress,
.progressLoading {
  width: 50px;
  text-align: right;
  font-weight: bold;
  color: $grey-dark;
  text-decoration: blink;
  @include breakpoint-max(xs) {
    font-size: 14px;
    text-align: left;
  }
}

.progressLoading {
  color: $light-gray;
}

@-webkit-keyframes blinker {
  from {
    opacity: 1;
  }
  to {
    opacity: 0.3;
  }
}

.progressBarLoading {
  opacity: 0.5;
  -webkit-animation-name: blinker;
  -webkit-animation-duration: 0.6s;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-timing-function: ease-in-out;
  -webkit-animation-direction: alternate;
}

.lessonResourceLeftContent {
  h2 {
    font-size: 1rem;
    line-height: 1.2rem;
    margin: unset;

    @include breakpoint-max(xs) {
      font-size: 0.8em;
      line-height: 1.2em;
      margin: 0;
    }
  }
}

.disabledText {
  color: $grey-light-medium-dark;
}

.disabledContainer {
  border: 1px solid $grey-light-medium-dark;
}

.finishedContainer {
  box-shadow: none !important;
}

.disabledIcon {
  opacity: 0.5;
}
.buttonContainer {
  width: fit-content;
  display: flex;
  justify-content: center;
  align-items: center;
  max-height: 20px;
}

.buttonContainer a {
  width: 60%;
}

.resourceBtns:hover {
  background-color: $grey-medium-dark !important;
}

.resourceBtns {
  margin: 10px 0 10px 40px;
  min-width: 135px;
  background-color: $grey-dark !important;
  color: $white !important;
  box-shadow: none !important;

  @include breakpoint-max(xs) {
    min-width: 75px;
    width: 100%;
    margin-left: unset;
    padding: 8px 8px !important;
    margin-top: 0px !important;
    margin-bottom: 0px !important;
    margin-right: 0px !important;
  }
}
