.alert {
  margin: 0 0 16px;
}

.button {
  margin: 8px;
}

.container {
  text-align: center;
  display: flex;
  flex-direction: column;
  gap: 4vh;
  width: 60vw;
}

.formWrapper {
  display: flex;
  flex-direction: column;
  gap: 2vh;

  .checkbox {
    display: flex;
    white-space: nowrap;
    label {
      input {
        margin-right: 0.3em;
      }
    }
  }
}

.label {
  font-weight: bold;
}

.timeModal {
  z-index: 3000;
}

.buttonsWrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  gap: 2em;

  button {
    max-width: 150px;
  }
}

.formGroup {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 4vw;

  .secundaryFormGroup {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 0.5em;
  }
}

.popupCalendar {
  font-size: 0.9em;
}

.inputsContainer {
  display: flex;
  justify-content: space-between;
  gap: 1em;
}

.calendarLabel {
  margin-right: 0.7em;
}
