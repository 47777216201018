.regular-wrapper,
.bigger-wrapper {
  position: absolute;
  top: 50%;
  z-index: 2;

  .blocked-lesson {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background-color: #f3f3f3;
    position: absolute;
  }

  .progress-ring {
    .progress-ring__circle {
      stroke-dasharray: 10 20;
    }
  }
}

.regular-wrapper {
  width: 30px;
  height: 30px;
  transform: translate(-38%, -50%) rotate(-90deg);
}

.bigger-wrapper {
  width: 36px;
  height: 36px;
  transform: translate(-42%, -50%) rotate(-90deg);
}
