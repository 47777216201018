.wrapper {
  padding: 6vh 6.6vw 12vh;
  position: relative;

  .back {
    position: absolute;
    top: 1.5%;
    span {
      margin-left: 0.4rem;
    }
    &:hover {
      opacity: 0.7;
    }
  }
}
