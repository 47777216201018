@import '../../../style/utils/colors';

.active {
  .lessonItem {
    font-size: 0.9em;
    min-height: 40px;
    &.mint {
      border-bottom: 3px solid $mint !important;
    }

    &.salmon {
      border-bottom: 3px solid $salmon !important;
    }
  }
}

.item {
  &:hover {
    cursor: pointer;
  }
}

.done {
  background-color: $background-extra-soft-mint;
  color: $grey-medium-dark;
  &:hover {
    cursor: auto;
    background-color: $background-extra-soft-mint;
  }
  .item {
    &:hover {
      cursor: auto;
    }
  }
}

.disabled {
  background-color: $grey-lightest;
  &:hover {
    cursor: auto;
    background-color: $grey-lightest;
  }
}

.lessonItem {
  margin: 0.3em 1em;
  line-height: 2.5em;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;

  .cancelLessonInfo {
    margin: 0;
    display: flex;
    justify-content: flex-start;
    align-items: center;

    .lessonNumber {
      margin-right: 1em;
    }
  }

  .disabled {
    color: silver;
  }

  .lessonNumber {
    font-weight: 600;
    font-size: 0.875em;
    display: block;
  }

  .lessonDate {
    font-weight: 400;
    font-size: 0.875em;
    display: block;
    line-height: 1.5em;
  }

  .imageWrapper {
    transition: transform 0.4s;
    display: flex;
    justify-content: center;
    align-items: center;

    button {
      width: 100%;
      height: 100%;
      img {
        width: 20px;
        height: 20px;
      }
    }

    img {
      width: 20px;
      height: 20px;
    }

    .checkButton {
      outline: none;
      border: none;
      background-color: transparent;
      display: flex;
      align-items: center;
      img {
        width: 100%;
        &:hover {
          transform: scale(1.3);
        }
      }
    }

    .confirmButton {
      background: none;
      border: none;
      outline: none;
      background-color: $mint;
      color: $white;
      padding: 0.25rem 0.5rem;
      position: relative;
      text-decoration: none;
      font-size: 0.75rem !important;
      border-radius: 5px;

      &:hover {
        opacity: 0.7;
      }

      &:disabled {
        color: $grey-light-dark;
        background-color: transparent;

        &:hover {
          cursor: default;
          opacity: 1;
          color: $grey-light-dark;
        }
      }
    }
    .cancelButton {
      background: none;
      border: none;
      outline: none;
      color: $medium-pink;
      &:hover {
        cursor: pointer;
        color: $soft-pink;
        text-decoration: underline;
      }
    }
  }
}
