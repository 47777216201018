@import '../../../style/utils/colors';

.error {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 0.5rem;
    width: 100% !important;

    svg {
        min-width: 1.2rem;
        max-width: 1.2rem;
        min-height: 1.2rem;
        max-height: 1.2rem;
        path {
            fill: $red;

        }
    }

    p {
        font-size: 0.875rem !important;
        color: $red;

        strong {
            font-size: 0.875rem !important;
            margin-right: 0.25rem;
        }
    }
}

