@import '../../../../style/utils/colors';

.tip-wrapper {
  padding: 0.3em 0.6em;
  border-radius: 5px;
  background-color: $tag-grey;

  p {
    color: $grey-dark;
    font-size: 0.75em;
  }
}
