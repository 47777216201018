.container {
  display: flex;
  justify-content: center;
  margin: 16px 0;
  width: 100%;
}

@media (max-width: 880px) {
  .container {
    display: block;
  }
}
