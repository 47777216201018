@import '../utils/spacing';
@import '../utils/colors';
@import '../utils/breakpoints';

.header {
  width: 320px;
  height: 60px;
  background-image: url('../../assets/logos/chatterbox-full-logo.svg');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;

  @include breakpoint-max(sm) {
    width: 200px;
    height: 40px;
  }
}
