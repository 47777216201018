.wrapper {
  display: flex;
  justify-content: center;
  gap: 2vh;

  .buttons-container {
    display: flex;
    justify-content: center;
    gap: 2vw;
    width: fit-content;
  }
}
