@import '../../../../style/utils/colors';

$loader-size: 100%;

.loadingWrapper {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: transparent;

    svg {
        fill: $grey-dark;
    }
}

.darkMode {
    svg {
        fill: $white;
    }
}
