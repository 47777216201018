@import '../../../../../style/utils/colors';
@import '../../../../../style/utils/breakpoints';

.information-wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 2em 0 0;
  gap: 2vh;


  @include breakpoint-max(sm) {
    margin: 3em 0 0;
  }

  h2 {
    color: $link-default;
    font-weight: bold;
    
    svg {
      width: 0.8em;
      height: 0.8em;
      transform: translateY(8%);
    }
  }

  .response {
    p {
      margin-bottom: 1em;
      font-size: 1em;
    }
  }
}