@import '../../../../../style/utils/colors';

.ticket-wrapper {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.details {
  border: 1px solid $grey-light;
  padding: 0.75rem 1rem;
  border-radius: 8px;

  h2 {
    font-size: 0.875rem;
    color: $light-gray;
    margin-bottom: 0.75rem;
  }

  .line,
  .line b {
    font-size: 0.875rem;
    margin-bottom: 0.25rem;
  }

  .line b {
    text-decoration: underline;
  }

  .nothing {
    display: block;
    width: 100%;
    text-align: center;
    padding: 0.5rem 1rem;
    background-color: $whisper;
    border-radius: 4px;
  }
}
