@import '../../utils/breakpoints';
@import '../../utils/colors';

.tutorCardWrapper {
  background-color: $white;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  padding: 3%;
  border-radius: 10px;
  margin-bottom: 2%;
  @include breakpoint-max(xs) {
    flex-direction: column;
    align-items: center;
    padding: 3% 5%;
  }
}

.tutorProfilePic {
  width: 91px;
  height: 91px;
  min-width: 91px;
  border-radius: 100%;
  object-fit: cover;
  opacity: .8;
}

.greenButton {
  width: 115px;
  height: 40px;
  background-color: $mint;
  margin-top: 30px;
  border-radius: 5px !important;
  box-shadow: none !important;
  text-align: center;
}

.tutorInfoWrapper {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  width: 100%;

  @include breakpoint-max(xs) {
    margin-top: 1vh;
  }
}

.tutorInfoTop {
  display: flex;
  flex-direction: row;
  margin-bottom: 20px;
  @include breakpoint-max(xs) {
    flex-direction: column;
    align-items: center;
  }
}

.tutorInfoLine {
  display: flex;
  flex-direction: row;
  margin-top: 5px;
  @include breakpoint-max(xs) {
    flex-direction: column;
  }
}

.tutorInfo1 {
  font-size: 1.3em;
  margin-right: 20px;
}

.tutorInfo2 {
  font-size: 1.1em;
  margin-right: 20px;
}

.tutorInfoFirstColumn {
  min-width: 118px;
}

.tutorIcon {
  font-size: 0.8em;
  margin-right: 10px;
}

.tutorInfoLeft {
  flex-grow: 2;
  display: flex;
  flex-direction: column;
  @include breakpoint-max(xs) {
    width: 100%;
  }
}

.tutorInfoRight {
  align-items: flex-end;
  margin-left: 30px;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  min-width: 150px;
}

.tutorName {
  margin-bottom: 10px;
  font-size: 24pt;
  cursor: pointer;
}

.tutorName:hover {
  color: $grey;
}

.tutorInfoTag {
  padding: 5px;
  padding-left: 10px;
  padding-right: 10px;
  background-color: $red;
  color: $white;
  border-radius: 5px;
  margin-bottom: 5px;
}

.tutorCtaContainer {
  display: flex;
  flex-direction: column;
  gap: 20px;
  justify-content: center;
  align-items: center;
  width: 150px;
  text-align: center;
  margin-right: 20px;
}
