@import '../../../style/utils/colors';

.loadingWrapper {
  width: 100%;
  height: 20vh;
  display: flex;
  justify-content: center;
  align-items: center;

  .loader {
    width: 80px;
    height: 80px;
  }
}

.goBack {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  color: $grey-dark;
  font-size: 0.9rem;
  text-decoration: underline;

  &:hover {
    cursor: pointer;
    color: $dark-mint;

    * {
      color: $dark-mint;
      stroke: $dark-mint;
    }
  }
}
