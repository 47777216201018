@import '../utils/colors';
@import '../utils/breakpoints';

$margin-level-selector: calc(8% + 11px);

.onboardingExternal {
  width: 100%;
  height: calc(100vh - 60px);
  background-size: cover;
  background-position: top;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: -32px;
}

.onboardingContainer {
  background-color: $white;
  margin-top: 5%;
  margin-bottom: 5%;
  max-width: 870px;
  width: 80vw;
}

.onboardingFull {
  min-width: 100%;
  min-height: 100%;
  width: 100% !important;
  height: 100% !important;
  min-height: 81vh;
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.onboardingFull .stepWrapper {
  height: 100%;
  min-height: calc(100vh - 60px);
}

.placementTestLoadingContainer {
  width: 5vw;
  height: 100vh;
  display: flex;
  justify-content: center;
}

.stepWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;

  .coachesFaces {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    justify-content: space-between;

    @include breakpoint-max(xs) {
      display: none;
    }

    img {
      display: block;
      position: absolute;

      &:nth-child(1) {
        bottom: 28%;
        left: 5%;
      }

      &:nth-child(2) {
        bottom: 25%;
        left: 27%;
      }

      &:nth-child(3) {
        bottom: 7%;
        left: 18%;
      }

      &:nth-child(4) {
        bottom: 25%;
        right: 20%;
      }

      &:nth-child(5) {
        bottom: 9%;
        right: 8%;
      }

      &:nth-child(6) {
        bottom: 40%;
        right: 5%;
      }
    }
  }
}

.onboarding__title-wrapper {
  margin: 2.4vw 0 1.5vw 0;
  width: 70%;

  @include breakpoint-max(xs) {
    width: 80%;
  }

  h1,
  h2,
  p {
    margin: 0 !important;
  }

  h1 {
    font-size: 26px;
  }

  h2,
  p {
    font-size: 14px;
  }

  .onboardingHeader {
    margin-top: 1.1em !important;
    text-align: center;
  }

  .onboardingSubtitle {
    text-align: center;
    font-size: 12px;
    font-style: italic;
  }
}

.buttonContainer {
  display: flex;
  justify-content: center;
  gap: 5vw;
  width: 100%;
  width: 100%;
  margin: 5% 0;

  z-index: 100;

  button {
    width: 160px;
    height: 53px;
  }

  .defaultNextButton {
    width: auto;
    height: 40px;
    background-color: $mint;
    margin-top: 4%;
  }

  .defaultNextButton:hover {
    background-color: $mint;
    transform: scale(0.98);
  }

  .nextButton {
    background-color: $grey-dark;
    color: #fff;

    z-index: 100;

    &:hover {
      background-color: $active-grey;
      color: $grey-dark;
      transform: scale(0.98);
    }
  }
}

.buttonContainerColumn {
  margin-left: 8%;
  margin-right: 8%;
  margin-bottom: 5%;
  margin-top: 5%;
  text-align: center;
  width: 84%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.loader {
  background-size: 34px !important;
}

.backButton {
  width: 79px;
  height: 40px;
  background-color: transparent;
  margin-top: 4%;
  border-radius: 5px !important;
  box-shadow: none !important;
  margin-right: 20px;
  text-decoration: underline;
  color: $black;
}

.backButton:hover,
.backButton:focus,
.backButton:active:hover {
  color: $black;
  outline: 0;
}

.addLanguageButton {
  height: 40px;
  width: 200px;
  background-color: $back-button;
  margin-top: 4%;
  margin-right: 10px;
  box-shadow: none !important;
}

.defaultNextButton {
  width: auto;
  height: 40px;
  background-color: $mint;
  margin-top: 4%;
  border-radius: 5px !important;
  box-shadow: none !important;
}

.defaultNextButton:hover {
  background-color: $mint;
  transform: scale(0.98);
}

.nextButton {
  width: 79px;
  height: 40px;
  background-color: $mint;
  margin-top: 4%;
  border-radius: 5px !important;
  box-shadow: none !important;
}

.nextButton:hover {
  background-color: $mint;
  transform: scale(0.98);
}

.backToDashboardButton {
  width: 230px;
  height: 40px;
  background-color: $mint;
  margin-top: 4%;
  border-radius: 5px !important;
  box-shadow: none !important;
  text-align: center;
}

.changeDialectButton {
  width: 230px;
  height: 40px;
  background-color: $mint;
  border-radius: 5px !important;
  box-shadow: none !important;
  text-align: center;
  margin-right: 1%;
}

.notifyMeButton {
  height: 40px;
  border-radius: 5px !important;
  box-shadow: none !important;
  text-align: center;
  background-color: $back-button;
  width: 230px;
  margin-left: 1%;
}

.skipButton {
  text-decoration: underline;
  background-color: transparent;
  border: 0px !important;
  box-shadow: none !important;
  display: flex;
  padding: 0 !important;
  margin-left: 8%;
  margin-right: 8%;
  margin-bottom: 5%;
  margin-top: 2%;
}

.skipButton:hover,
.skipButton:active {
  background-color: transparent;
}

.languageDropdown {
  border: solid 1px $grey-light-dark !important;
  border-radius: 5px !important;
  box-shadow: none !important;
  color: $grey;
  width: 100%;
}

.languageDropdownWrapper {
  margin-left: 8%;
  margin-right: 8%;
  width: 40%;
  margin-bottom: 2%;
}

.languageDropdownWrapper ul {
  width: 100%;
}

.languageDropdownWrapper ul li {
  text-align: center;
}

.onboardingCopy {
  margin-bottom: 3%;
  margin-left: 8%;
  margin-right: 8%;
}

.motivationButtonContainer {
  margin-left: calc(8% - 7px);
  margin-right: calc(8% - 7px);
  width: 84%;
  z-index: 1;
}

.motivationTextareaWrapper {
  display: flex;
  flex-direction: column;
  width: calc(100% - 24px);
}

.motivationTextarea {
  margin-left: 8%;
  margin-right: 8%;
  border: solid 1px $grey-dark;
  padding: 10px;
}

.personalTextarea {
  width: 84%;
  margin-top: 1%;
  min-height: 100px;
}

.levelSelectorWrapper {
  display: flex;
  flex-direction: row;
  margin-left: $margin-level-selector;
  margin-right: 8%;
  justify-content: space-between;
  margin-top: 2%;
  margin-bottom: 2%;
  align-items: center;
  width: 80%;
  position: relative;
}

.singleLevelWrapper {
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  align-items: center;
}

.singleLevelWrapperBlank {
  flex-grow: 0 !important;
}

.levelButton {
  border-radius: 100% !important;
  width: 16px;
  height: 16px;
  background-color: $grey-light-dark;
  padding: 0 !important;
}

.levelButtonGreen {
  border-radius: 100% !important;
  width: 37px;
  height: 37px;
  background-color: $soft-yellow;
  padding: 0 !important;
  margin-left: -11px;
  margin-right: -10px;
  flex-flow: row wrap;
  transition: opacity 0.5s;
}

.levelButtonGreen:hover {
  background-color: $soft-yellow;
}

.levelButtonGreen:active,
.levelButtonGreen:focus {
  border-radius: 100% !important;
  width: 37px;
  height: 37px;
  background-color: $soft-yellow;
  padding: 0 !important;
}

h2.onboardingHeader {
  margin-left: 8%;
  margin-right: 8%;
  margin-bottom: 10px;
  text-align: center;
}

.levelLine {
  flex-grow: 1 !important;
  height: 4px;
  background-color: $grey-light-dark;
}

.levelLineGreen {
  flex-grow: 1 !important;
  height: 4px;
  background-color: $soft-yellow;
}

.checkedIcon {
  color: $white;
  margin-top: 4px;
}

.checkContainer {
  margin-left: 8%;
  margin-right: 8%;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-start;
}

.checkContainer p,
.personalInterestCheckContainer p {
  margin: 0;
}

.interestTypingContainer {
  margin-bottom: 3vh;
}

.interestButtonsContainer {
  display: flex;
  gap: 1vw;
  flex-wrap: wrap;
  max-height: 25vh;
  overflow: auto;
}

.personalInterestCheckContainer {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  width: 100%;
  align-items: center;
  float: left;
  margin-bottom: 10px;
}

.professionalCheckbox {
  margin-right: 10px;
}

.sessionButtonContainer {
  display: flex;
  margin-left: 8%;
  margin-right: 8%;
  justify-content: space-around;
}

.dialectHelp {
  margin-left: 8%;
  margin-right: 8%;
  font-weight: 800;
  font-size: 0.85em;
  margin-top: -1.5%;
}

.dialectWrapper {
  width: 100%;
  text-align: center;
}

.creditsCopy {
  font-weight: bold !important;
  font-size: 0.8em;
}

.extraLanguageWrapper {
  width: 100%;
  text-align: center;
  margin-top: 20px;
}

.laguageLevelWrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: center;
}

.secondOption {
  width: 100%;
  text-align: center;
}

.autocompleteInput {
  width: 40%;
  min-width: 200px;
  height: 50px;
  text-align: center;
  border: 1px solid $grey-light-button;
}

.autocompleteContainer {
  width: 100%;
  text-align: center;
  height: 70px;
  z-index: 200;
}

.autocompleteContainer input:focus::placeholder,
.motivationTextarea:focus::placeholder {
  color: transparent;
}

.autocompleteSuggestionsContainer {
  text-align: center;
  width: 40%;
  min-width: 200px;
  margin: auto;
  z-index: 200;
  background-color: $white;
}
.autocompleteSuggestionsContainerOpen {
  border: 1px solid $grey-light-button;
  background-color: $white;
  z-index: 200;
  max-height: 30vh;
  height: fit-content;
  overflow-y: scroll;
}
.autocompleteSuggestionsList {
  list-style-type: none;
  padding: 0;
  margin: 0;
  background-color: $white;
  z-index: 200;
}

.autocompleteSuggestion div {
  z-index: 201;
}
.autocompleteSuggestion {
  cursor: pointer;
  background-color: $white;
  z-index: 201;
  position: relative;
}
.autocompleteSuggestion:hover {
  background-color: $grey-lightest;
}

.autocompleteTag {
  width: 70%;
  height: 20px;
  z-index: 1;
}

.autocompleteMultipleTags {
  display: flex;
  flex-direction: row;
  width: 70%;
  flex-wrap: wrap;
  margin-bottom: 30px;
  min-height: 27px;
  z-index: 1;
}

.autocompleteTagWrap {
  display: flex;
  flex-direction: row;
  margin-right: 10px;
  z-index: 1;
}

.autocompleteTagCross {
  margin-top: 5px;
  font-size: 0.8em;
  margin-left: 5px;
  cursor: pointer;
}

.autocompleteTagCross:hover {
  opacity: 0.8;
  cursor: pointer;
}

.autocompleteSuggestionItem {
  padding: 10px;
  z-index: 2;
}

.autocompleteTagHidden {
  width: 0;
  overflow-x: hidden;
  z-index: 1;
}

.noInterestedText {
  margin-top: 3px;
  font-size: 1em;
  margin-left: 4px;
  width: 100%;
}

.notifyMeWrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 4%;
}

.buttonContainerRow {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}

.pointSpan {
  margin-right: 10px;
}

.pointText {
  display: inline-block;
  font-size: 0.9em;
  width: 85%;
}

@media (max-width: 600px) {
  .onboardingContainer {
    width: 100%;
    height: 100%;
    padding-top: 10%;
    margin: unset;
  }

  .onboardingExternal {
    min-height: unset;
  }

  .sessionButtonContainer {
    flex-direction: column;
  }

  .languageDropdownWrapper {
    width: 60%;
  }

  .autocompleteMultipleTags {
    width: 100%;
    align-items: flex-start;
    margin-bottom: 15px;
  }

  .autocompleteTagWrap {
    height: fit-content;
  }

  .otherPositionQuestion {
    font-size: 14px;
  }

  .onboardingTitle {
    font-size: 10vw;
  }

  .onboardingText {
    font-size: 5vw;
  }
}

.loadingContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 5vh;
  padding-bottom: 5vh;

  .loadingWrapper,
  .loading {
    height: 10vw;
    position: relative;
    width: 10vw;
    border-radius: 100%;

    @include breakpoint-max(xs) {
      height: 40vw;
      width: 40vw;
    }
  }

  .loadingWrapper {
    margin: 40px auto;

    @include breakpoint-max(xs) {
      margin: 3vh auto;
    }
  }

  .loading {
    border: 2px solid transparent;
    border-color: transparent $mint transparent $mint;
    -moz-animation: rotate-loading 1.5s linear 0s infinite normal;
    -moz-transform-origin: 50% 50%;
    -o-animation: rotate-loading 1.5s linear 0s infinite normal;
    -o-transform-origin: 50% 50%;
    -webkit-animation: rotate-loading 1.5s linear 0s infinite normal;
    -webkit-transform-origin: 50% 50%;
    animation: rotate-loading 1.5s linear 0s infinite normal;
    transform-origin: 50% 50%;
  }

  .loadingWrapper .loading {
    -webkit-transition: all 0.5s ease-in-out;
    -moz-transition: all 0.5s ease-in-out;
    -ms-transition: all 0.5s ease-in-out;
    -o-transition: all 0.5s ease-in-out;
    transition: all 0.5s ease-in-out;
  }

  .loadingText {
    -moz-animation: loading-text-opacity 2s linear 0s infinite normal;
    -o-animation: loading-text-opacity 2s linear 0s infinite normal;
    -webkit-animation: loading-text-opacity 2s linear 0s infinite normal;
    animation: loading-text-opacity 2s linear 0s infinite normal;
    color: $grey-dark;
    font-size: 12px;
    font-weight: bold;
    opacity: 0;
    position: absolute;
    text-align: center;
    top: 0;
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10%;
  }
}

.listItem {
  padding: 10px 0;
}

.loadingList {
  margin-top: 10px;
  list-style: none;
  margin-bottom: 20px;
}

.listItemNumber {
  display: inline-block;
  border-radius: 50%;
  background-color: $soft-green;
  width: 40px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  font-weight: 600;
  margin-right: 10px;
}

.stepText {
  font-size: 16px;
}

.loadingHeader {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
}

.headerText {
  font-size: 24px;
}

.progressBar {
  background-color: $mint;
  height: 10px;
  transition: width 1s;
}

.typeformContainer {
  width: 100%;
  border: none !important;
  height: 100%;
  @include breakpoint-max(xs) {
    height: 100%;
    margin-top: -10%;
    margin-bottom: 0px;
  }
}

.instructionsWrapper {
  display: flex;
  flex-direction: row;
  margin-bottom: 5vh;
}

.tuitionInstructionsWrapper {
  display: flex;
  flex-direction: row;
}

.singleInstruction {
  display: flex;
  flex-direction: column;
  padding: 2%;
  align-items: center;
  justify-content: flex-start;
}

.singleTuitionInstructionLeft,
.singleTuitionInstructionRight {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  flex-grow: 1;
  flex-basis: 0;
}

.singleTuitionInstructionLeft {
  padding: 6%;
  padding-right: 3%;
  padding-bottom: 0;
}

.singleTuitionInstructionRight {
  padding: 6%;
  padding-left: 3%;
  padding-bottom: 0;
}

.instructionImg {
  width: auto;
  height: 10vw;
  min-height: 50px;
  max-height: 110px;
}

.instructionCopy {
  text-align: center;
}

.instructionsStudy,
.instructionsLive {
  padding: 10px;
  width: 100%;
  text-align: center;
  font-size: 1.4em;
}

.instructionsStudy {
  background-color: $mint;
}

.instructionsLive {
  background-color: $yellow;
}

.tuitionInstructionCopy {
  margin-top: 5%;
  margin-left: 5%;
  margin-right: 5%;
}

.typeformContainer iframe {
  height: 100%;
  width: 100%;
  border: none !important;
}

.typeformContainer div {
  height: 100%;
}

.typeformContainer button {
  display: none;
}

.ceregoLogo {
  width: 50%;
  min-width: 100px;
  margin-bottom: 10px;
}

.ceregoCopy {
  font-size: 0.8em;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  text-align: center;
  padding: 15px;
  margin-top: 15px;
  background-color: #f0f0f0;
}

.preferencesHeader,
.preferencesOption {
  flex-direction: row;
  display: flex;
  align-items: center;
  justify-content: center;
}

.preferencesOption p {
  width: 40%;
  text-align: left;
}

.preferencesHeader h2 {
  width: 40%;
  text-align: left;
}

.preferencesHeader h5 {
  width: 30%;
  text-align: center;
}

.classroomChoicesContainer {
  display: flex;
  flex-direction: column;
  width: 80%;
}

.meetMeInfo {
  width: 100%;
  padding-left: 28.3%;
  padding-right: 28.3%;
}

@keyframes rotate-loading {
  0% {
    transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
  }
}

@-moz-keyframes rotate-loading {
  0% {
    transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
  }
}

@-webkit-keyframes rotate-loading {
  0% {
    transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
  }
}

@-o-keyframes rotate-loading {
  0% {
    transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
  }
}

@keyframes rotate-loading {
  0% {
    transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
  }
}

@-moz-keyframes rotate-loading {
  0% {
    transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
  }
}

@-webkit-keyframes rotate-loading {
  0% {
    transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
  }
}

@-o-keyframes rotate-loading {
  0% {
    transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
  }
}

@keyframes loading-text-opacity {
  0% {
    opacity: 0;
  }
  20% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@-moz-keyframes loading-text-opacity {
  0% {
    opacity: 0;
  }
  20% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@-webkit-keyframes loading-text-opacity {
  0% {
    opacity: 0;
  }
  20% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@-o-keyframes loading-text-opacity {
  0% {
    opacity: 0;
  }
  20% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.loadingWrapper,
.loading {
  height: 150px;
  position: relative;
  width: 150px;
  border-radius: 100%;
}

.loadingWrapper {
  margin: 40px auto;
}

.loading {
  border: 2px solid transparent;
  border-color: transparent $mint transparent $mint;
  -moz-animation: rotate-loading 1.5s linear 0s infinite normal;
  -moz-transform-origin: 50% 50%;
  -o-animation: rotate-loading 1.5s linear 0s infinite normal;
  -o-transform-origin: 50% 50%;
  -webkit-animation: rotate-loading 1.5s linear 0s infinite normal;
  -webkit-transform-origin: 50% 50%;
  animation: rotate-loading 1.5s linear 0s infinite normal;
  transform-origin: 50% 50%;
}

.loadingWrapper .loading {
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
}

.loadingText {
  -moz-animation: loading-text-opacity 2s linear 0s infinite normal;
  -o-animation: loading-text-opacity 2s linear 0s infinite normal;
  -webkit-animation: loading-text-opacity 2s linear 0s infinite normal;
  animation: loading-text-opacity 2s linear 0s infinite normal;
  color: $grey-dark;
  font-size: 12px;
  font-weight: bold;
  opacity: 0;
  position: absolute;
  text-align: center;
  top: 0;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10%;
}

.selectAll__wrapper {
  display: flex;
  justify-content: flex-start;
  margin: 10px 0;
  .checkbox {
    label {
      margin-left: 1em !important;
    }
  }
}

.tooltip__wrapper {
  display: flex;
  align-items: center;
  position: relative;
  z-index: 101;

  span {
    transform: translateY(-10%);
    color: #dad6d6;
    font-size: 18px;

    & .tooltipContent {
      display: none;
      width: 14vw;
      max-width: 220px;
      position: absolute;
      left: 120%;
      top: -20%;
      font-size: 0.9em;
      border: 1px solid #333;
      background-color: #fff;
      box-sizing: content-box;
      padding: 5px 10px;
      margin: 0 5px !important;
      z-index: 101;

      @include breakpoint-max(xs) {
        width: 40vw;
        font-size: 0.7em;
      }
    }

    &:hover {
      cursor: pointer;

      .tooltipContent {
        display: inline-block;
      }
    }
  }
}

.showTutorButtonContainer {
  width: 15vw;
  height: 9.07vw;
  max-width: 210px;
  margin: 45px 0;
  z-index: 1;
  display: flex;
  align-items: center;

  button {
    height: 6vh;
    min-height: 53px;
    font-size: 100%;
  }

  @include breakpoint-max(xs) {
    width: 60vw;
    height: 10vw;
  }
}

.selectionWarning {
  margin-top: 1em;

  p {
    font-size: 0.9em;
  }
}
