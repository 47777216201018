@import '../../../style/utils/breakpoints';

.loadingWrapper {
  width: 300px;
  height: 30px;
  margin: 1vh 0;

  @include breakpoint-max(sm) {
    width: 150px;
  }
}

.tabContainer {
  margin: 2vh 0 3vh;
  display: flex;

  @include breakpoint-max(sm) {
    margin: 2vh 0;
  }

  .web-wrapper {
    display: flex;
    @include breakpoint-max(sm) {
      display: none;
    }
  }
  .mobile-wrapper {
    cursor: pointer;
    display: none;
    @include breakpoint-max(sm) {
      display: flex;
    }
  }
}
