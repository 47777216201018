@import '../utils/colors';
@import '../utils/breakpoints';

.footer {
  display: flex;
  justify-content: space-between;
  width: 100%;
  min-width: $md;
  gap: 5vw;
  background-color: $grey-lightest;
  letter-spacing: 1px;
  padding: 3vh 6vw;
  text-align: center;
  text-transform: uppercase;

  @include breakpoint-max(sm) {
    text-align: center;
    flex-direction: column;
    min-width: unset;
  }
}

.link {
  color: $color-footer-link;
  transition: underline 0.2s;
  font-size: 0.875rem;

  &:active,
  &:focus,
  &:hover {
    text-decoration: underline;
    color: $color-footer-link-hover;
  }
}
