@import '../../../style/utils/breakpoints';

.placement-test-wrapper {
  width: 100%;
  height: 100%;
}

.loadingWrapper {
  width: 6vw;
  height: 6vw;
  margin: 10vh auto;
}