$breakpoints: (
  se: 350px,
  xs: 500px,
  sm: 768px,
  md: 992px,
  slg: 1145px,
  lg: 1280px,
  xl: 1440px,
  xxl: 1920px
);

$se: 350px;
$xs: 500px;
$sm: 768px;
$md: 992px;
$slg: 1145px;
$lg: 1280px;
$xl: 1440px;

@mixin breakpoint($size) {
  $breakpoint: map-get($breakpoints, $size);

  @media (min-width: $breakpoint) {
    @content;
  }
}

@mixin breakpoint-max($size) {
  $breakpoint: map-get($breakpoints, $size);

  @media (max-width: $breakpoint) {
    @content;
  }
}
