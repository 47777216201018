.container {
  width: 90%;
  max-width: 800px;
  margin: 40px auto;
}

.bio {
  height: 80px !important;
}

.photoForm {
  display: flex;
}

.photo {
  border-radius: 75px;
  height: 150px;
  width: 150px;
  background-size: cover;
  background-position: center;
}

.photoContainer {
  display: inline;
}

.dropZone {
  text-align: center;
  display: inline;
  margin-left: 20px;
  padding: 6px 20px;
  height: 40px;
  &:hover {
    cursor: pointer;
    opacity: 0.8;
  }
}

.buttonContainer {
  margin-top: 10px;
  text-align: center
}

.appointmentType {
  text-transform: capitalize;
}

.explanation {
  font-style: italic;
  margin-bottom: 10px;
}

.label {
  font-size: 16px;
  text-align: left !important;
  margin-bottom: 5px !important;
}

@media (max-width: 768px) {
  .buttonContainer {
    float: none;
  }
}

@media (max-width: 375px) {
  .dropZone {
    margin-left: 15px;
    padding: 10px 15px;
  }
}
