@import '../utils/colors';

.autocompleteContainer {
  width: 100%;
  height: 70px;
  z-index: 2;
}

.autocompleteInput {
  width: 100%;
  height: 50px;
  text-align: center;
  border: 1px solid $grey-light;
  border-radius: 5px;
}

.autocompleteSuggestionsContainer {
  width: 100%;
  text-align: center;
  margin: auto;
  z-index: 200;
  background-color: $white;
}

.autocompleteSuggestionsContainerOpen {
  border: 1px solid $grey-light;
  background-color: $white;
  z-index: 200;
  margin: unset;
}

.autocompleteSuggestionsList {
  list-style-type: none;
  padding: 0;
  margin: 0;
  background-color: $white;
  z-index: 2;
}
.autocompleteSuggestion {
  cursor: pointer;
  background-color: $white;
  z-index: 2;
  position: relative;
}
.autocompleteSuggestion:hover {
  background-color: $grey-lightest;
}

.autocompleteTag {
  width: 70%;
  height: 20px;
  z-index: 1;
}

.autocompleteMultipleTags {
  display: flex;
  flex-direction: row;
  width: 70%;
  flex-wrap: wrap;
  margin-bottom: 30px;
  min-height: 27px;
  z-index: 1;
}

.autocompleteTagWrap {
  display: flex;
  flex-direction: row;
  margin-right: 10px;
  z-index: 1;
}

.autocompleteTagCross {
  margin-top: 5px;
  font-size: 0.8em;
  margin-left: 5px;
  cursor: pointer;
}

.autocompleteTagCross:hover {
  opacity: 0.8;
  cursor: pointer;
}

.autocompleteSuggestionItem {
  padding: 10px;
  z-index: 2;
}

.autocompleteTagHidden {
  width: 0;
  overflow-x: hidden;
  z-index: 1;
}
