@import '../../../style/utils/breakpoints';
@import '../../../style/utils/colors';

$max-width: map-get($breakpoints, lg);

.course-details-wrapper {
  padding: 3vw 8vw;
  width: 100%;

  @include breakpoint-max(sm) {
    width: 100%;
    padding: 4vh 10vw;
    border-right: none;
    background-color: $background-light-grey;
  }

  .course-details {
    width: 100%;
    max-width: $max-width;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
  }

  .loadingWrapper {
    width: 100%;
    height: 40vh;
    display: flex;
    justify-content: center;
    align-items: flex-start;

    .loader {
      width: 60px;
      height: 60px;
    }
  }
}
