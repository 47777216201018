@import '../../utils/colors';

.absencePeriod {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 80%;
  margin: 0 auto 1em;
  padding-bottom: 1em;
  border-bottom: 1px solid $grey-dark;

  &_default {
    text-align: center;
  }
}

.actions {
  text-align: center;
  position: relative;
  margin: 1em auto 0;
  max-width: 150px;
}

.container {
  width: 100%;
}

.loader {
  background-size: 30px;
  position: absolute;
  right: 152px;
  top: -10px;
}
