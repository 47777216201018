@import '../../utils/colors';

.spliter {
  position: relative;
  display: flex;
  justify-content: center;
  &:after {
    content: ' ';
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    height: 1px;
    background-color: $grey-light-button;
  }
  .or {
    display: block;
    margin: 1rem 0;
    background-color: $white;
    padding: 0 15px;
    z-index: 100;
    color: $grey-dark;
  }
}

.passwordReset {
  display: block;

  a {
    color: $grey-dark;
    font-size: 0.9rem;
    text-decoration: underline;

    &:hover {
      color: $dark-mint;
      text-decoration: underline;
    }
  }
}

.submitContainer {
  text-align: center;
  margin-top: 0.8rem;
}

.goBack {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  text-decoration: underline;
  p {
    font-size: 0.9rem;
  }

  &:hover {
    cursor: pointer;
    color: $dark-mint;
    p {
      color: $dark-mint;
    }
    svg {
      path {
        stroke: $dark-mint;
      }
    }
  }
}

.otherProviders {
  margin-bottom: 0.8rem;
  button {
    margin-top: 0;
  }
}
