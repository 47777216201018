@import '../../../../style/utils/colors';
@import '../../../../style/utils/breakpoints';

.card-wrapper {
  padding: 2em 2em 5em;
  background-color: $white;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  position: relative;
  width: 39%;
  max-width: 500px;
  min-width: 450px;
  min-height: 30vh;
  border: 2px solid $tag-grey;
  box-sizing: border-box;

  &:hover {
    cursor: pointer;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
  }

  &.loading {
    padding: 1vw 2vw;
  }

  @include breakpoint-max(sm) {
    padding: 1em 1em 5em;
    min-width: 250px;
  }

  .coach-presentation {
    width: 10vw;
    height: 10vw;
    min-width: 120px;
    min-height: 120px;
    margin-bottom: 1em;

    @include breakpoint-max(sm) {
      width: 10vh;
      height: 10vh;
      min-width: 100px;
      min-height: 100px;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
      clip-path: circle(50% at center);
    }
  }

  .coach-name {
    font-size: 1.4em;
    text-align: center;
  }

  .coach-availability {
    display: flex;
    justify-content: center;
    gap: 1vw;
    margin: 1em 0 2em;

    @include breakpoint-max(sm) {
      margin: 1em 0 2em;
    }
  }

  .coach-description {
    margin-bottom: 2em;
  }

  .book-button {
    position: absolute;
    bottom: 2em;

    @include breakpoint-max(sm) {
      bottom: 4vw;
    }
  }

  .card-unavailability-tag {
    position: absolute;
    top: 2%;
    right: 2%;
    background-color: $salmon;
    border-radius: 3px;
    padding: 4px 8px;
    font-size: 0.85em;
  }
}
