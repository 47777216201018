@import '../../../../style/utils/colors';
@import '../../../../style/utils/breakpoints';

.card-wrapper {
  padding: 1em 2em;
  background-color: $white;
  border-radius: 15px;
  width: 100%;
  
  &:hover {
    cursor: pointer;
  }

  &.is-open {
    .card-header {
      .card-title {
        &:after {
          transform: rotate(315deg);
        }
      }
    }
  }

  .card-header {
    display: flex;
    justify-content: space-between;
    @include breakpoint-max(sm) {
      font-size: 1em;
      position: relative;
    }
    .card-title {
      font-size: 1.1em;
      font-weight: 700;
      position: relative;

      @include breakpoint-max(sm) {
        font-size: 1em;
        position: unset;
      }
  
      &:after {
        content: ' ';
        position: absolute;
        top: 25%;
        width: 7px;
        height: 7px;
        margin-left: 1em;
        border-top: 2px solid $black;
        border-right: 2px solid $black;
        transform: rotate(135deg);
        transition: transform 0.3s;

        @include breakpoint-max(sm) {
          right: -0.5em;
        }
      }
    }

    button {
      &:hover {
        opacity: 0.5;
      }
    }
  }

  .card-response {
    margin-top: 1em;
    font-size: 1.1em;
  }
}