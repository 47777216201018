@import '../../../../style/utils/colors';
@import '../../../../style/utils/breakpoints';

.cardWrapper {
  width: 100%;
  min-height: 150px;
  max-height: 192px;
  padding: 5%;
  border: 2px solid $gray85;
  border-radius: 4px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
  display: flex;
  justify-content: space-between;
  position: relative;
  order: 2;

  &:hover {
    flex-direction: row;
    cursor: auto;
  }

  @include breakpoint-max(sm) {
    justify-content: space-between;
    align-items: center;
    flex-wrap: nowrap;
    height: unset;
    padding: 1rem 1.2rem;
  }

  &:not(.isPast) {
    .threeDots:hover {
      opacity: 0.7;
    }

    &.selectable:not(.singleSelectionIsOn) {
      &:hover {
        cursor: pointer;
        box-shadow: rgba(0, 0, 0, 0.25) 0px 25px 50px -12px;
      }
    }

    &.selected {
      border: 2px solid $medium-mint;

      @include breakpoint-max(sm) {
        border-left: 2px solid $medium-mint;
      }
    }

    &.selected--convo {
      border: 2px solid $salmon;

      @include breakpoint-max(sm) {
        border-left: 2px solid $salmon;
      }
    }
  }

  &.isPast {
    background-color: $gray85-opaque;
    box-shadow: none;

    .threeDots {
      display: none;
    }

    .cardContent {
      .cardInformation {
        &.convo-card {
          justify-content: flex-end;
          gap: 2vh;

          .icon--wrapper {
            filter: grayscale(1);
          }
        }

        .title {
          margin-top: 0;
        }
        .cardFooter {
          .tutorImage {
            button {
              img {
                filter: grayscale(1);
              }
            }
          }
        }
        * {
          color: $grey-light-button-font;
        }
      }
    }
  }

  .cardContent {
    width: 100%;
    height: 100%;
    min-height: 138px;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;

    @include breakpoint-max(sm) {
      order: 2;
      flex-wrap: nowrap;
      width: 87%;
      min-height: unset;
    }

    .cardInformation {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      flex-direction: column;
      width: 100%;
      height: 100%;

      .title {
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        gap: 0.75rem;

        h1 {
          font-size: 1.2rem;
          line-height: 1.5rem;
          margin: 0;
          white-space: nowrap;

          @include breakpoint-max(sm) {
            position: relative;
            &:after {
              content: ':';
              position: absolute;
              right: -10%;
            }
          }
        }

        h2 {
          font-size: 1.2rem;
          line-height: 1.5rem;
          margin: 0;
        }
      }

      .icon--wrapper {
        align-self: center;
        width: 50px;
        height: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
        clip-path: circle(50% at center);
        background-color: $salmon;

        @include breakpoint-max(sm) {
          width: 40px;
          height: 40px;
          margin: 1vh 0;

          svg {
            max-width: 20px;
            height: 15px;
          }
        }

        svg {
          width: 25px;
          height: 25px;
        }
      }

      .joinClassButtonWrapper {
        max-height: 2.5em;
      }
    }
  }

  p {
    margin: 0;
  }

  .cardFooter {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 3%;

    .tutorImage {
      width: 35px;
      height: 35px;
      button {
        outline: none;
        border: none;
        background-color: transparent;
        width: 100%;
        height: 100%;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          clip-path: circle(50% at center);
        }
      }
    }

    .timestamp {
      p {
        font-size: 0.9em;
        margin: 0;
        color: $grey-dark;
        opacity: 0.7;
      }

      .timezoneStamp {
        color: $grey-medium-dark;
      }
    }
  }

  .singleSelectionOptions {
    background-color: rgba(0, 0, 0, 0.6);
    position: absolute;
    border-radius: 4px;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-end;
    padding: 4% 5%;
    gap: 8%;
    z-index: 2000;

    & > button {
      width: 30px;
      height: 30px;
      padding: 0;
    }

    .buttonsWrapper {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      align-items: center;
      margin: 0 auto;
      gap: 10px;

      button {
        width: 48%;
        height: 45px;
        @include breakpoint-max(sm) {
          height: 40px;
        }
      }
    }
  }
}

.checkButton {
  width: 2vw;
  max-width: 24px;
  height: 2vw;
  max-height: 24px;
  border: 2px solid $gray85;
  border-radius: 6px;
  background-color: transparent;
  position: relative;

  @include breakpoint-max(sm) {
    width: 5vw;
    height: 5vw;
  }

  &.selected,
  &.selected--convo {
    border: none;
    transition: background-color 0.15s ease-in;

    &:after {
      content: ' ';
      position: absolute;
      bottom: 33%;
      left: 37%;
      width: 0.3vw;
      height: 0.6vw;
      border-bottom: 2px solid $grey-dark;
      border-right: 2px solid $grey-dark;
      transform: rotate(45deg);
    }

    @include breakpoint-max(sm) {
      position: relative;

      &:after {
        top: 11%;
        left: 30%;
        width: 1.7vw;
        height: 3.1vw;
      }
    }
  }

  &.selected {
    background-color: $mint;
  }

  &.selected--convo {
    background-color: $salmon;
  }
}

.threeDots {
  background-color: transparent;
  outline: none;
  border: none;
  display: flex;
  gap: 3px;
  justify-content: space-between;
  align-items: flex-start;
  padding: 3% 0%;
  border-radius: 3px;

  @include breakpoint-max(sm) {
    padding: 5%;
    position: absolute;
    right: 0;
    top: 0;
  }

  .oneDot {
    width: 5px;
    height: 5px;
    background-color: $grey-dark;
    border-radius: 50%;
    position: relative;
  }
}

.infoIconWrapper {
  position: relative;

  &:hover {
    .tooltipContent {
      display: block !important;
    }
  }

  .infoIcon {
    width: 2.2vw;
    height: 2vw;
  }

  .tooltipContent {
    display: none;
    width: 13vw;
    padding: 0.5em 0.7em;
    position: absolute;
    top: 30%;
    z-index: 1000;
    font-size: 1em;
    background-color: $white;
    border: 1px solid $grey-dark;
  }
}

.loaderContainer {
  width: 100%;
  height: 100%;
}
