@import '../../../style/utils/breakpoints';

$card-size: 15vw;
$card-height--mobile: 8vh;
$min-card-size: 150px;

.selectors-wrapper {
  display: flex;
  justify-content: center;
  gap: 5vw;
  margin-bottom: 2em;

  button {
    width: $card-size;
    height: $card-size;
    min-width: $min-card-size;
    min-height: $min-card-size;
  }

  @include breakpoint-max(sm) {
    flex-direction: column;
    width: 100%;

    button {
      width: 100%;
      min-height: unset;
      height: $card-height--mobile;
    }
  }
}
