@import '../../style/utils/breakpoints';
@import '../../style/utils/colors';

.container {
  width: 35vw;
  min-width: 380px;
  display: flex;
  flex-direction: column;
  align-items: center;

  @include breakpoint-max(sm) {
    min-width: 80vw;
  }

  .person-container {
    width: 45%;
    margin-bottom: 3vh !important;

    svg {
      width: 100%;
      height: 100%;
    }
  }

  h1 {
    font-size: 1.2rem;
    margin-bottom: 2vh !important;
  }

  p {
    width: 100%;
    text-align: left;
    font-size: 1rem;
    margin-bottom: 2vh;
  }

  .textarea {
    width: 100%;
    font-size: 1em;
    padding: 0.8em 1.2em;
    box-sizing: border-box;
    border-color: $grey-light-medium-dark;
    border-radius: 5px;
    margin: 1rem 0;
  }

  .buttonsWrapper {
    display: flex;
    gap: 4vw;
    margin-top: 2vh !important;
  }
}
