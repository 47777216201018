@import '../../../style/utils/breakpoints';

$max-width: map-get($breakpoints, xl);

.course-details-wrapper {
  padding: 2vw 8vw 3vw;
  max-width: $max-width;
  width: 100%;

  .alertsWrapper {
    margin-bottom: 2vw;
  }

  .course-details {
    width: 100%;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    gap: 10vw;
  }

  .loadingWrapper {
    width: 100%;
    height: 40vh;
    display: flex;
    justify-content: center;
    align-items: flex-start;

    .loader {
      width: 60px;
      height: 60px;
    }
  }
}
