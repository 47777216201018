.formGroup {
  display: flex;
  justify-content: space-between;
  width: 80%;
  margin-bottom: 0.5em;
}

.heading {
  margin-bottom: 20px;
}

.alert {
  margin-bottom: 0px;
  margin-top: 15px;
}

.buttonsWrapper {
  display: flex;
  flex-direction: column;
  gap: 0.5em;
  justify-content: center;
  margin-top: 1.5em;
  button {
    max-width: 250px;
  }
}

.fromLabel {
  margin-right: 1em;
}

.toLabel {
  margin-right: 1em;
}

.heading {
  margin-bottom: 20px;
}

.instruction {
  margin-bottom: 10px;
}
