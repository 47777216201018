.message {
    position: relative;
    padding-top: 100px
}

.message_container {
    height: 100vh;
    width: 90vw;
    margin-left: auto;
    margin-right: auto;
    text-align: center
}