@import '../../../../../../style/utils/colors';
@import '../../../../../../style/utils/breakpoints';

.cardWrapper {
  display: flex;
  width: 100%;
  max-height: 166px;

  .contentContainer {
    width: 100%;
    padding: 1.1rem 1.5rem;
    display: flex;
    gap: 1.5vw;
    justify-content: flex-start;
    align-items: center;
    background-color: $white;
    border-radius: 10px;
    border: 2px solid $border-grey;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;

    @include breakpoint-max(sm) {
      max-width: unset;
      padding: 1.2rem 1rem;
      gap: 3vw;
    }

    .icon--wrapper {
      width: 75px;
      height: 75px;
      display: flex;
      justify-content: center;
      align-items: center;
      clip-path: circle(50% at center);
      background-color: $mint;

      @include breakpoint-max(sm) {
        width: 40px;
        height: 40px;

        svg {
          width: 20px;
          height: 20px;
        }
      }
    }

    .card-data--wrapper {
      width: 80%;
      height: 100%;
      position: relative;

      .button--wrapper {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        text-align: left;
        position: relative;

        h2 {
          font-weight: 700;
          line-height: 1.75rem;

          @include breakpoint-max(sm) {
            font-size: 1.1rem;
            line-height: 1.2rem;
          }
        }

        &:after {
          position: absolute;
          right: 0;
          top: 50%;
          content: ' ';
          width: 6px;
          height: 6px;
          border-top: 3px solid $grey-dark;
          border-right: 3px solid $grey-dark;
          border-radius: 1px;
          transform: rotate(45deg) translate(-50%, -50%);
        }
      }
    }
  }
}

.text--wrapper {
  width: 100%;
  text-align: left;
  white-space: pre-line;

  h2 {
    font-weight: 700;
  }
}
