@import '../../../style/utils/colors';

.wrapper {
  position: relative;
  button {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 0.8vw;

    &:hover {
      opacity: 0.7;
    }

    .button-icon {
      max-width: 20px;
      max-height: 20px;
    }
  }
  .filter {
    position: absolute;
    top: 0;
    transform: translateY(-110%);
    z-index: 10000;
    background-color: transparent;
    border-radius: 3px;
    animation: slideToTop 0.3s ease-out;
  }
}

@keyframes slideToTop {
  from {
    opacity: 0;
    transform: translateY(-90%);
  }
  to {
    opacity: 1;
    transform: translateY(-110%);
  }
}