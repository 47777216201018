@import '../../../style/utils/colors';

$bar-height: 10px;


@keyframes loading {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(0);
  }
}

.wrapper {
  width: 100%;
  min-width: 100px;
  display: inline-flex;
  gap: 0.5rem;

  .bar {
    width: 100%;
    height: $bar-height;
    background-color: $white;
    border: 1px solid $grey-light-dark;
    border-radius: 10px;
    position: relative;
    box-sizing: content-box;

    .percentage {
      position: absolute;
      top: 0;
      left: 0;
      height: $bar-height;
      background-color: $mint;
      border-radius: 10px;
    }

    .reference {
      height: $bar-height;
      width: 1px;
      position: absolute;
      top: 0;
      border: 0.5px solid $grey-light-dark;
    }
  }

  .progress-text {
    white-space: nowrap;
    font-size: 0.875rem !important;
    line-height: calc($bar-height + 2px);

    &.spinner--wrapper {
      width: 1rem;
      height: 1rem;
      transform: translateY(-2px);
    }
  }
}
