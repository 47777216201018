@import '../../style/utils/colors';
@import '../../style/utils/breakpoints';

$link-color: $mint;
$logo-height: 26px;
$menu-height: 60px;

.majorContainer {
  position: relative;
}

.navbar-container {
  width: 100%;
  height: $menu-height;
  background-color: $background-light-grey;
  min-width: $md;

  @include breakpoint-max(xs) {
    min-width: unset;
  }

  &.logged-user {
    background-color: $grey-dark;
  }

  .navbar-wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 6vw;
    position: relative;

    .links-wrapper {
      height: 100%;
      display: flex;

      @include breakpoint-max(xs) {
        display: none;

        &.show-mobile-menu {
          display: flex;
          flex-direction: column;
          position: absolute;
          top: $menu-height;
          left: 0;
          right: 0;
          animation: showMobileMenu 0.2s ease-out forwards;
        }
      }
    }

    .mobile-hamburger {
      display: none;
      z-index: 1000;

      @include breakpoint-max(xs) {
        display: flex;
        flex-direction: column;
        justify-content: center;
        gap: 6px;
        align-items: center;
        height: 100%;
        box-sizing: content-box;
        width: 30px;
      }

      .mobile-hamburger-bar {
        width: 100%;
        height: 2px;
        border-radius: 5px;
        background-color: $mint;
      }
    }
  }
}

@keyframes showMobileMenu {
  from {
    transform: translateY(-300%);
    z-index: 999;
  }
  to {
    transform: translateY(0);
    z-index: 999;
  }
}
