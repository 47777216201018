@import '../../utils/colors';

.tableHeader {
  font-weight: bold;
  text-align: left;
  padding: 1em 0.8em !important;
}

.tableRow {
  padding: 1.5em 1em !important;
}

.buttonWrapper {
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.closeButton {
  width: 150px;
  padding: 10px 20px;
  font-size: 14px;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.42857143;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  letter-spacing: 0.5px;
  text-align: center;
  color: #333333;
  border-radius: 5px;
  background-color: #f0f0f0;
  border: 0;
  margin-right: 15px;
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.15);
}

.closeButton:hover {
  background-color: $gainsboro;
}

.join,
.see-resources {
  &:hover {
    opacity: 0.5;
  }
}
