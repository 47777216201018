@import '../utils/colors';
@import '../utils/prefixed';
@import '../utils/spacing';
@import '../utils/z-indexes';
@import '../utils/breakpoints';

$loader-size: 64px;
$menu-height: 60px;

%disabled-block {
  @include user-select(none);

  -webkit-touch-callout: none;
  pointer-events: none;
  position: relative;

  &:before {
    background-color: $grey-lightest;
    content: ' ';
    display: block;
    height: 100%;
    left: 0;
    opacity: 0.5;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: $z-index-disabled-overlay;
  }
}

%loader {
  animation: spin 1s infinite linear;
  background-image: url('/assets/icons/spinner.png');
  background-position: center;
  background-repeat: no-repeat;
  background-size: $loader-size;
  display: block;
  height: $loader-size;
  opacity: 0.25;
  width: 64px;
  margin: 0 auto;
}

.app {
  background-color: $color-background;
  width: 100%;
  min-width: $md;
  min-height: 100vh;

  @include breakpoint-max(sm) {
    min-height: unset;
    min-width: unset;
  }
}

.disabledBlock {
  @extend %disabled-block;
}

.inline {
  display: inline;
}

.loader {
  @extend %loader;
}

.loading,
.loadingModal {
  @extend %disabled-block;

  min-height: $loader-size;

  &:after {
    @extend %loader;

    bottom: 0;
    content: ' ';
    left: 0;
    margin: auto;
    position: absolute;
    right: 0;
    top: 0;
    width: $loader-size;
    z-index: $z-index-disabled-overlay + 1;
  }
}

.loadingModal:before {
  background-color: white;
  z-index: $z-index-modal + $z-index-disabled-overlay + 1;
}

.main {
  min-height: 80vh;
  min-width: $md;

  @include breakpoint-max(sm) {
    min-width: unset;
  }
}

.modalActions {
  display: flex;
  justify-content: center;
}

.resetPassTitle {
  margin-top: 0;
  padding-top: 40px;
  padding-bottom: 20px;
}

.resetPassCopy {
  margin-bottom: 20px;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.loaderCentralized {
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
  left: 0;
  right: 0;
}

.textContainer {
  margin-left: 25px;
  @include breakpoint-max(xs) {
    margin-left: 0px;
  }
}

.textContainer h1 {
  font-size: 30px;
  text-align: left;
  color: $white;
  font-weight: bold;
  margin-bottom: unset;

  @include breakpoint-max(xs) {
    font-size: 25px;
  }
}

.textContainer p {
  color: $white;
  font-size: 21px;
  margin-bottom: 3%;

  @include breakpoint-max(xs) {
    font-size: 18px;
  }
}

.textContainer a {
  color: $white;
  text-decoration: underline;
}

.textContainer span {
  color: $white;
  font-size: 21px;

  @include breakpoint-max(xs) {
    font-size: 18px;
  }
}

.closeButton {
  position: absolute;
  right: 20px;
  top: 15px;
  font-size: 23px;
  color: $white;
}

.pollContainer {
  display: flex;
  margin-top: 30px;
  margin-bottom: 25px;
}

.pollContainer p {
  width: 265px;
  text-align: center;
  color: white;
}

.pollNumbersContainer {
  display: flex;
}

.pollSelected {
  background-color: $dark-blue !important;
  color: $white;
  transform: scale(1.2);
  padding: 10px 12px;
  border: 1px solid $white;
  cursor: pointer;
  color: $white;
}

.pollNumbersContainer {
  margin-left: 65px;
  margin-right: 65px;
}

.pollNumber {
  background-color: $white;
  padding: 10px 12px;
  border: 0.5px solid $color-border;
  cursor: pointer;
  color: $grey-light-dark;
}

.pollNumber:hover {
  background-color: $grey-lightest;
  border: 0.5px solid $color-extra-darkener;
  color: $grey-darkest;
}

.pollNumber:last-child {
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}

.pollNumber:first-child {
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  border-left: 1px solid $color-border;
}

.subtitleContainer {
  width: 86.5%;
  display: flex;
  justify-content: space-between;
  margin-left: 37px;
  margin-top: 7px;
}

.subtitleContainer p {
  width: fit-content;
}

.submitRatingButton {
  height: fit-content;
  background-color: $grey-lightest;
}

.emptyNavbar {
  height: $menu-height;
  background-color: $background-light-grey;
}
