@import '../utils/breakpoints';

.wrapper {
  padding: 5vh 6vw;
  display: flex;
  flex-direction: column;
  gap: 8vh;

  @include breakpoint-max(sm) {
    gap: 5vh;
  }

  button {
    max-width: 20vw;

    @include breakpoint-max(sm) {
      max-width: unset;
    }
  }
}