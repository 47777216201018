@import '../../utils/colors';
@import '../../utils/breakpoints';

.locationSelector {
  margin-bottom: 15px;
}

.settingsContainer {
  display: flex;
}

.settingsContainer .checkbox {
  margin-top: unset;
}

.checkBoxContainer {
  text-align: center;
  margin-left: 40px;
  margin-right: 40px;
  margin-top: 20px;
}

.emailsContainer {
  margin-right: 45px;
}

.space {
  margin-top: 25px;
}

.whyLink {
  margin: 0 !important;
  padding: 0 !important;
  color: $grey-dark;
  cursor: pointer;
  text-decoration: underline;
}

.divider {
  height: 50px;
}

.checkboxWrapper {
  width: 30%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.disabledOption {
  color: $grey-light;
}

.meetMeInfo {
  width: 40%;
}

.meetMeInfo p {
  margin-bottom: 25px;
}

.tooltip {
  position: relative;
  text-decoration: underline;
  cursor: pointer;
}

.tooltip .tooltipText {
  visibility: hidden;
  background-color: $white;
  color: $grey-dark;
  text-align: left;
  border-radius: 6px;
  padding: 10px;
  width: 310px;
  position: absolute;
  top: 20px;
  left: 0;
  z-index: 10;

  @include breakpoint-max(xs) {
    width: 250px;
    margin-left: 0;
  }
}

.tooltipTextWrapper {
  /* Position the tooltip */
  position: absolute;
  top: 100%;
  left: 50%;
  z-index: 10;
  margin-left: -60px;
  margin-top: -10px;

  width: 310px;
  height: 20px;
}

.tooltipTextWrapper:hover .tooltipText {
  visibility: visible;
}

.tooltip:hover .tooltipText {
  visibility: visible;
}

.tooltip:active .tooltipText {
  visibility: visible;
}

.tooltip .tooltipText::after {
  content: ' ';
  position: absolute;
  bottom: 100%; /* At the top of the tooltip */
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent $grey-dark transparent;
}

.dropdownWrapper {
  margin-bottom: 25px;
}

.modalTitle {
  font-size: 26px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: $grey-dark;

  @include breakpoint-max(xs) {
    font-size: 24px;
  }
}

.modalSubtitle {
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: $light-gray;
  @include breakpoint-max(xs) {
    margin-top: 10px;
  }
}

.sendButton {
  width: 250px;
  height: 56px;
  border-radius: 4px;
  background-color: $grey-dark;
  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.5px;
  text-align: center;
  color: $white;
  border: 0;
  margin-left: 15px;
  margin-top: 20px;
  @include breakpoint-max(xs) {
    margin-left: 5px;
    height: 45px;
  }

  &:hover {
    background-color: $active-grey;
    color: $grey-dark;
  }
}

.modalContent {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.modalTextarea {
  margin-top: 20px;
  margin-bottom: 20px;
  padding: 10px;
  width: 100%;
  min-height: 150px;
}

.validUntil {
  display: inline-block;
  background: $white;
  border: 1px solid $gray85;
  box-sizing: border-box;
  border-radius: 4px;
  padding: 8px 20px;
  margin-left: 12px;
}
