@import '../utils/colors';

.wrapper {
  background-color: $white;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
  padding: 10vh 10vw;
  gap: 5vw;

  h1 {
    text-align: left;

    span {
      font-size: 1.5rem;
      display: block;
      margin-top: 0.5rem;
      font-weight: 400;

      a {
        font-size: 1em;
        color: $grey-dark;
        text-decoration: underline;

        &:hover {
          color: $mint;
        }
      }
    }
  }
}
