@import '../../../../style/utils/colors';
@import '../../../../style/utils/breakpoints';

@mixin animatedBackground($color) {    
    animation-duration: 1.25s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-name: loading;
    animation-timing-function: linear;
    background: $color;
    background: linear-gradient(to right, lighten($color, 8%) 8%, lighten($color,4%) 18%, lighten($color,8%) 33%);
    background-size: 800px 104px;
    position: relative;
}

.rowsWrapper {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;

    @include breakpoint-max(sm) {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: repeat(3, 5vh);
        align-items: center;

        & div {
            height: 60% !important;
        }

        & div:first-child {
            grid-row: 3 span;
            height: 88% !important;
        }
    }


    .loading {
        width: 95%;
        height: 15%;
        @include animatedBackground($smooth-peach);

        &:nth-child(1) {
            height: 30%;
        }

        &.salmon {
            @include animatedBackground($salmon);
        }

        &.gray {
            @include animatedBackground($grey-light-button);
        }
    }
}

@keyframes loading{
    0%{
        background-position: -468px 0
    }
    100%{
        background-position: 468px 0
    }
}
