@import '../../utils/breakpoints';
@import '../../utils/colors';

.tabName {
  font-size: 22px;
  line-height: 1.4em;
}

.underline {
  height: 9px;
  width: 105px;
  background-color: $smooth-peach;
}

.tabWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 5px;
  min-width: 150px;
  overflow-y: visible;

  @include breakpoint-max(xs) {
    margin: 0 auto;
  }
}

.tabWrapper:hover {
  cursor: pointer;
  opacity: .7;
}
