@import '../../../../style/utils/colors';
@import '../../../../style/utils/breakpoints';

$card-border-radius: 4px;
$card-padding: 1em 0.8em;

.cardWrapper {
  outline: none;
  border: none;
  width: 100%;
  border-radius: $card-border-radius;
  box-shadow: rgba(0, 0, 0, 0.1) 0px $card-border-radius 12px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: $white;

  &.disabled {
    .card-header {
      background-color: $grey-light-button;
      color: $button-hover-grey;

      .dots {
        display: none;
      }
    }
    &:hover {
      cursor: auto;
      box-shadow: rgba(0, 0, 0, 0.1) 0px $card-border-radius 12px;
    }

    .content {
      p {
        color: $grey-button-disabled-text;
      }
    }
  }

  &.clickless {
    &:hover {
      cursor: default;
      box-shadow: rgba(0, 0, 0, 0.1) 0px $card-border-radius 12px;
    }
  }

  .content {
    padding: $card-padding;
    width: 100%;
    height: 100%;
    position: relative;

    @include breakpoint-max(sm) {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  &:hover {
    box-shadow: rgba(0, 0, 0, 0.25) 0px 25px 50px -12px;
    cursor: pointer;

    .content {
      a {
        color: $link-hover;
      }
    }
  }

  .options-container {
    background-color: rgba(0, 0, 0, 0.6);
    position: absolute;
    border-radius: 0 0 4px 4px;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    inset: 0;
    padding: 4% 5%;
    gap: 8%;
    z-index: 2000;

    .buttonsWrapper {
      height: 100%;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      align-items: center;
      margin: 0 auto;

      button {
        height: 35%;
        white-space: nowrap;

        &:hover {
          opacity: 0.9;
        }

        @include breakpoint-max(sm) {
          height: 40%;
        }

        &:first-of-type {
          width: 42%;
        }

        &:nth-of-type(2) {
          width: 50%;
        }

        &:nth-of-type(3) {
          width: 50%;
        }
      }
    }
  }
}

.default {
  .card-header {
    background-color: $dark-mint;
  }
}

.black {
  .card-header {
    background-color: $grey-dark;
    color: $white;
  }
}
