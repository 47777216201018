@import '../../../../style/utils/colors';
@import '../../../../style/utils/breakpoints';

$logo-height: 26px;

.homeBtn {
  transition: opacity 0.2s;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 1000;

  &:hover {
    opacity: 0.5;
  }

  & a {
    height: $logo-height;
  }

  .logo {
    display: inline-block;
    background-image: url('/assets/logos/chatterbox-full-logo.svg');
    background-size: contain;
    background-repeat: no-repeat;
    height: $logo-height;
    width: 142px;
    margin: auto;
    image-rendering: crisp-edges;
  }

  &.logged-user {
    a {
      .logo {
        background-image: url('/assets/logos/chatterbox_logo_white.svg');

        @include breakpoint-max(xs) {
          background-image: url('/assets/logos/chatterbox-logo.png');
          filter: brightness(10);
          width: 30px;
        }
      }
    }
  }
}
