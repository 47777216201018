@import '../utils/breakpoints';
@import '../utils/colors';

$padding-left: 10%;
$size-elements-left: 170px;

.listWrapper{
  width: 100%;
  padding: 3% 10%;
  background-color: $grey-lightest;
}

.loader {
  width: 50%;
}

.pageWrapper{
  background-color: $white;
}

.pageWrapper h1 {
  margin-top: 0;
  padding-top: 3%;
}

.filterButton {
  margin-right: 10px;
  border: 1px solid $black !important;
}

.filterWrapper {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding-bottom: 3%;
  @include breakpoint-max(xs) {
    flex-wrap: wrap;
  }
}

.timetableWrapper {
  position: absolute;
  box-shadow: 0px 2px 0px 0px $color-shadow;
  z-index: 10;
  top: 74px;
  left: -85px;
}

.timetableWrapper div {
  margin: unset !important;
}

.header {
  padding: 1% 10%;
}

.header h1 {
  text-align: left;
}

.dropdownWrapper {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin-right: 2%;
  position: relative;
  @include breakpoint-max(xs) {
    margin-bottom: 5%;
  }
}

.dropdownLabel {
  font-size: 1.2em;
}

.fakeDropdown {
  box-shadow: 0 0px 0px $color-shadow;
  transition: 0.1s box-shadow linear;
  padding: 10px 20px;
  color: $grey-dark;
  background-color: $grey-light-medium-dark;
  border-color: $grey-light-dark;
  margin-right: 10px;
  border: 1px solid black !important;
  border-radius: 5px !important;
}
