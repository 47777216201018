@import '../../../style/utils/colors';

.wrapper {
  min-width: 35px;
  height: 100%;
  border: 1px solid $mint;
  box-sizing: border-box;
  border-radius: 3px;
  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
    background-color: $mint;
    cursor: pointer;
    opacity: 0.8;

    .attachButton {
      background-color: $mint;

      svg {
        animation: ring 0.5s ease-in-out 1 forwards;
      }
    }
  }

  .attachButton {
    width: 100%;
    height: 100%;
    padding: 5px;
    display: flex;
    justify-content: center;
    align-items: center;

    svg {
      width: 75%;
      height: 75%;
    }
  }
}

@keyframes ring {
  0% {
    transform: translateX(-10%);
  }
  20% {
    transform: translateX(10%);
  }
  40% {
    transform: translateX(-10%);
  }
  60% {
    transform: translateX(10%);
  }
  80% {
    transform: translateX(-10%);
  }
  100% {
    transform: translateX(0);
  }
}
