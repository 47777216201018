@import './style/utils/_colors.scss';
@import './style/utils/_breakpoints.scss';
// CSS Reset
/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video,
input,
button,
label {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 16px;
  font-family: Karla, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  vertical-align: baseline;
  text-decoration: none;
  box-sizing: border-box;

  @include breakpoint-max(sm) {
    font-size: 14px;
  }
}
/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}
body {
  line-height: 1;
}
ol,
ul {
  list-style: none;
}
blockquote,
q {
  quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: '';
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}

body,
html {
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
  background-color: $color-footer;
}

hr {
  border-color: $color-border;
  border-width: 2px;
  width: 75%;
}

h1 {
  font-size: 2.5em;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: $grey-dark;
  margin-top: 3%;
  margin-bottom: 3%;
  text-align: center;

  span {
    font-size: 1em;
  }

  @include breakpoint-max(sm) {
    font-size: 1.75em;
  }
}

h2 {
  font-size: 1.375em;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.45;
  letter-spacing: normal;
  color: $grey-dark;

  span {
    font-size: 1em;
  }
}

p {
  font-size: 0.9em;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.38;
  letter-spacing: normal;
  color: $grey-dark;

  span {
    font-size: 1em;
  }
}

button {
  background-color: transparent;
  outline: none;
  border: none;
  &:hover {
    cursor: pointer;
  }
}

.Toastify {
  &__toast-container {
    .Toastify__toast {
      padding: 0.5em 1em !important;

      &-body {
        margin: 0;
        line-height: 1.4em;
        padding: 0.5em 1em;
      }
    }
  }
}

.Toastify .Toastify__close-button {
  font-weight: normal;

  &--error {
    color: $red-dark;
  }

  &--info {
    color: $dark-blue;
  }

  &--success {
    color: $dark-mint;
  }
}

.Toastify .Toastify__toast {
  padding: 16px;

  &--error {
    background-color: $red-light;
    color: $red-dark;
  }

  &--info {
    background-color: $blue-light;
    color: $dark-blue;
  }

  &--success {
    background-color: $soft-mint;
    color: $dark-green;
  }
}

.weglot-container {
  * {
    display: none !important;
  }
}
