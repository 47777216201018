@import '../../style/utils/colors';
@import '../../style/utils/breakpoints';

.modal-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  max-width: 60vw;

  @include breakpoint-max(sm) {
    max-width: 85vw;
  }

  .close-button {
    font-weight: 700;
    position: absolute;
    padding: 5px 8px;
    border-radius: 50%;
    right: 0;
    top: 0;
    transform: translate(20px, -10px);

    &:hover {
      opacity: 0.8;
      background-color: $grey-light-medium-dark;
    }
  }

  .modal-title {
    font-weight: 700;
    font-size: 1.5em;
    margin-bottom: 0.2em;
  }

  .modal-subtitle {
    font-size: 1em;
  }

  .buttons-wrapper {
    display: flex;
    justify-content: center;
    gap: 2em;
    margin: 2em 0 1em;
    flex-wrap: wrap;
    button {
      padding: 0.5em 1em;
      background-color: $button-light-grey;
      border-radius: 5px;

      &:hover {
        background-color: $grey-light-medium-dark;
      }

      img {
        width: 40px;
        height: 40px;
        position: relative;
        transform: translateY(-4px);
      }
    }

    .thumb-down {
      transform: rotate(180deg);
    }
  }

  .choices-wrapper {
    display: flex;
    width: 80%;
    gap: 1em;
    justify-content: center;
    flex-wrap: wrap;
    margin: 2em 0;

    button {
      padding: 0.5em 1em;
      background-color: $button-light-grey;
      border-radius: 5px;

      &:hover {
        background-color: $grey-light-medium-dark;
      }
    }

    .choice {
      border-radius: 50px;
      padding: 1.2em 1.5em;
    }

    .selected {
      background-color: $mint;
    }
  }

  .textarea {
    width: 65%;
    min-height: 10vh;
    font-size: 1em;
    padding: 0.8em 1.2em;
    border-color: $grey-light-medium-dark;
    border-radius: 5px;

    @include breakpoint-max(sm) {
      width: 85%;
    }
  }

  .modal-buttons-wrapper {
    display: flex;
    justify-content: space-between;
    width: 70%;
    gap: 20%;
    margin: 4em 0 1em;
  }
}
