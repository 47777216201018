@import '../../utils/colors';

.input__insertion {
  width: 100%;
  position: relative;

  .input__wrapper {
    width: 100%;
    border-radius: 5px;
    display: flex;
    align-items: stretch;
    overflow-x: auto;

    input {
      padding: 2%;
      border: 1px solid $border-grey;
      border-radius: 5px;
      width: 100%;
      height: 3.5em;
      outline: none;

      &:focus {
        border: 1px solid $grey-dark;
      }

      &:disabled {
        background-color: white;
      }
    }

    .span--special {
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      box-sizing: content-box;
      border-radius: 20px !important;
      background-color: $medium-mint;
      border: none !important;
      box-shadow: none !important;
      padding: 1% 2%;
      margin-right: 2%;

      button {
        background: transparent;
        border: none;
        white-space: nowrap;
        cursor: initial;
      }

      &__remove {
        transform: translateY(-10%);
        margin-left: 0.3em;
      }

      &__remove:hover {
        cursor: pointer;
      }
    }
  }

  p {
    font-style: italic;
    font-size: 0.9em;
    margin-top: 0.4em;
  }
}

.autocompleteInput {
  border: none;
  border-radius: 5px;
  width: 100%;
  height: 100%;
  outline: none;
  height: 2.5em;
}

.autocompleteContainer {
  width: 100%;
}

.autocompleteSuggestionsContainer {
  text-align: center;
  position: absolute;
  left: 0;
  right: 0;
  z-index: 200;
  background-color: $white;
  max-height: 20vh;
  overflow-y: scroll;
}
