@import '../../utils/z-indexes';

.closeButton {
  position: absolute;
  right: 5px;
  top: 5px;
  z-index: $z-index-modal;
  font-size: 20px;

  &:hover {
    cursor: pointer;
  }
}

.container {
  padding: 32px 8vw;
  position: relative;
}
