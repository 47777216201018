@import '../../style/utils/colors';
@import '../../style/utils/breakpoints';

.aside-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  border-radius: 8px;
  padding: 1.5rem 2rem;
  background-color: $grey-dark;
  transition: all 0.2s ease;

  &:hover {
    background-color: $button-hover-grey;
    cursor: pointer;
  }

  .avatar-and-name {
    display: flex;
    align-items: center;
    gap: 1rem;
  }

  .name {
    h1 {
      font-size: 1.5rem;
      font-weight: 500;
      margin: 0 0 0.25rem;
      text-align: left;
      color: $white;
    }
    h2 {
      font-size: 0.9rem;
      font-weight: 400;
      letter-spacing: 0.5px;
      margin: 0;
      text-align: left;
      color: $white;
    }
  }

  .edit-profile {
    color: $white;
    text-decoration: underline;
  }

  .personal-data {
    margin-top: 4vh;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 1vh;

    @include breakpoint-max(sm) {
      margin-top: 2vh;
      flex-direction: row;
      flex-wrap: wrap;
    }

    .data {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 0.5rem;

      svg {
        width: 20px;
        height: 20px;
        fill: $mint;

        path {
          fill: $mint;
        }
      }
      p {
        font-size: 1rem;
        color: $white;
      }
    }
  }
}
