@import '../../utils/colors';
@import '../../utils/elevation';
@import '../../utils/spacing';

$padding: 20px;

.buttons {
  display: flex;
  justify-content: center;
  position: absolute;
  bottom: 2vh;
  left: 50%;
  transform: translateX(-50%);
}

.card {
  @include elevation(1);
  position: relative;
  padding-bottom: 10vh;

  background-color: $white;
  margin: 0 auto 40px;
}

.content {
  > p {
    margin-bottom: 0.67 * $padding;

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.header {
  background-color: $mint;
  color: black;
  margin: 0;
  padding: $padding;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.main {
  height: 100%;
  margin: 0;
  padding: $padding;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 5vh;
}

.title {
  font-size: 22px;
  margin: 0;
  font-weight: bold;
}
