@import '../../../style/utils/colors';

.wrapper {
  width: 70px;
  height: 70px;
  border-radius: 50%;
  background-color: $white-smoke;
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: 50% 50%;
    border-radius: 50%;
  }

  .initials {
    font-size: 2rem;
    font-weight: 700;
    color: $white-smoke;
  }
}
