@import '../../../../style/utils/colors';

.errorWrapper {
  width: 100%;
  background-color: $background-red;
  padding: 0.8em 1.2em;
  border-radius: 5px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 0.4em;

  p {
    color: $red;
    font-size: 0.9em;
  }

  button {
    margin: 0 !important;
    color: $red;
    border-radius: 50px;
    padding: 2px 10px;

    &:hover {
      color: white;
      background-color: $red;
    }
  }
}