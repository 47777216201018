@import '../../../style/utils/breakpoints';

.tabsContainer {
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-around;
  padding: 1.5vh 0 3vh;
}

.loadingContainer {
  width: 30px;
  height: 30px;
  margin: 2vh 0;
}
