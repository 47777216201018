@import '../../utils/elevation';
@import '../../utils/breakpoints';
@import '../../utils/colors';

.modal {
  @include elevation(24);

  background-color: $white;
  margin: 50px auto;
  max-width: 480px;
  border-radius: 4px;

  &:focus {
    outline: 0;
  }
}

.modal {
  @include elevation(24);

  border-radius: 4px;
  background-color: $white;
  max-width: 796px;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  margin: auto auto;
  height: fit-content;

  &:focus {
    outline: 0;
  }

  @include breakpoint-max(md) {
    width: 85%
  }
}

.modal h2 {
  margin-top: 0px;
}

.extraInfo {
  color: $grey;
  display: block;
  margin-top: 18px;
}

.logo {
  width: 167px;
  margin-bottom: 21px;
}

.modal p {
  @include breakpoint-max(md){
    margin-bottom: 15px;
  }
}