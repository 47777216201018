@import '../../../style/utils/colors';

.wrapper {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 1rem;
  padding: 0.75rem 1rem;
  border-radius: 5px;
  width: max-content;

  .iconWrapper {
    width: 2rem;
    height: 2rem;
    border-radius: 100px;
    display: flex;
    justify-content: center;
    align-items: center;

    svg {
      width: 1rem;
      height: 1rem;
    }
  }

  .content {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 1rem;
    .text {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: 0.25rem;
      h1 {
        font-size: 1rem;
        margin: 0;
      }
      p {
        font-size: 1rem;
      }
    }

    button {
      font-size: 0.875rem;
      font-weight: bold;
      position: relative;
      border: 1px solid $mint;
      padding: 5px 7.5px;
      border-radius: 5px;

      &:hover {
        &:before {
          content: '';
          top: 0;
          left: 0;
          bottom: 0;
          right: 0;
          inset: 0;
          position: absolute;
        }
      }
    }
  }

  .close {
    margin-left: 1rem;
  }
}

.success {
  background-color: $background-extra-soft-mint;
  .iconWrapper {
    background-color: $mint;
  }
  .content {
    button {
      &:hover {
        &:before {
          background-color: $mint;
          opacity: 0.3;
        }
      }
    }
  }
}

.danger {
  background-color: $background-red;
  .iconWrapper {
    background-color: $red;
  }
  .content {
    button {
      &:hover {
        &:before {
          background-color: $red;
          opacity: 0.3;
        }
      }
    }
  }
}
