@import '../../../../style/utils/colors';
@import '../../../../style/utils/breakpoints';

.selector-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 3vh;
  padding: 3vw;
  border-radius: 5px;
  background-color: $white;
  width: 100%;
  height: 100%;

  @include breakpoint-max(sm) {
    flex-direction: row;
    justify-content: flex-start;
    padding: 6vw;
    gap: 2vh;
  }

  img {
    width: 40%;

    @include breakpoint-max(sm) {
      width: 12%;
    }
  }

  p {
    font-size: 1em;
    color: $grey-dark;
  }

  &:hover,
  &:active {
    cursor: pointer;
    background-color: $mint;
    border: none;
  }

  &:disabled {
    &:hover {
      cursor: auto;
    }
  }

  .loading-container {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    div {
      width: 30px;
      height: 30px;
    }
  }
}
