@import '../utils/breakpoints';
@import '../utils/colors';

.cardContainer * {
    font-family: Open Sans, sans-serif;
    font-size: 16px;
    font-weight: 500;
}
.modalArea {
    z-index: 10;
    position: fixed;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: $color-mid-darkener;
    z-index: 250;
}
.cardContainer {
    position: absolute;
    background-color: $white;
    top: 9vh;
    bottom: unset;
    left: 0;
    right: 0;
    margin: auto auto;
    width: 450px;
    height: fit-content;
    z-index: 251;
    border-radius: 5px;

    @include breakpoint-max(xs) {
        width: 90%;
    }
}
.contentContainer{
    padding: 25px;
}

.contentContainer p {
    font-family: Karla;
    font-weight: bold;
}
.contentContainer button {
    display: block;
    width: 100%;
    height: 37px;
    background-color: $mint;
    border-radius: 5px;
    font-weight: 600;
    color: black;
    cursor: pointer;
    border: none;
    font-family: Karla;
}
.contentContainer img {
    width: 178px;
    height: 40px;
    margin: 0 auto 15px auto;
    display: block;
}
.contentContainer form {
    max-width: 425px!important;
    position: relative;
    margin-top: 20px;
}
.contentContainer label {
    color: black;
    font-weight: bold;
    font-size: 16px;
    font-family: karla;
    width: 425px!important;

    @include breakpoint-max(sm) {
        width: 100%;
    }
}
.titleContainer {
    background-color: $grey-lightest;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 75px;
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;
    padding: 15px 25px;
}
.titleContainer h2 {
    font-weight: bold;
    display: inline-block;
    font-size: 24px;
    margin: unset;
    font-family: Karla;

    @include breakpoint-max(xs) {
        font-size: 20px;
    }
}
.cost {
    font-size: 24px;
}
.StripeElement {
    width: 100%;
    display: block;
    margin: 10px 0 20px 0;
    min-width: 200px;
    padding: 10px 14px;
    font-size: 1em;
    font-family: Karla;
    box-shadow: $color-darkener 0px 1px 3px, $color-darkener 0px 1px 0px;
    border: 0;
    outline: 0;
    border-radius: 4px;
    background: white;
    border: 1px solid black;
}
.__PrivateStripeElement {
    margin: 0px !important;
    padding: 0px !important;
    border: none !important;
    display: block !important;
    background: transparent !important;
    position: relative !important;
    opacity: 1 !important;
}
.error {
    color: $red;
    margin: 5px 0;
}
