@import '../../../../style/utils/colors';

.tabWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  &:hover {
    cursor: pointer;
  }

  .tabName {
    text-transform: uppercase;
    position: relative;

    &.selected {
      &:after {
        content: ' ';
        position: absolute;
        bottom: -10px;
        left: -2px;
        right: -2px;
        height: 6px;
        background-color: $mint;
      }
    }
  }
}