@import '../../../../style/utils/colors';
@import '../../../../style/utils/breakpoints';

@mixin animatedBackground($color) {    
    animation-duration: 1.25s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-name: loading;
    animation-timing-function: linear;
    background: $color;
    background: linear-gradient(to right, lighten($color, 8%) 8%, lighten($color,4%) 18%, lighten($color,8%) 33%);
    background-size: 800px 104px;
    position: relative;
}

.rowsWrapper {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap: 1em;

    @include breakpoint-max(sm) {
        height: 40vh;

        & div {
            height: 70% !important;
        }

        & div:first-child {
            height: 88% !important;
            margin-bottom: 3vh;
        }
    }


    .loading {
        width: 95%;
        height: 1.5em;
        border-radius: 5px;
        @include animatedBackground($smooth-peach);

        &:nth-child(1) {
          width: 50%;
        }

        &:nth-child(2) {
          width: 60%;
          margin-bottom: 1em;
        }

        &.salmon {
            @include animatedBackground($salmon);
        }

        &.gray {
            @include animatedBackground($grey-light-button);
        }
    }
}

@keyframes loading{
    0%{
        background-position: -468px 0
    }
    100%{
        background-position: 468px 0
    }
}
