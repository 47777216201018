@import '../../style/utils/colors';
@import '../../style/utils/breakpoints';

.container {
  margin: 2rem 4vw;
  display: flex;
  justify-content: flex-start;
  gap: 1rem;
  overflow-x: hidden;
  position: relative;

  @include breakpoint-max(sm) {
    flex-direction: column;
    gap: 2vh;
  }

  .aside {
    width: fit-content;
    display: flex;
    flex-direction: column;
    gap: 0;
    background-color: $white;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
    border-radius: 8px;

    @include breakpoint-max(sm) {
      width: 100%;
      min-width: unset;
    }
  }

  .content {
    width: 100%;
    min-width: 600px;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
    border-radius: 8px;
    padding: 1.5rem 2rem;
    background-color: $white;

    @include breakpoint-max(sm) {
      width: 100%;
      min-width: unset;
    }

    h1,
    h2 {
      text-align: left;
      margin: 0 0 0.5rem;
    }

    h1 {
      font-size: 1.5rem;
      font-weight: 700;
    }

    h2 {
      margin-bottom: 2vh;
      font-size: 1.1rem;
      font-weight: 300;

      span {
        position: relative;
        &::after {
          content: ' ';
          position: absolute;
          height: 3px;
          left: 0;
          right: 0;
          bottom: -3px;
          background-color: $mint;
        }
      }
    }
  }
}
