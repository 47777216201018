@import '../../../../../style/utils/colors';
@import '../../../../../style/utils/breakpoints';

.form-wrapper {
  width: 35%;
  margin: 0 auto 1em;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 2.5vh;

  @include breakpoint-max(sm) {
    width: 100%;
  }

  p {
    font-size: 0.85em;
    text-align: center;

    a {
      font-size: 1em;
      &:hover {
        text-decoration: underline;
        font-weight: 500;
      }
    }
  }

  button {
    margin-top: 4vh;
  }

  .text-button {
    max-width: unset;
    height: unset;
    font-weight: 400;
    margin-top: 2vh;
    &:hover {
      cursor: pointer;
      color: $link-hover;
      text-decoration: underline;
    }
  }

  .link {
    color: $link-default;

    &:hover {
      cursor: pointer;
      color: $link-hover;
      text-decoration: underline;
    }
  }
}
