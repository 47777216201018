.photo {
  background-size: cover;
  background-position: center;
  margin: auto;
  width: 100%;
  padding-bottom: 100%;
  min-width: 80px;
  min-height: 80px;
  border-radius: 50%;
}


@media (max-width: 600px) {
  .photo {
    width: auto;
    height: 25%;
    border-radius: 50%;
  }
}
