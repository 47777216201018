@import '../../../style/utils/colors';
@import '../../../style/utils/breakpoints';

.bookings-wrapper {
  width: 100%;
  max-width: 1280px;
  margin: 0 auto;

  .loading-wrapper {
    width: 30vw;
    height: 2rem;
    margin: 0 0 1rem;
  }

  .bookings-title {
    margin: 0 0 1rem;
    font-size: 1.5rem;
    font-weight: 700;
    width: 100%;
    text-align: left;

    span {
      position: relative;
      z-index: 10;
      &:before {
        content: ' ';
        position: absolute;
        bottom: -0.2rem;
        left: 0;
        height: 0.3rem;
        background-color: $mint;
        z-index: -1;
        animation: show-underline 0.8s ease-out 1s forwards;
      }
    }

    @include breakpoint-max(sm) {
      font-size: 1.2rem;
    }
  }

  .cards-wrapper {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    gap: 3vw;

    @include breakpoint-max(sm) {
      flex-direction: column;
      flex-wrap: wrap;
    }
  }

  .links-wrapper {
    display: flex;
    justify-content: space-between;
    position: absolute;
    bottom: 2vw;
    left: 12vw;
    right: 12vw;
    max-width: 1280px;
    margin: 0 auto;

    @include breakpoint-max(sm) {
      display: none;
    }

    .link {
      a {
        font-size: 0.875rem;
        text-decoration: underline;
        color: $grey-dark;
      }

      &:hover {
        opacity: 0.5;
      }
    }
  }
}

@keyframes show-underline {
  from {
    right: 100%;
  }
  to {
    right: 0;
  }
}
