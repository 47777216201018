@import '../../../style/utils/breakpoints';

.translateButtonWrapper {
  display: flex;
  align-items: center;
  gap: 0.5em;
  justify-content: flex-end;
  margin-top: 1em;

  @include breakpoint-max(xs) {
    flex-direction: column;
  }

  p, button {
    font-size: 0.875rem;
  }
}
