@import '../utils/colors';

.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 5vh 5vw 10vh;
}

.instruction {
  font-size: 1.2em;
  margin-bottom: 0.5em;
}

.marginBottom {
  margin-bottom: 50px;
}

.tableContainer {
  background-color: $white;
  border-radius: 4px;
}

.tableHeading {
  text-align: center;
  margin-bottom: 0.5em;
  flex-direction: row;
  display: flex;
  align-items: center;
  justify-content: center;
}

.loader {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  margin: auto;
}

.loaderContainer {
  width: 200px;
  height: 50vh;
  position: relative;
  margin: auto;
}

.loadMoreButtonContainer {
  width: 131px;
  height: 30px;
  margin-left: 10px;
}
