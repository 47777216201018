.container{
  display: flex;
  flex-direction: column;
}

.priceRow {
  margin-top: 10px;
  margin-bottom: 10px;
}

.priceCell{
  text-align: right;
}

.title{
  margin-bottom: 25px;
  text-align: center;
}

.radioButton{
  padding: 0;
  margin: 0;
}

.productName{
  font-size: 85%;
}
