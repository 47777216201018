@import '../../style/utils/breakpoints';
@import '../../style/utils/colors';

$chat-height: 75vh;
$chat-padding: 20px;
$input-height: 52px;
$header-height: 55px;
$total-border: 2px;
$input-and-header: $input-height + $header-height + $total-border;

.chatPageWrapper {
  width: 100%;
  height: 100%;
  padding: 1vw 2vw;
  box-sizing: border-box;

  .mainContent {
    position: relative;

    .conversations {
      width: 100%;
      height: 80vh;
    }

    .messages {
      position: relative;
      padding-top: 1rem;
      .backButton {
        z-index: 1000;
        position: absolute;
        top: 0;
        left: 0;
      }
      width: 100%;
      height: 82vh;
    }
  }
}

.chatButtonsWrapper {
  display: flex;
}

.translateButton {
  margin-left: 0px;
  padding-left: 5px !important;
  text-decoration: underline;
  background-color: transparent !important;
  box-shadow: none;
  border: none;
  color: $link-default !important;
  &:hover {
    color: $link-hover !important;
    cursor: pointer;
  }

  &:active {
    color: $link-active !important;
  }
}

.chatListContainer {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  height: fit-content !important;
  width: 100%;
  height: $chat-height;
  padding: $chat-padding;
  margin-bottom: 0;
  padding-bottom: $chat-padding/2;

  min-height: 75vh !important;

  @include breakpoint-max(xs) {
    padding-top: unset;
  }

  @include breakpoint-max(md) {
    padding: 0;
    height: 81vh;
  }
}

.channelListContainer {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  height: fit-content;
  overflow-x: hidden;
  border: 1px solid $grey-lightest;
  width: 30%;
  min-height: 75vh;
  max-height: 75vh;
  overflow-y: scroll;
  background-color: $white;

  @include breakpoint-max(md) {
    width: 55%;
    min-width: unset;
  }

  @include breakpoint-max(xs) {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
  }
}

.chatNav {
  background-color: $white;
  padding-bottom: 5px;
  display: flex;
  flex-direction: row;
  height: 50px;
  display: flex;
  justify-content: space-around;
}

.navButton {
  background-color: unset !important;
  color: $black !important;
  font-size: 16px !important;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}

.navButton span {
  @include breakpoint-max(md) {
    font-size: 13px;
  }
}

.selectedNavButton {
  border-bottom: $blue 6px solid !important;
  border-radius: unset !important;
}

.channelListContainer > div {
  width: 100%;
  background-color: $white;

  @include breakpoint-max(md) {
    width: 96%;
    min-width: unset;
  }

  @include breakpoint-max(xs) {
    width: 100%;
  }
}

.messageListContainer {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  height: 100%;
  padding: 0;
  flex-grow: 1;
  border: 1px solid $grey-lightest;
  border-left: 0;
  width: 70%;
  min-height: 75vh;

  @include breakpoint-max(xs) {
    width: 100%;
    height: unset;
    position: absolute;
    left: 0;
    padding: 18px;
    background-color: $white;
  }
}

.messageList {
  background-color: $white;
  width: 100%;
  border-bottom: 1px solid $grey-lightest;
  flex-grow: 1;
  max-height: calc(#{$chat-height} - #{$input-and-header});
  //
  // @include breakpoint-max(md) {
  //   max-height: calc(#{$chat-height} - #{$input-and-header} + 50px);
  // }
}

.messageList img {
  max-width: 100%;
}

.messageBox div,
.messageBoxCurrentUser div {
  border-radius: 5px !important;
  box-shadow: none;
}

.messageBox > div {
  background-color: $grey-lightest !important;
  white-space: pre-line;
}

.messageAuthor {
  &:hover {
    color: blue;
    text-decoration: underline;
  }
}

.messageBoxCurrentUser div {
  white-space: pre-line;
}

.messageBoxCurrentUser div:hover,
.messageBox div:hover {
  text-decoration: none !important;
  cursor: default;
}

.messageLoaderContainer,
.channelLoaderContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $white;
  flex-grow: 1;
}

.messageLoaderContainer {
  width: 100%;
  border-bottom: 1px solid $grey-lightest;

  @include breakpoint-max(xs) {
    height: 59vh;
  }
}

.channelLoaderContainer {
  padding: 0 3vw;
}

.channelLoader,
.messageLoader {
  width: 64px;
}

.iconLoader {
  background-size: 14px !important;
  width: 14px !important;
  height: 20px !important;
}

.navBar {
  font-size: 22px;
  background-color: $white !important;
  width: 100%;
  border-bottom: 1px solid $grey-lightest;
  height: $header-height;
}

.chatButton {
  border: 1px solid $grey-dark !important;
  background-color: $white !important;
}

.buttonIcon {
  height: 14px;
  width: auto;
}

.systemMessage,
.systemMessage div {
  background-color: $white;
  border-radius: 0;
}

.systemMessage div {
  color: $grey;
  box-shadow: none;
}

.channelBoxSelected div {
  background-color: $grey-lightest !important;
}

.channelBox span {
  background-color: $mint !important;
}

.channelBoxUnread {
  border-left: 5px solid $mint;
  font-weight: bold;
}

.nonAvailableMessage {
  padding: 20px;
  text-align: center;
  width: 100%;
}

.unactiveInput {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: $input-height;
}

.unactiveInput p {
  margin: 0;
}

.dropzone {
  padding: 10px;
  border-radius: 5px;
  padding-left: 12px;
  padding-right: 12px;
  margin-right: 5px;

  &:hover {
    cursor: pointer;
    opacity: 0.7;
  }
}

.backGlyph {
  position: absolute;
  left: 8px;
  top: 94px;
  font-size: 16px;
  z-index: 10;
}

.languageHelperTag,
.channelBoxSelected .languageHelperTag {
  background-color: $soft-yellow !important;
  margin: 0;
  padding: 0;
  width: auto;
  position: absolute;
  top: 10px;
  left: 5px;
  font-size: 8px;
  z-index: 1;
}

.channelBoxUnread .languageHelperTag {
  font-weight: normal;
}

.channelBoxUnread > div {
  margin-left: -5px;
}

.archivedTag {
  background-color: $grey-light !important;
  color: $grey-darkest;
  margin: 0;
  padding: 0;
  width: auto;
  position: absolute;
  top: 10px;
  left: 4px;
  font-size: 10px;
  z-index: 1;
}

.noChannelsMsg {
  padding: 20px;
  height: calc(75vh - 97px);
}
