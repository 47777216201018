@import '../../../style/utils/colors';
@import '../../../style/utils/breakpoints';

.button {
  background-color: $grey-dark;
  border-radius: 4px;
  border: none;
  color: $white;
  width: 100%;
  height: 100%;
  font-size: 1rem;
  font-weight: 500;
  letter-spacing: 0.5px;
  transition: all 0.2s ease;
  padding: 1rem 1.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;

  * {
    color: $grey-dark;
    fill: $grey-dark;
  }

  svg {
    width: 1.25rem;
    height: 1.25rem;
  }

  &.loading {
    div {
      width: 20px;
      height: 20px;
    }
  }

  &:disabled {
    background-color: $grey-light-button;
    &:hover {
      cursor: initial;
    }
  }

  &:hover {
    cursor: pointer;
  }

  &:disabled {
    background-color: $grey-button-disabled;
    color: $grey-button-disabled-text;

    &:hover {
      cursor: default;
      background-color: $grey-button-disabled !important;
      color: $grey-button-disabled-text !important;
    }
  }

  &.nowrap {
    white-space: nowrap;
  }

  @include breakpoint-max(xs) {
    font-size: 0.9rem;
    padding: 0.5rem 1rem;
  }
}

.mainButton {
  font-size: 16px;
  font-weight: 600;
  @include breakpoint-max(xs) {
    font-size: 0.9em;
  }

  &:hover {
    background-color: $button-hover-grey;
  }

  svg {
    fill: $white;

    path {
      fill: $white;
    }
  }
}

.roundedMainButton {
  @extend .mainButton;
  padding: 1rem;
  border: 0;
  border-radius: 100px;
}

.secondaryButton {
  background-color: $button-light-grey;
  color: $grey-dark;

  &:hover {
    background-color: $grey-light-medium-dark;
  }
}

.thirdButton {
  background-color: $button-light-grey;
  color: $medium-pink;

  &:hover {
    background-color: $grey-light-medium-dark;
  }
}

.regularButton {
  &:hover {
    background-color: $button-hover-grey;
  }

  &:disabled {
    background-color: $grey-button-disabled;
    color: $grey-button-disabled-text;

    &:hover {
      cursor: default;
      background-color: $grey-button-disabled !important;
      color: $grey-button-disabled-text !important;
    }
  }
}

.greenButton {
  background-color: $mint;
  color: $grey-dark;
  white-space: nowrap;

  &:hover {
    opacity: 0.8;
  }
}

.greenButtonWithoutPadding {
  background-color: $mint;
  color: $grey-dark;
  white-space: nowrap;
  padding: 0.5rem;

  &:hover {
    opacity: 0.8;
  }
}

.greenButtonWithoutVisualDisable {
  @extend .greenButton;

  &:disabled {
    background-color: $mint;
    color: $grey-dark;

    &:hover {
      opacity: 1;
      cursor: default;
      background-color: $mint !important;
      color: $grey-dark !important;
    }
  }
}

.greenOpaqueButton {
  background-color: $mint-opaque;
  color: $grey-dark;
  white-space: nowrap;
  &:hover {
    background-color: $dark-mint;
  }
}

.salmonButton {
  background-color: $tag-salmon;
  color: $grey-dark;
  white-space: nowrap;
  &:hover {
    background-color: $salmon;
  }
}

.whiteButton {
  background-color: $white;
  color: $grey-dark;
  white-space: nowrap;
  &:hover {
    background-color: $grey-light-medium-dark;
  }

  &:disabled {
    background-color: $grey-button-disabled;
    color: $grey-button-disabled-text;

    &:hover {
      cursor: default;
      background-color: $grey-button-disabled !important;
      color: $grey-button-disabled-text !important;
    }
  }
}

.lightGrayButton {
  background-color: $whisper;
  color: $grey-dark;
  &:hover {
    background-color: $gray85;
  }

  &:disabled {
    background-color: $grey-button-disabled;
    color: $grey-button-disabled-text;

    &:hover {
      cursor: default;
      background-color: $grey-button-disabled !important;
      color: $grey-button-disabled-text !important;
    }
  }
}

.lightGrayButtonWithoutVisualDisable {
  @extend .lightGrayButton;

  &:disabled {
    background-color: $whisper;
    color: $grey-dark;

    &:hover {
      cursor: default;
      background-color: $whisper !important;
      color: $grey-dark !important;
    }
  }
}

.transparentButton {
  background-color: transparent;
  border: 1px solid $grey-dark;
  color: $grey-dark;

  &:hover {
    background-color: $button-light-grey;
    opacity: 0.8;
  }

  &:disabled {
    background-color: $grey-button-disabled;
    color: $grey-button-disabled-text;

    &:hover {
      cursor: default;
      background-color: $grey-button-disabled !important;
      color: $grey-button-disabled-text !important;
    }
  }
}

.roundedTransparentButton {
  @extend .transparentButton;
  padding: 1rem;
  border: 0;
  border-radius: 100px;
}

.cancelButton {
  background-color: $medium-pink;
  color: $white;

  &:hover {
    background-color: $pink;
  }

  &:disabled {
    background-color: $grey-button-disabled;
    color: $grey-button-disabled-text;

    &:hover {
      cursor: default;
      background-color: $grey-button-disabled !important;
      color: $grey-button-disabled-text !important;
    }
  }
}

.smallButton {
  font-size: 1em;

  &:hover {
    background-color: $button-hover-grey;
  }

  &:disabled {
    background-color: $grey-button-disabled;
    color: $grey-button-disabled-text;

    &:hover {
      cursor: default;
      background-color: $grey-button-disabled !important;
      color: $grey-button-disabled-text !important;
    }
  }

  @include breakpoint-max(xs) {
    font-size: 0.6em;
  }
}

.link {
  background-color: transparent;
  font-size: 1em;
  text-decoration: underline !important;
  color: $link-default !important;
  padding: 0;
  width: fit-content;

  &:hover {
    color: $link-hover !important;
  }

  &:active {
    color: $link-active !important;
  }
}

.bold {
  font-weight: 700;
}
