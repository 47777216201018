.wrapper {
  width: 100vw;
  height: 70vh;
  display: flex;
  justify-content: center;
  align-items: center;

  span {
    font-size: 1.2em;
  }
}
