@import '../../utils/colors';
@import '../../utils/spacing';

$row-height: 34px;

.addBtn {
  color: darken($mint, 20%);

  &:active,
  &:focus,
  &:hover {
    color: darken($mint, 30%);
    text-decoration: none;
  }
}

.input {
  height: $row-height;
  margin-right: 5px;
}

.removeBtn {
  @include padding-vertical(0);

  color: $grey-dark;
  line-height: $row-height;
  padding: 0 8px !important;
  vertical-align: middle;
}

.row {
  display: flex;
  margin-bottom: 8px;
}
