@import '../../../../../style/utils/breakpoints';

.contentWrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;

  .onTrackLine {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;

    h3 {
      font-size: 1.2em;
      text-align: left;
    }
  }

  .onTrackLine {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 0.4em;

    h3 {
      font-size: 1.2em;
    }
  }

  .data {
    font-size: 0.75em;
    text-align: left;
  }
}

.progressWrapper {
  width: calc(100% - 43px - 0.8em);
  margin-top: 0.3em;
  margin-bottom: 0.3em;
}

.progressCardWrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;

  @include breakpoint-max(sm) {
    width: 100%;
    padding: 0.5em 1em;
  }
}

.cardsContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  height: 100%;
}

.progressCard {
  flex-grow: 1;
  margin-bottom: 1em;
  position: relative;
  z-index: 100;
}

.overallCourseProgress {
  font-size: 0.8em;
  font-weight: bold;
}

.studyTime {
  font-size: 0.8em;
}

.updated-daily {
  position: absolute;
  font-size: 0.8em;
  bottom: 5%;
  right: 2%;
}
