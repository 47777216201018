@import '../../../../../style/utils/colors';
@import '../../../../../style/utils/breakpoints';

.empty-state {
  display: flex;
  justify-content: space-between;
  gap: 3vw;
  width: 100%;

  @include breakpoint-max(sm) {
    flex-direction: column;
  }

  .lp-empty-state,
  .convo-empty-state {
    width: 48%;
    height: 100%;
    background-color: $gray85-opaque;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    padding: 2rem 3rem;
    gap: 5vh;

    @include breakpoint-max(sm) {
      width: 100%;
      padding: 1.2rem 1.8rem;
    }

    .header--wrapper {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 0.5rem;

      .profile-photo--wrapper {
        width: 45px;
        height: 45px;
        display: flex;
        justify-content: center;
        align-items: center;
        filter: grayscale(1);
        background-color: $grey-light-button;
        clip-path: circle(50% at center);

        img {
          width: 100%;
          height: 100%;
          clip-path: circle(50% at center);
          object-fit: cover;
        }

        svg {
          width: 20px;
          height: 20px;
        }
      }

      h2 {
        font-size: 1rem;
      }
    }

    .text--wrapper {
      h3 {
        font-size: 1.1rem;
        margin-bottom: 0.4rem;
      }

      p,
      strong {
        font-size: 0.9rem;
      }

      h3,
      p {
        color: $light-gray !important;
      }
    }
  }
}

.cards-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 2vw;

  @include breakpoint-max(sm) {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 3vw;
    max-height: 55vh;
    overflow-y: scroll;
  }
}
