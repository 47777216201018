@import '../../../../style/utils/colors';
@import '../../../../style/utils/breakpoints';

.messageContainer {
  display: flex;
  &.Chatterbox {
    justify-content: flex-start;
  }
  &.User {
    justify-content: flex-end;
  }
}

.text {
  &__Chatterbox {
    background-color: $super-extra-soft-mint;
    display: block;
    width: fit-content;
    max-width: 60%;
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
    padding: 0.5rem 1rem 1rem;
    border-radius: 5px;
    font-size: 0.875rem;
    line-height: 1.2rem;

    span {
      display: block;
      font-size: 0.875rem;
      font-weight: 500;
      margin-bottom: 0.4rem;
    }
  }
}

.selection {
  &__User {
    background-color: $active-grey;
    padding: 0.5rem 1rem 1rem;
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
    max-width: 60%;
    border-radius: 5px;
    font-size: 0.875rem;
    line-height: 1.2rem;

    span {
      display: block;
      font-size: 0.875rem;
      font-weight: 500;
      margin-bottom: 0.4rem;
    }
  }
}

.typing {
  &__Chatterbox {
    background-color: $super-extra-soft-mint;
    display: block;
    width: fit-content;
    max-width: 60%;
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
    padding: 0.5rem 1rem 1rem;
    border-radius: 5px;
    font-size: 0.875rem;
    line-height: 1.2rem;

    span {
      display: block;
      font-size: 0.875rem;
      font-weight: 500;
      margin-bottom: 0.4rem;
    }
  }
}

.button-wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  padding-bottom: 2vh;

  button {
    width: 25%;
  }
}
